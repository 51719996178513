import React, {useContext, useEffect, useState, useRef, useMemo} from 'react';
import {
  Dialog,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Grid,
  DialogContent,
  DialogActions,
  CircularProgress,
  Badge,
  IconButton,
  DialogTitle,
  Select,
  Accordion,
  AccordionSummary, Typography, AccordionDetails
} from "@material-ui/core";
import AuthContext from "context/AuthContext";
import moment from "moment";
import APIContext from "context/APIContext";
import {Form, Formik} from "formik";
import {FormikSelectField, FormikTextField} from "formik-material-fields";
import MyButton from "components/Controls/MyButton";
import * as Yup from "yup";
import {AddressFields} from "scenes/SubscriptionPage/SubscriptionFlow/InvoicingDetails";
import Team from "pages/Team";
import ErrorBadge from "components/common/ErrorBadge";
import SocketContext from "context/SocketContext";
import PageTitle from "components/layout-components/PageTitle";
import userIcon from 'assets/images/icons/user-icon.png';
import './style.scss';
import ShowIf from "components/common/ShowIf";
import CacheContext from "context/CacheContext";
import FormikChipSelect from "components/Controls/FormikChipSelect";
import {
  ArrowBackIosNewOutlined,
  CardMembershipOutlined, CloseOutlined, ExpandMoreOutlined, Lock,
  MeetingRoomOutlined, NewReleases, NewReleasesOutlined,
  PeopleAltOutlined, PermContactCalendar, PersonOffOutlined, PersonOutlineOutlined, Room
} from "@mui/icons-material";
import discordIcon from 'assets/images/icons/discord.svg';
import Tooltip from "@material-ui/core/Tooltip";
import ReactMarkdown from "react-markdown";
import SubscriptionPage from "../../scenes/SubscriptionPage";
import {MISSING_GENRES_MAP} from "../../scenes/Headquarters";

const updateUserInfo = 'updateUserInfo';
const deleteUser = 'deleteUser';
const DEFAULT_ARRAY = [];

const Profile = ({onClose}) => {

  const {track} = useContext(SocketContext);
  const {call, loading} = useContext(APIContext);
  const {auth, setAuth, logout} = useContext(AuthContext);
  const {cache} = useContext(CacheContext);
  const {genres = DEFAULT_ARRAY, platforms = DEFAULT_ARRAY} = cache;
  const [error, setError] = useState();
  const [titleKey, setTitleKey] = useState();
  const scrollRef = useRef();

  useEffect(() => {
    track('profile.open');
    setTitleKey(1);
  }, []);

  async function onUpdate(values, {resetForm}) {

    let data = {...values};

    if (!(data.password && data.password2 && data.password === data.password2)) {
      delete data.password;
    }

    delete data.password2;

    let response = await call(updateUserInfo, {data}, {
      successMessage: "Your profile has been updated successfully!",
      setError
    });
    if (response.ok) {
      let user = response.body
      setAuth({user}, true, false);
      resetForm({
        values: {
          name: user.name,
          email: user.email,
          address: user.address,
          first_name: user.first_name,
          last_name: user.last_name,
          genres: user.genres,
          platform: user.platform,
          password: "",
          password2: ""
        }
      });
    }
  }

  async function onDeleteAccount() {
    let response = await call(deleteUser, {}, {
      successMessage: "Your account has been deleted successfully!",
    });
    if (response.ok) {
      logout();
    }
  }

  return (
    <div className="profile-modal-content scrollable" ref={scrollRef}>
      <PageTitle
        key={titleKey}
        titleHeading="Profile"
        scrollRef={scrollRef}
      />
      <div className="top-bar">
        <div className="back clickable hvr-backward" onClick={onClose}>
          <ArrowBackIosNewOutlined className="font-size-lg"/>
          <span className="ml-2">To Ludo</span>
        </div>
        <div className="delete-account">
          <MyButton size="small" className="red" color="secondary" onClick={onDeleteAccount}
                    confirmText="This action cannot be reversed. Are you sure you want to delete your account and all your data?">
            <PersonOffOutlined className="font-size-lg"/>
            <span className="ml-2">Delete Account</span>
          </MyButton>
        </div>
      </div>
      <Formik
        initialValues={{
          name: auth.user.name,
          first_name: auth.user.first_name,
          last_name: auth.user.last_name,
          email: auth.user.email,
          address: auth.user.address || {},
          genres: auth.user.genres,
          platform: auth.user.platform
        }}
        onSubmit={onUpdate}
        validationSchema={ValidationSchema}
      >
        {formik => (
          <Form className="strong-colors-form">
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item md={4} sm={12} style={{gap: "10px", display: "flex", flexDirection: "column"}}>
                  <div className="section-title">
                    <span>Personal Information</span>
                    <PermContactCalendar className="section-icon"/>
                  </div>
                  <Grid container spacing={1}>
                    <Grid item sm={6} xs={12}>
                      <FormikTextField
                        name="first_name"
                        label="First Name"
                        fullWidth
                        disabled={loading[updateUserInfo]}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <FormikTextField
                        name="last_name"
                        label="Last Name"
                        fullWidth
                        disabled={loading[updateUserInfo]}
                      />
                    </Grid>
                  </Grid>
                  <FormikTextField
                    name="name"
                    label="Company Name"
                    fullWidth
                    disabled={loading[updateUserInfo]}
                  />
                  <FormikTextField
                    name="email"
                    label="Email Address"
                    fullWidth
                    type="email"
                    disabled={true}
                    validateOnChange={true}
                  />
                  <FormikSelectField
                    name="genres[0]"
                    label="Default Genre"
                    fullWidth
                    disabled={loading[updateUserInfo]}
                    options={["All",...(genres || [])].map((value) => {
                      return { value, label: value === "All" ? "All Genres" : value };
                    })}
                  />
                  <FormikSelectField
                    name="platform"
                    label="Default Platform"
                    fullWidth
                    disabled={loading[updateUserInfo]}
                    options={(platforms || []).map((value) => {
                      return { value, label: value };
                    })}
                  />
                </Grid>
                <Grid item md={4} sm={12}>
                  <div className="section-title">
                    <span>Billing Address</span>
                    <Room
                      className="section-icon"
                    />
                  </div>
                  <AddressFields
                    title={null}
                    loading={loading[updateUserInfo]}
                    formik={formik}
                  />
                </Grid>
                <Grid item md={4} sm={12}>
                  <div className="section-title">
                    <span>Password</span>
                    <Lock className="section-icon"/>
                  </div>
                  <FormikTextField
                    label="New Password"
                    name="password"
                    fullWidth
                    type="password"
                    disabled={loading[updateUserInfo]}
                  />
                  <FormikTextField
                    label="Confirm New Password"
                    name="password2"
                    fullWidth
                    type="password"
                    disabled={loading[updateUserInfo]}
                  />
                </Grid>
                <ErrorBadge error={error}/>
              </Grid>

            </DialogContent>

            <DialogActions>
              <MyButton
                id="profile.submit"
                color="primary"
                className="mx-auto"
                disabled={!formik.dirty || !formik.isValid}
                loading={loading[updateUserInfo]}>
                Submit Changes
              </MyButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </div>
  )
};

export const ProfileButton = () => {

  const {cache} = useContext(CacheContext);
  const {whatsNew} = cache;
  const {track} = useContext(SocketContext);
  const {auth, logout} = useContext(AuthContext);

  const [menuAnchorEl, setMenuAnchorEl] = useState();
  const [openProfile, setOpenProfile] = useState(false);
  const [openBilling, setOpenBilling] = useState(false);
  const [openTeam, setOpenTeam] = useState(false);
  const [openWhatsNew, setOpenWhatsNew] = useState(false);

  const {subscription, user} = auth;

  function onClick(event) {
    track('profile-icon.open');
    setMenuAnchorEl(event.currentTarget);
  }

  function onCloseActions() {
    setMenuAnchorEl();
  }

  let canUseTeam = false;
  if (subscription && subscription.plan && subscription.plan.metadata?.team === "true")
    canUseTeam = true;

  const whatsNewCount = useMemo(() => {
    let mostRecentDate = (whatsNew || [])[0]?.date;
    let date = user.whats_new_date || (mostRecentDate ? mostRecentDate - 1 : undefined) || moment().unix();
    return whatsNew?.filter(item => item.date > date).length || 0;
  }, [user.whats_new_date, whatsNew]);

  return (
    <div className="profile-button">
      <Tooltip
        arrow
        open={!!menuAnchorEl ? false : undefined}
        placement="bottom"
        title="Profile, Subscriptions & Billing"
        PopperProps={{className: "MuiTooltip-popper MuiTooltip-popperArrow secondary"}}
      >
        <div>
          <img src={userIcon} onClick={onClick}/>
          {whatsNewCount > 0 && <Badge
            badgeContent={whatsNewCount}
            color="secondary"
            style={{position: "absolute", right: "0px", top: "0px"}}
            className="animate__animated animate__heartBeat ml-5"
          />}
        </div>
      </Tooltip>
      <ShowIf condition={!!menuAnchorEl}>
        <Menu
          anchorEl={menuAnchorEl}
          keepMounted
          open={!!menuAnchorEl}
          onClose={onCloseActions}
          className="profile-menu"
        >
          <MenuItem onClick={() => {
            onCloseActions();
            setOpenProfile(true)
          }}>
            <ListItemIcon>
              <PersonOutlineOutlined/>
            </ListItemIcon>
            <ListItemText primary="Edit Profile"/>
          </MenuItem>
          <MenuItem
            onClick={() => {
              onCloseActions();
              setOpenBilling(true);
            }}>
            <ListItemIcon>
              <CardMembershipOutlined/>
            </ListItemIcon>
            <ListItemText primary="Subscriptions & Billing"/>
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (canUseTeam) {
                onCloseActions();
                setOpenTeam(true);
              }
            }}
            className={"only-desktop " + (canUseTeam ? "" : "opacity-5")}
          >
            <ListItemIcon>
              <PeopleAltOutlined/>
            </ListItemIcon>
            <ListItemText
              primary="Your Team"
            />
          </MenuItem>
          <Divider/>
          <MenuItem
            onClick={() => {
              onCloseActions();
              setOpenWhatsNew(true);
            }}>
            <ListItemIcon>
              {whatsNewCount > 0 ? <Badge
                badgeContent={whatsNewCount}
                color="secondary"
                style={{color: "white", marginLeft: "18px"}}
                className="animate__animated animate__heartBeat text-white"
              /> : <NewReleasesOutlined/>}
            </ListItemIcon>
            <ListItemText
              primary={<span>What's New</span>}
            />
          </MenuItem>
          <MenuItem>
            <a
              href="https://discord.gg/FmTPyugsrR"
              target="_blank"
              rel="noreferrer"
              style={{display: "contents"}}
            >
              <ListItemIcon style={{placeContent: "center"}}>
                <img src={discordIcon} alt="Discord"/>
              </ListItemIcon>
              <ListItemText
                primary="Ludo Discord Server"
              />
            </a>
          </MenuItem>
          <Divider/>
          <MenuItem onClick={() => {
            logout();
          }}>
            <ListItemIcon>
              <MeetingRoomOutlined/>
            </ListItemIcon>
            <ListItemText primary="Logout"/>
          </MenuItem>
        </Menu>
      </ShowIf>
      <BillingModal open={openBilling} onClose={() => setOpenBilling(false)}/>
      <Dialog
        open={openProfile}
        onClose={() => setOpenProfile(false)}
        className="full-screen-modal no-scroll"
      >
        <Profile onClose={() => setOpenProfile(false)}/>
      </Dialog>
      <Dialog
        scroll="body"
        maxWidth="lg"
        open={openTeam}
        onClose={() => setOpenTeam(false)}
        className="ludo-modal"
        classes={{
          paper: 'modal-content rounded border-0 bg-white p-3 p-xl-0'
        }}
        style={{minWidth: "90%"}}
      >
        <div className="bg-light">
          <Team onClose={() => setOpenTeam(false)}/>
        </div>
      </Dialog>
      <WhatsNewModal
        open={openWhatsNew}
        onClose={() => setOpenWhatsNew(false)}
      />
    </div>
  )
};

const WhatsNewModal = ({open, onClose}) => {

  const {cache} = useContext(CacheContext);
  const {whatsNew} = cache;
  const {call} = useContext(APIContext);
  const {track} = useContext(SocketContext);
  const {setAuth} = useContext(AuthContext);

  useEffect(() => {
    if (open) {
      track('whatsNew.open');
      let data = {
        whats_new_date: moment().unix(),
      };
      call(updateUserInfo, {data}).then(response => {
        if (response.ok) {
          setAuth({user: response.body}, true, false);
        }
      });
    }
  }, [open])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="whats-new-modal ludo-modal"
    >
      <span className="top-right">
          <IconButton onClick={onClose}>
          <CloseOutlined
            className="font-size-xxxxxxl pointer text-white"
          />
          </IconButton>
        </span>
      <DialogTitle className="text-align-center text-primary">
        What's New
      </DialogTitle>
      <DialogContent>
        {(whatsNew || []).map(item => <WhatsNewItem key={item.id} item={item}/>)}
      </DialogContent>
    </Dialog>
  )
}

const WhatsNewItem = ({item}) => {
  return (
    <div className="whats-new-item">
      <div className="content">
        <div className="title">
          {item.title}
        </div>
        <div className="date">
          {moment.unix(item.date).format('MMMM Do YYYY')}
        </div>
        <div className="description">
          <ReactMarkdown children={item.text}/>
        </div>
      </div>
    </div>
  )
}

export const BillingModal = ({open, onClose}) => {

  return (
    <Dialog
      scroll="body"
      maxWidth="lg"
      open={open}
      onClose={onClose}
      className="subscription-modal full-screen-modal no-scroll"
    >
      <div style={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
        <SubscriptionPage onSuccess={onClose} onClose={onClose}/>
      </div>
    </Dialog>
  )
}

export default Profile;

const ValidationSchema = Yup.object().shape({
  first_name: Yup.string()
    .required('No first name provided'),
  last_name: Yup.string()
    .required('No last name provided'),
  email: Yup.string()
    .email('Must be a valid email')
    .required('No email provided'),
  password: Yup.string()
    .oneOf([Yup.ref('password2'), null], 'Passwords must match'),
  password2: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
});
