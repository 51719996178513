import React, { useContext, useMemo } from "react";
import ShowIf from "components/common/ShowIf";
import { MenuTopBar } from "pages/GDD3/GDDSideMenu";
import {
  AutoAwesomeOutlined,
  DeleteOutlined,
  FavoriteOutlined,
  FileDownloadOutlined,
  FileUploadOutlined,
  ModeEditOutlined,
} from "@mui/icons-material";
import GDDContext from "context/GDDContext";
import {
  MoodboardFavorites,
  MoodboardGenerator,
  MoodboardSuggestions,
} from "pages/GDD3/GDDSideMenu/MoodboardMenu";
import { IMAGE_MENUS } from "pages/GDD3/GDDSideMenu/GameElementsMenu";
import {UploadImage} from "../Helpers";
export const MENUS = {
  suggestionsGenerated: {
    showInput: true,
    id: "suggestionsGenerated",
    label: "Suggestions",
    buttonLabel: <AutoAwesomeOutlined className="font-size-lg mr-2" />,
  },
  editImage: {
    id: "editImage",
    label: "Edit Image",
    buttonLabel: <ModeEditOutlined />,
  },
  favorites: {
    id: "favorites",
    label: "From Favorites",
    buttonLabel: <FavoriteOutlined />,
  },
  upload: {
    id: "upload",
    label: "Upload Image",
    buttonLabel: <FileUploadOutlined />,
  },
  download: {
    id: "download",
    label: "Download Image",
    buttonLabel: (
      <span>
        <FileDownloadOutlined />
      </span>
    ),
  },
  delete: {
    id: "delete",
    label: "Delete",
    buttonLabel: (
      <span>
        <DeleteOutlined />
      </span>
    ),
  },
};

const HeaderMenu = ({ component, gdd }) => {
  const { menu } = useContext(GDDContext);

  const section = useMemo(() => {
    return gdd.sections.find(
      ({ id, name }) => id === menu.section || name === menu.section
    );
  }, [gdd, menu]);

  const imageType = menu?.subSection === "icon" ? menu?.subSection : "promo";

  return (
    <div className="menu-section moodboard">
      <MenuTopBar />
      <div className="menu-section-content" key={menu?.subSection}>
        <ShowIf condition={menu?.option === MENUS.suggestionsGenerated.id}>
          <MoodboardSuggestions
            section={menu?.section}
            value={section?.value}
            component={component}
            generated={true}
            imageType={imageType}
            autoSuggest={false}
            artStyle={gdd.art_style}
            input={menu?.input}
          />
        </ShowIf>
        <ShowIf condition={menu?.option === MENUS.favorites.id}>
          <MoodboardFavorites
            section={menu?.section}
            value={section?.value}
            name={menu?.subSection}
          />
        </ShowIf>
        <ShowIf condition={menu?.option === MENUS.upload.id}>
          <UploadImage addImages={() => {}} />
        </ShowIf>
        <ShowIf condition={menu?.option === IMAGE_MENUS.editImage.id}>
          <MoodboardGenerator
            key={"edit-" + menu?.subSection}
            section={menu?.section}
            value={section?.value}
            artStyle={gdd.art_style}
            gdd={gdd}
            initialImage={menu.image}
          />
        </ShowIf>
      </div>
    </div>
  );
};

export default HeaderMenu;
