import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Editable } from "pages/GDD3/Helpers";
import { useBus, useListener } from "react-bus";
import SocketContext from "context/SocketContext";
import {
  Chip,
  CircularProgress,
  ClickAwayListener,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import {
  DESCRIPTION_MENUS,
  MENUS,
  TITLE_MENUS,
} from "pages/GDD3/GDDSideMenu/GameSummaryMenu";
import GDDContext from "context/GDDContext";
import { ArrowForwardOutlined, Sync } from "@mui/icons-material";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Menu as MaterialMenu,
} from "@material-ui/core";
import WbIncandescentOutlinedIcon from "@mui/icons-material/WbIncandescentOutlined";
import FavoriteBorderOutlined from "@mui/icons-material/FavoriteBorderOutlined";
import CacheContext from "context/CacheContext";
import PerformanceUtils from "../../helpers/PerformanceUtils";

const FIELDS = {
  title: "title",
  description: "description",
};

const GDDGameSummary = ({
  active,
  scrollTo,
  changeGdd,
  section,
  value,
  component,
}) => {
  const { track } = useContext(SocketContext);
  const { menu, openMenu } = useContext(GDDContext);
  const bus = useBus();
  const ref = useRef();
  const titleRef = useRef();
  const textRef = useRef();
  const [activeField, setActiveField] = useState();

  useEffect(() => {
    if (active && scrollTo && ref.current) {
      setTimeout(() => {
        ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 150);
    }
  }, [active, scrollTo]);

  const [hoverText, setHoverText] = useState();

  const onHover = useCallback(
    (data) => {
      setHoverText(data);
      setTimeout(() => {
        if (ref.current)
          ref.current.scrollIntoView({ behavior: "smooth", block: "end" });
      }, 50);
    },
    [ref]
  );

  const onClick = useCallback(
    (data) => {
      if (data) {
        setHoverText();
        track("gdd.summary.click-idea", data);
        let newValue = {
          ...(value || {}),
          title: data.title || value?.title,
          text: (value?.text || "") + (data.text || ""),
        };
        changeGdd(section, newValue, true);
      }
    },
    [section, value, changeGdd]
  );

  const onReplace = useCallback(
    (data) => {
      if (data) {
        track("gdd.summary.replace-idea", data);
        let newValue = {
          ...(value || {}),
          title: data.title || value?.title,
          text: data.text || value?.text,
        };
        let otherData = data.genres ? { genres: data.genres } : undefined;
        changeGdd(section, newValue, true, otherData);
      }
    },
    [section, value, changeGdd]
  );

  useListener(`${component.section}.hover`, onHover);
  useListener(`${component.section}.click`, onReplace);
  useListener(`${component.section}.replace`, onReplace);

  let className = "section game-summary";
  if (active) className += " active";
  if (!value?.text && !value?.title) className += " empty";

  function onTyped(key, text) {
    let newValue = {
      ...(value || {}),
      [key]: text,
    };
    changeGdd(section, newValue, true);
    bus.emit(`${component.section}.typed`);
  }

  function onClickedOption(option, _, input) {
    openMenu({
      component,
      option,
      section,
      input,
      clickId: PerformanceUtils.generateId(),
    });
  }

  let descriptionMenuWrapper = "editable menu-wrapper";
  let titleMenuWrapper = "editable menu-wrapper large";

  const descriptionIds = [DESCRIPTION_MENUS.suggestions.id];

  if (descriptionIds.includes(menu?.option)) descriptionMenuWrapper += " open";
  if (menu?.option === TITLE_MENUS.suggestions.id) titleMenuWrapper += " open";

  if (activeField === FIELDS.description) descriptionMenuWrapper += " selected";
  if (activeField === FIELDS.title) titleMenuWrapper += " selected";

  return (
    <>
      <div className={className} ref={ref}>
        <div className={titleMenuWrapper} ref={titleRef}>
          <Menu id="title" options={TITLE_MENUS} onClick={onClickedOption} />
          <Editable
            className="text-align-left w-100"
            value={!hoverText?.title ? value?.title || "" : ""}
            setValue={(value = "") => onTyped("title", value)}
            placeholder="Enter a game title"
            addOnText={hoverText?.title}
            onFocus={() => setActiveField(FIELDS.title)}
          />
        </div>
        <div className={descriptionMenuWrapper} ref={textRef}>
          <Menu
            id="summary"
            options={{
              descriptionSuggestions: DESCRIPTION_MENUS.suggestions,
              chat: DESCRIPTION_MENUS.chat,
            }}
            onClick={onClickedOption}
          />
          <Editable
            className="text-align-left w-100"
            value={hoverText?.text ? "" : value?.text || ""}
            setValue={(value = "") => onTyped("text", value)}
            addOnText={hoverText?.text}
            placeholder="Enter a game description"
            onFocus={() => setActiveField(FIELDS.description)}
          />
        </div>
      </div>
    </>
  );
};

export const GDDSummaryActions = ({ component, section }) => {
  const { track } = useContext(SocketContext);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const { openMenu } = useContext(GDDContext);
  const { cache } = useContext(CacheContext);
  const { gddComponents } = cache;

  if (component?.section !== gddComponents?.summary?.section) return null;

  function onActionsClick(event) {
    track("gdd.section.clicked-context", { section });
    event.preventDefault();
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  }

  function onClickedOption(option) {
    openMenu({ option, component });
    setMenuAnchorEl();
  }

  return (
    <>
      <div className="action hide-preview">
        <IconButton
          className="secondary"
          onClick={onActionsClick}
          style={{ padding: "7px" }}
        >
          <Sync
            className="font-size-xxxxl text-white"
            onClick={onActionsClick}
          />
        </IconButton>
      </div>
      {!!menuAnchorEl && (
        <MaterialMenu
          anchorEl={menuAnchorEl}
          keepMounted
          open={!!menuAnchorEl}
          onClose={() => setMenuAnchorEl(null)}
        >
          <span
            className="p-4"
            style={{ color: "#9E9EB3", fontWeight: "bold" }}
          >
            Replace section content with:
          </span>
          <MenuItem onClick={() => onClickedOption(MENUS.ideator.id)}>
            <ListItemIcon>
              <WbIncandescentOutlinedIcon className="flip-vertical" />
            </ListItemIcon>
            <ListItemText primary={MENUS.ideator.label} />
          </MenuItem>
          <MenuItem onClick={() => onClickedOption(MENUS.favorites.id)}>
            <ListItemIcon>
              <FavoriteBorderOutlined />
            </ListItemIcon>
            <ListItemText primary={MENUS.favorites.label} />
          </MenuItem>
        </MaterialMenu>
      )}
    </>
  );
};

export const Menu = ({ id, options = {}, onClick, inputStyle }) => {
  const { track } = useContext(SocketContext);

  const optionsRef = useRef();

  const [menuAnchorEl, setMenuAnchorEl] = useState();
  const [input, setInput] = useState("");
  const [data, setData] = useState();
  const [contextMenu, setContextMenu] = useState({
    open: false,
    anchorEl: null,
    options: null,
  });

  function onClickedOption(event, data) {
    track("clicked-button", { id: `gdd.${id}.${data}` });
    let chosen = Object.values(options).find((option) => option?.id === data);

    if (chosen?.options) {
      // If the option has nested options, show context menu
      setContextMenu({
        open: true,
        anchorEl: event.currentTarget,
        parentMenu: chosen.id,
      });
    } else if (chosen?.showInput) {
      setInput("");
      setData(data);
      setMenuAnchorEl(event.currentTarget);
    } else {
      onClick(data);
    }
  }

  function onContextMenuItemClick(optionId) {
    onClick(optionId);
    closeContextMenu();
  }

  function closeContextMenu() {
    setContextMenu({ open: false, anchorEl: null, options: null });
  }

  function onInputKeyDown(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      onSubmitInput();
    } else if (event.key === "Escape") {
      resetData();
    }
  }

  function onSubmitInput() {
    onClick(data, undefined, input);
    resetData();
  }

  function resetData() {
    setMenuAnchorEl();
    setInput();
    setData();
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        resetData();
        closeContextMenu();
      }}
    >
      <div className="hover-options hide-preview" ref={optionsRef}>
        {menuAnchorEl && (
          <div className="gdd-floating-input-wrapper" style={inputStyle}>
            <TextField
              fullWidth
              variant="standard"
              value={input}
              margin="dense"
              placeholder="Suggestions input (optional)"
              onChange={(e) => setInput(e.target.value)}
              style={{ width: "200px" }}
              onKeyDown={(e) => onInputKeyDown(e)}
              autoFocus
            />
            <div className="icon-wrapper">
              <ArrowForwardOutlined onClick={onSubmitInput} />
            </div>
          </div>
        )}
        <div className="white-shadow" />
        {Object.keys(options)
          .filter((key) => !!options[key])
          .map((key) => {
            return options[key]?.loading ? (
              <Chip
                key={key}
                label={
                  <CircularProgress
                    size={20}
                    style={{
                      color: "white",
                      top: "3px",
                      margin: "5px",
                      position: "relative",
                    }}
                  />
                }
              />
            ) : (
              <React.Fragment key={key}>
                {!!options[key]?.label && (
                  <Tooltip
                    title={options[key]?.label}
                    arrow
                    PopperProps={{
                      disablePortal: false,
                      className: "MuiTooltip-popper MuiTooltip-popperArrow",
                    }}
                    placement="top"
                  >
                    <Chip
                      label={options[key]?.buttonLabel || options[key]?.label}
                      onClick={(event) =>
                        onClickedOption(event, options[key].id)
                      }
                    />
                  </Tooltip>
                )}
                {!options[key]?.label && (
                  <Chip
                    label={options[key]?.buttonLabel || options[key]?.label}
                    onClick={(event) => onClickedOption(event, options[key].id)}
                  />
                )}
              </React.Fragment>
            );
          })}

        {/* Context Menu for nested options */}
        {contextMenu.open && (
          <MaterialMenu
            anchorEl={contextMenu.anchorEl}
            keepMounted
            open={contextMenu.open}
            onClose={closeContextMenu}
          >
            {Object.keys(options[contextMenu.parentMenu].options || {}).map((key) => (
              <MenuItem
                key={key}
                onClick={() =>
                  onContextMenuItemClick(
                    options[contextMenu.parentMenu].options[key].id
                  )
                }
              >
                {options[contextMenu.parentMenu].options[key].loading ? (
                  <ListItemIcon>
                    <CircularProgress size={16} />
                  </ListItemIcon>
                ) : (
                  options[contextMenu.parentMenu].options[key].icon && (
                    <ListItemIcon>{options[contextMenu.parentMenu].options[key].icon}</ListItemIcon>
                  )
                )}
                <ListItemText
                  primary={
                    options[contextMenu.parentMenu].options[key].buttonLabel ||
                    options[contextMenu.parentMenu].options[key].label
                  }
                />
              </MenuItem>
            ))}
          </MaterialMenu>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default GDDGameSummary;
