import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  CloseOutlined,
  ExpandMoreOutlined,
  QuestionMark,
} from "@mui/icons-material";
import {
  Typography,
  AccordionDetails,
  Accordion,
  AccordionSummary,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
} from "@material-ui/core";
import discordIcon from "assets/images/icons/discord-primary.svg";
import CacheContext from "context/CacheContext";
import MyButton from "components/Controls/MyButton";
import { useLocation } from "react-router-dom";
import ReactMarkdown from "react-markdown";

const HelpIcon = () => {
  const [modal, setModal] = useState(false);

  return (
    <div className="help-icon">
      <IconButton onClick={() => setModal(true)}>
        <QuestionMark />
      </IconButton>
      {modal && (
        <DocumentationModal modal={modal} setModal={setModal} key={modal} />
      )}
    </div>
  );
};

export const DocumentationModal = ({
  modal,
  setModal,
  defaultDoc,
  defaultIndex,
  defaultTitle,
}) => {
  const { cache } = useContext(CacheContext);
  const location = useLocation();

  const allDocs = useMemo(() => {
    return cache.documentation?.filter((tip) => tip.index.startsWith("doc"));
  }, [cache.documentation]);

  const selectedReference = useRef();

  const pageDoc = useMemo(() => {
    const locationPage =
      defaultDoc || location.pathname.split("/").filter((s) => s)[0] || "index";
    let defaultDocFound = allDocs?.find((tip) => tip.index === "doc_index");
    return (
      allDocs?.find((tip) => tip.index === `doc_${locationPage}`) ||
      defaultDocFound
    );
  }, [allDocs, location.pathname, defaultDoc]);

  const [selectedDoc, setSelectedDoc] = useState(pageDoc);

  useEffect(() => {
    setTimeout(() => {
      if (selectedReference.current) {
        selectedReference.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 100);
  }, [selectedDoc]);

  if (!selectedDoc) return null;

  return (
    <Dialog
      open={modal}
      onClose={() => setModal(false)}
      className="faq-modal ludo-modal"
    >
      <span className="top-right">
        <IconButton onClick={() => setModal(false)}>
          <CloseOutlined className="font-size-xxxxxxl pointer text-white" />
        </IconButton>
      </span>
      <DialogTitle className="text-align-center text-primary">
        <Select
          key={selectedDoc?.index}
          name="faq"
          value={selectedDoc}
          className="w-100"
          defaultValue={selectedDoc}
          onChange={(event) => {
            setSelectedDoc(event.target.value);
          }}
        >
          {allDocs.map((doc) => (
            <MenuItem key={doc.index} value={doc}>
              <span>{doc.label}</span>
            </MenuItem>
          ))}
        </Select>
      </DialogTitle>
      <DialogContent key={selectedDoc?.index}>
        <div className="faq-items" key={selectedDoc?.index}>
          <div>
            {selectedDoc?.section?.map(({ title, content }, index) => {
              const isDefault =
                index === defaultIndex || title === defaultTitle;

              return (
                <div
                  ref={isDefault ? selectedReference : null}
                  key={index + title + content}
                >
                  <Accordion
                    className="faq-item"
                    key={title}
                    defaultExpanded={isDefault}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreOutlined />}
                      aria-controls={title + index + "-content"}
                      id={title + index + "-header"}
                    >
                      <Typography>{title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <ReactMarkdown children={content} />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              );
            })}
          </div>
        </div>
        <div className="bottom">
          <div className="discord">
            <span className="answers">Not finding the answer you seek?</span>
            <div className="actions">
              <div className="square" />
              <div className="discord-icon">
                <img
                  src={discordIcon}
                  alt="Discord"
                  width="70px"
                  className="mt-3"
                />
                <div className="bg" />
              </div>
              <span>Ask us directly!</span>
              <a
                href="https://discord.gg/FmTPyugsrR"
                rel="noopener nofollow"
                target="_blank"
              >
                <MyButton>Ludo on Discord</MyButton>
              </a>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default HelpIcon;
