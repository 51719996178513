import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import useApi from "useApi";
import useAnalytics from "hooks/useAnalytics";
import useSocket from "useSocket";
import ChartsContext from "context/ChartsContext";
import AuthContext from "context/AuthContext";
import SocketContext from "context/SocketContext";
import APIContext from "context/APIContext";
import AnalyticsContext from "context/AnalyticsContext";
import CacheContext, { DEFAULT_CACHE } from "context/CacheContext";
import usePersistedState from "hooks/usePersistedState";
import Routes from "Routes";
import ShowIf from "components/common/ShowIf";
import PerformanceUtils from "helpers/PerformanceUtils";
import jwtDecode from "jwt-decode";
import config from "config/config.json";
import DetailsPanelContext from "context/DetailsPanelContext";
import useDetailsPanel from "useDetailsPanel";
import LudoErrorPage from "scenes/LudoErrorPage";
import CookieConsent from "react-cookie-consent";
import { useHistory, useLocation } from "react-router";
import { AnimatePresence } from "framer-motion";
import { ThemeProvider } from "@material-ui/styles";
import MuiTheme from "./theme";
import localforage from "localforage";
import LoadingPage from "./scenes/LoadingPage";

const App = () => {
  const [auth, setAuthState] = usePersistedState("auth", {}, true);
  const [cache, setCache] = useState(DEFAULT_CACHE);
  const [loadingCacheData, setLoadingCacheData] = useState(true);
  const [charts, setCharts] = useState([]);
  const [authRecheck, setAuthRecheck] = useState(0);
  const [refreshId, setRefreshId] = useState(0);
  const {
    status,
    emit,
    track,
    addTrackListener,
    SOCKET_STATUS,
    setSocketAuth,
    instance,
  } = useSocket(cache, setCache);
  const { call, setToken, loading, loggedIn, setTrackingIds, ready } = useApi(
    logout,
    setCache
  );
  const {
    showGame,
    showGameTopics,
    showIdeator,
    showChat,
    showTrendTopic,
    showImage,
    showCompetitiveAnalysis,
    showDeveloper,
    hidePanel,
    navigatePrevious,
    navigateNext,
    hasPrevious,
    hasNext,
  } = useDetailsPanel(cache, setCache, setCacheValue, track);

  const history = useHistory();
  const location = useLocation();

  const setAuth = useCallback((val, incremental = true, decodeToken = true) => {
    setAuthState((prevState) => {
      let newAuth = { ...prevState, ...val };
      if (!incremental) newAuth = { ...val, ok: !!val.token };
      if (decodeToken && newAuth.token)
        newAuth.decodedToken = jwtDecode(newAuth.token);
      return newAuth;
    });
  }, []);

  const analyticsPayload = useAnalytics(
    auth?.user,
    setAuth,
    call,
    ready,
    setTrackingIds
  );

  useEffect(() => {
    track(`page.${location.pathname}`);
    analyticsPayload.trackPageView();
  }, [location.pathname, analyticsPayload.trackPageView]);

  function setCacheValue(id, value) {
    return setCache((prevState) => {
      return { ...prevState, [id]: value };
    });
  }

  function addFavoriteToCache(value) {
    return setCache((prevState) => {
      return {
        ...prevState,
        allFavorites: PerformanceUtils.editOrAdd(
          value,
          prevState.allFavorites,
          "_id"
        ),
      };
    });
  }

  function removeFavoriteFromCache(value) {
    return setCache((prevState) => {
      return {
        ...prevState,
        allFavorites: PerformanceUtils.removeFromArray(
          value,
          prevState.allFavorites,
          "_id"
        ),
      };
    });
  }

  useEffect(() => {
    setToken(auth.token);
    setSocketAuth(auth);
  }, [auth]);

  useEffect(() => {
    if (auth.decodedToken && auth.decodedToken.exp < moment().unix()) {
      logout();
    }

    let timeout = setTimeout(() => {
      setAuthRecheck(Math.random());
    }, 1000 * 60 * 60);

    return () => {
      clearTimeout(timeout);
    };
  }, [auth, authRecheck]);

  function logout() {
    setAuth({}, false, false);
    localStorage.clear();
    sessionStorage.clear();
    localforage.clear();
    history.push("/");
  }

  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence key="animate">
        <AuthContext.Provider value={{ auth, logout, setAuth }}>
          <SocketContext.Provider
            value={{
              emit,
              status,
              track,
              addTrackListener,
              SOCKET_STATUS,
              instance,
            }}
          >
            <AnalyticsContext.Provider value={{ ...analyticsPayload }}>
              <APIContext.Provider
                value={{ call, loading, loggedIn, setToken }}
              >
                <CacheContext.Provider
                  value={{
                    cache,
                    setCache,
                    setCacheValue,
                    addFavoriteToCache,
                    removeFavoriteFromCache,
                  }}
                >
                  <DetailsPanelContext.Provider
                    value={{
                      showGame,
                      showGameTopics,
                      showIdeator,
                      showChat,
                      showTrendTopic,
                      showImage,
                      showDeveloper,
                      showCompetitiveAnalysis,
                      hidePanel,
                      navigatePrevious,
                      navigateNext,
                      hasPrevious,
                      hasNext,
                    }}
                  >
                    <ChartsContext.Provider value={{ charts, setCharts }}>
                      <ShowIf condition={loading.clientOK === true}>
                        <CookieConsent
                          key={refreshId + analyticsPayload.consent}
                          extraCookieOptions={
                            config.ENVIRONMENT === "production"
                              ? {
                                  domain: ".ludo.ai",
                                  sameSite: "lax",
                                }
                              : undefined
                          }
                          onAccept={() => {
                            setRefreshId(Math.random());
                          }}
                          visible={
                            analyticsPayload.consent === undefined
                              ? "show"
                              : "hidden"
                          }
                          location="bottom"
                          cookieName={config.CONSENT_COOKIE}
                          buttonText="Accept All"
                          style={{
                            background: "#131648",
                            zIndex: 9999,
                            padding: "20px",
                            fontFamily: '"Inter", sans-serif',
                            boxShadow: "0 -2px 10px rgba(0,0,0,0.25)",
                          }}
                          buttonStyle={{
                            color: "#ffffff",
                            fontSize: "18px",
                            background: "#7E2FF0",
                            borderRadius: "30px",
                            padding: "10px 20px",
                            fontWeight: "bold",
                            border: "none",
                            textTransform: "uppercase",
                          }}
                        >
                          Level up your experience! Cookies help us personalize
                          your game development journey and improve our AI
                          tools. Accept all to get the most out of Ludo.ai, or
                          decline for a basic experience.
                        </CookieConsent>
                        <Routes
                          loadingCacheData={loadingCacheData}
                          setLoadingCacheData={setLoadingCacheData}
                        />
                      </ShowIf>
                      <ShowIf condition={loading.clientOK === false}>
                        <LudoErrorPage />
                      </ShowIf>
                      <ShowIf
                        condition={
                          (!!auth.ok && loading.clientOK === undefined) ||
                          loadingCacheData
                        }
                      >
                        <LoadingPage />
                      </ShowIf>
                    </ChartsContext.Provider>
                  </DetailsPanelContext.Provider>
                </CacheContext.Provider>
              </APIContext.Provider>
            </AnalyticsContext.Provider>
          </SocketContext.Provider>
        </AuthContext.Provider>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default App;
