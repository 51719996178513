import React, { useCallback, useContext, useEffect, useState } from "react";
import usePersistedState from "../../hooks/usePersistedState";
import { GDDModal } from "../GDD3/Helpers";
import {
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Slider,
  Slider as OldSlider,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ReactCrop from "react-image-crop";
import { GeneratingButton } from "../../components/Controls/MyButton";
import { Canvas } from "./MaskCanvas";
import SocketContext from "../../context/SocketContext";
import APIContext from "../../context/APIContext";
import { MyImage } from "../../components/common/ImageGallery";
import PerformanceUtils from "../../helpers/PerformanceUtils";
import { IMAGE_STRENGTH, prepareGeneratedImage } from "./index";
import { Hint } from "../../scenes/Headquarters";
import {
  CheckOutlined,
  DeleteOutline,
  EditOutlined,
  RemoveCircleOutlineOutlined,
  SyncOutlined,
  UndoOutlined,
} from "@mui/icons-material";

const DEFAULT_ARRAY = [];
const DEFAULT_OBJECT = {};

const cancelGeneration = "cancelGeneration";
const generateImagesInpainting = "generateImagesInpainting";
const generateImages = "generateImages";

let cancelBatches = [];

const IMAGE_MODES = {
  edit: {
    value: "edit",
    label: "Edit",
    description: "Add or change elements in the image",
    icon: <EditOutlined />,
  },
  remove: {
    value: "remove",
    label: "Remove",
    description: "Remove elements from the image",
    icon: <RemoveCircleOutlineOutlined />,
  },
};

const ImageEditor = ({
  url,
  onClose,
  sizeCrop = DEFAULT_ARRAY,
  formValues = DEFAULT_OBJECT,
  initialImage,
  onResults,
}) => {
  const { call, loading } = useContext(APIContext);
  const { track } = useContext(SocketContext);
  const [crop, setCrop] = useState({});
  const [mask, setMask] = useState();
  const [imageMode, setImageMode] = useState(IMAGE_MODES.edit.value);
  const [mode, setMode] = useState("mask");
  const [initialCrop, setInitialCrop] = useState([]);
  const [maskKey, setMaskKey] = useState(1);
  const [brushSize, setBrushSize] = usePersistedState(
    "ImageEditor.brushSize1",
    30
  );
  const [cropContainerSize, setCropContainerSize] = useState();
  const [imageSize, setImageSize] = useState();
  const [image, setImage] = useState(null);
  const [loadedImage, setLoadedImage] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [results, setResults] = useState([initialImage]);
  const [currentBatchId, setCurrentBatchId] = useState(undefined);
  const [hoveredImage, setHoveredImage] = useState();
  const [hoveredMask, setHoveredMask] = useState();
  const [maskHistory, setMaskHistory] = useState([]);
  const [strength, setStrength] = useState(IMAGE_STRENGTH.default);
  const [selectedResultIndex, setSelectedResultIndex] = useState(0);
  const [undoFunction, setUndoFunction] = useState(null);
  const [requests, setRequests] = useState([undefined]);

  const submitImage = hoveredImage || results[selectedResultIndex];

  const isGenerating =
    loading[generateImagesInpainting] || loading[generateImages];

  function onLoadImage(event) {
    setImageSize({ width: event.target.width, height: event.target.height });
  }

  const hasMask = !!mask;

  useEffect(() => {
    if (hasMask) {
      setStrength(0);
    } else {
      setStrength(IMAGE_STRENGTH.default);
    }
  }, [hasMask]);

  useEffect(() => {
    if (imageSize && crop.x === undefined) {
      function toPercentage(value, reference) {
        return (value / reference) * 100.0;
      }

      const { height, width } = imageSize;

      let newCrop = {
        x: toPercentage(sizeCrop[0] || 0, width),
        y: toPercentage(sizeCrop[1] || 0, height),
        width: toPercentage(
          parseInt(width) - (sizeCrop[2] || 0) - (sizeCrop[0] || 0),
          width
        ),
        height: toPercentage(
          parseInt(height) - (sizeCrop[3] || 0) - (sizeCrop[1] || 0),
          height
        ),
        unit: "%",
      };
      setCrop(newCrop);
      setInitialCrop();
    }
  }, [imageSize, initialCrop, crop]);

  const handleUndo = useCallback(
    (undoFunc) => {
      setUndoFunction(() => undoFunc);
    },
    [maskKey]
  );

  function onResultsClick(image, imageIndex) {
    track("image-generator.editor.click-edit-image");
    setSelectedResultIndex(imageIndex);
    setMask(maskHistory[imageIndex]);
    setMaskKey(maskKey + 1);

    let request = requests[imageIndex + 1];

    if (request) {
      setPrompt(request.hints[0] || "");
      setStrength(request.strength * 100.0);
    } else {
      setPrompt("");
    }
  }

  function onSaveAndClose(image) {
    track("image-generator.editor.save-close");
    onResults([image]);
  }

  function cancelGenerationWrapper() {
    if (currentBatchId) {
      cancelBatches.push(currentBatchId);
      call(
        cancelGeneration,
        { generationId: currentBatchId },
        { hideErrorMessage: true }
      );
    }
    setCurrentBatchId(undefined);
  }

  useEffect(() => {
    if (url) {
      fetch(url)
        .then((response) => response.blob())
        .then(async (blob) => {
          if (!blob.type) blob = new Blob([blob], { type: "image/jpeg" });
          setImage({ url: URL.createObjectURL(blob) });
        });
    }
  }, [url]);

  useEffect(() => {
    if (image && imageSize) {
      const cropX = ((crop.x || 0) / 100) * imageSize?.width;
      const cropY = ((crop.y || 0) / 100) * imageSize?.height;
      const cropWidth = ((crop.width || 100) / 100) * imageSize?.width;
      const cropHeight = ((crop.height || 100) / 100) * imageSize?.height;

      var canvas = document.createElement("canvas");
      canvas.width = cropWidth;
      canvas.height = cropHeight;

      var canvasContext = canvas.getContext("2d");
      let htmlImage = new Image();
      htmlImage.src = image.url;
      canvasContext.drawImage(
        htmlImage,
        cropX,
        cropY,
        cropWidth,
        cropHeight,
        0,
        0,
        cropWidth,
        cropHeight
      );
      setMaskKey((prevState) => prevState + 1);
    }
  }, [crop, mode, image, imageSize]);

  const cropContainerRef = useCallback(
    (node) => {
      function setDimensions(node) {
        if (node.offsetWidth > 0 && node.offsetHeight > 0) {
          setCropContainerSize({
            width: node.offsetWidth,
            height: node.offsetHeight,
          });
          return true;
        }
        return false;
      }

      if (node !== null) {
        if (!setDimensions(node)) {
          setTimeout(() => {
            setDimensions(node);
          }, 1000);
        }
        setMaskKey((prevState) => prevState + 1);
      }
    },
    [loadedImage]
  );

  async function onGenerate() {
    cancelGenerationWrapper();
    const {
      genres,
      platform,
      selectedColors,
      creative_mode,
      image_type,
      art_style,
      perspective,
      aspect_ratio,
    } = formValues;

    let data = {
      request_id: PerformanceUtils.generateId(),
      filters: {
        genres,
        colors: selectedColors,
        creative_mode: creative_mode,
        platform,
        n: 1,
      },
      image_type: image_type,
      art_style: art_style,
      perspective: perspective,
      aspect_ratio: aspect_ratio,
      hints: [prompt],
    };

    if (imageMode === IMAGE_MODES.remove.value) {
      data.hints = [];
      delete data.strength;
    }

    let submitField = submitImage?.submit_field || "initial_image_file";
    if (submitImage.game_id) submitField = "initial_game_image";
    else if (submitImage.request_id) submitField = "initial_generated_image";

    data.strength = strength / 100.0;
    data.mask_image = mask;
    data[submitField] = submitImage;

    setCurrentBatchId(data.request_id);
    const ep = data.mask_image ? generateImagesInpainting : generateImages;

    if(ep === generateImagesInpainting) {
      data.edit_mode = imageMode;
    }

    const response = await call(ep, { data });
    if (response.ok) {
      onGeneratedSuccessfully(response.body);
      setRequests((prevState) => {
        return [...prevState, data];
      });
      setPrompt("");
    }
    setCurrentBatchId(undefined);
  }

  async function repeatRequest(index) {
    track("image-generator.editor.regenerate");
    let data = {
      ...requests[index],
      request_id: PerformanceUtils.generateId(),
    };
    setCurrentBatchId(data.request_id);
    const ep = data.mask_image ? generateImagesInpainting : generateImages;
    let response = await call(ep, { data });
    setRequests((prevState) => {
      return [...prevState, data];
    });
    if (response.ok) onGeneratedSuccessfully(response.body);
    setCurrentBatchId(undefined);
  }

  function onGeneratedSuccessfully(images) {
    let processedImages = images.map(prepareGeneratedImage);
    setMaskHistory((prevState) => {
      let newState = [...prevState];
      newState[selectedResultIndex] = mask;
      return newState;
    });
    setResults((prevState) => [...prevState, ...processedImages]);
    setSelectedResultIndex(results.length + processedImages.length - 1);
    setMask();
    setMaskKey(maskKey + 1);
  }

  let containerClassName = "image-container " + mode;
  let modeToggleClassName = "mode-toggle " + mode;
  let maskContainerClassName = "mask-container";
  if (mask) maskContainerClassName += " has-mask";
  if (hoveredImage !== undefined) maskContainerClassName += " hovered-image";

  function onCanvasExport(maskImage) {
    if (maskImage) {
      setMask(maskImage);
    }
  }

  function clearMask() {
    track("image-generator.editor.clear-mask");
    setMask(undefined);
    setMaskKey(maskKey + 1);
  }

  function clearCrop() {
    setCrop({ x: 0, y: 0, width: 100, height: 100, unit: "%" });
    clearMask();
  }

  function changeCrop(value) {
    track("image-generator.editor.change-crop", {});
    setCrop(value);
    clearMask();
  }

  function onClickedUndo() {
    track("image-generator.editor.undo-mask");
    undoFunction();
  }

  const generateDisabled = imageMode === IMAGE_MODES.remove.value && !mask;

  return url ? (
    <GDDModal
      open={!!url}
      onClose={onClose}
      className="image-editor-modal full-screen-modal transparent-modal no-padding"
    >
      <div
        className="modal-content"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div className="image-content">
          <span className="description">
            {imageMode === IMAGE_MODES.edit.value && (mask ? "Describe what changes you want to the masked section on the right side of this screen" : "Draw a mask over the image or just type the changes you want to the image on the right side of this screen")}
            {imageMode === IMAGE_MODES.remove.value && (mask ? "Hit the Generate button to remove the masked section" : "Draw a mask over the image to select what to remove")}
          </span>
          <img src={image?.url} className="hide" onLoad={onLoadImage} />
          <div className={modeToggleClassName}>
            {/*<ToggleButtonGroup
            color="primary"
            value={mode}
            exclusive
            onChange={(event, mode) => changeMode(mode)}
            aria-label="Mode"
          >
            <ToggleButton value="mask">Draw Mask</ToggleButton>
            <ToggleButton value="crop">Crop</ToggleButton>
          </ToggleButtonGroup>*/}

            <div className="canvas-controls">
              <Chip
                onClick={clearCrop}
                className="clear-crop font-weight-bold"
                label="Clear Crop"
              />
              <Chip
                onClick={onClickedUndo}
                className="clear-mask font-weight-bold"
                label="Undo"
                disabled={!mask}
                icon={<UndoOutlined className="text-white" />}
              />
              <Chip
                onClick={clearMask}
                className="clear-mask font-weight-bold"
                label="Reset"
                disabled={!mask}
                icon={<DeleteOutline className="text-white" />}
              />
              <div className="slider-wrapper">
                <span>Brush Size</span>
                <OldSlider
                  className="slider-primary"
                  track="inverted"
                  value={brushSize}
                  step={1}
                  min={20}
                  onChange={(event, value) => {
                    track("image-generator.editor.set-brush-size", { value });
                    setBrushSize(value);
                  }}
                  max={80}
                />
              </div>
            </div>
          </div>

          {mode !== "crop" && submitImage && !initialCrop && (
            <div className={maskContainerClassName} ref={cropContainerRef}>
              <img
                src={submitImage?.url}
                className="cropped-image"
                onLoad={() => setLoadedImage(true)}
              />
              {cropContainerSize && (mask || hoveredMask) && loadedImage && (
                <img
                  className="segmentation-mask"
                  src={hoveredMask || mask}
                  style={{
                    width: cropContainerSize.width,
                    height: cropContainerSize.height,
                  }}
                />
              )}
              {cropContainerSize && mode === "mask" && loadedImage && (
                <Canvas
                  className="mask-canvas"
                  key={maskKey}
                  width={cropContainerSize.width + 1}
                  height={cropContainerSize.height + 1}
                  onExport={onCanvasExport}
                  brushSize={brushSize}
                  onUndo={handleUndo}
                />
              )}
            </div>
          )}

          {mode === "crop" && (
            <div className={containerClassName}>
              <>
                <img src={url} className="bg-image" />
                {cropContainerSize && (
                  <ReactCrop
                    crop={crop}
                    onChange={(pixelCrop, percentageCrop) =>
                      changeCrop(percentageCrop)
                    }
                    unit="%"
                  >
                    <div
                      className="crop-target"
                      style={{
                        width: cropContainerSize?.width,
                        height: cropContainerSize?.height,
                      }}
                    />
                  </ReactCrop>
                )}
                {mask && crop && (
                  <div
                    className="segmentation-mask-wrapper"
                    style={{
                      width: "100%",
                      height: "auto",
                    }}
                  >
                    <img
                      className="segmentation-mask"
                      src={mask}
                      style={{
                        width: crop.width + "%",
                        height: crop.height + "%",
                        left: crop.x + "%",
                        top: crop.y + "%",
                      }}
                    />
                  </div>
                )}
              </>
            </div>
          )}
        </div>
        <div className="results-panel">
          <div className="title">Image Editor</div>
          <div className="form">
            {imageMode === IMAGE_MODES.edit.value && <span className="description">
              Describe what changes you want to make to the{" "}
              {mask ? "masked region" : "full image"}
            </span>}
            <Select
              name="mode"
              className="select-field"
              value={imageMode}
              defaultValue={imageMode}
              onChange={(event) => {
                setImageMode(event.target.value);
              }}
            >
              {Object.values(IMAGE_MODES).map((mode) => (
                <MenuItem key={mode.value} value={mode.value}>
                  <ListItemIcon>{mode.icon}</ListItemIcon>
                  <ListItemText
                    primary={mode.label}
                    secondary={mode.description}
                  />
                </MenuItem>
              ))}
            </Select>
            {imageMode !== IMAGE_MODES.remove.value && (
              <>
                <TextField
                  className="text-field"
                  placeholder="Describe changes to the image here"
                  multiline={true}
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                />
                <div className="slider">
                  <Typography
                    id="discrete-slider-restrict"
                    gutterBottom
                    className="title-span"
                  >
                    <span>Image Strength</span>
                    <Hint
                      iconClassName="pt-0"
                      hint="How much the model should stick to the initial image"
                    />
                  </Typography>
                  <Slider
                    name="Image Strength"
                    className="slider-primary"
                    track="inverted"
                    value={strength}
                    step={IMAGE_STRENGTH.increment}
                    valueLabelDisplay="on"
                    min={IMAGE_STRENGTH.min}
                    onChange={(event, value) => {
                      track("image-generator.editor.set-image-strength", {
                        value,
                      });
                      setStrength(value);
                    }}
                    max={IMAGE_STRENGTH.max}
                    marks={[
                      {
                        value: IMAGE_STRENGTH.min,
                        label: "" + IMAGE_STRENGTH.min,
                      },
                      {
                        value: IMAGE_STRENGTH.max,
                        label: "" + IMAGE_STRENGTH.max,
                      },
                    ]}
                  />
                </div>
              </>
            )}
            {imageMode === IMAGE_MODES.remove.value && !mask && (
              <span className="error">
                Please draw a mask to use the "Remove" mode
              </span>
            )}
            <GeneratingButton
              label="Generate"
              id="image-generator.editor.generate"
              className="gradient"
              loading={isGenerating}
              loadProgressSecs={10}
              onClick={onGenerate}
              disabled={generateDisabled}
              onCancel={cancelGenerationWrapper}
            />
          </div>
          {results?.length > 0 && (
            <div className="results">
              <span className="small-title">History</span>
              <span className="description">
                Click on an image below to select it for editing.
              </span>
              <div className="image-history">
                {results.map(
                  (result, index) =>
                    result && (
                      <div
                        className={
                          "image-wrapper" +
                          (index === selectedResultIndex ? " selected" : "")
                        }
                        key={result?.id || result?.url}
                      >
                        <MyImage
                          convertUrl={true}
                          onImageClick={false}
                          onImageClickFunc={() => onResultsClick(result, index)}
                          key={result.id || result.url}
                          image={{
                            src: result?.url,
                            title: "",
                            originalImage: result,
                          }}
                          contextMenuOverrides={{
                            generateSimilar: null,
                          }}
                          dontExpand={true}
                          onHover={(val) => {
                            setHoveredImage(val);
                            setHoveredMask(
                              val ? maskHistory[index] : undefined
                            );
                          }}
                        >
                          {onResults && (
                            <div className="image-buttons">
                              <Tooltip
                                title="Edit Image"
                                arrow
                                PopperProps={{
                                  className:
                                    "MuiTooltip-popper MuiTooltip-popperArrow secondary",
                                }}
                                placement="top"
                              >
                                <IconButton
                                  className="icon-button text-white"
                                  onClick={() => onResultsClick(result, index)}
                                >
                                  <EditOutlined />
                                </IconButton>
                              </Tooltip>

                              {requests[index] && !isGenerating && (
                                <Tooltip
                                  title="Regenerate Image"
                                  arrow
                                  PopperProps={{
                                    className:
                                      "MuiTooltip-popper MuiTooltip-popperArrow secondary",
                                  }}
                                  placement="top"
                                >
                                  <IconButton
                                    className="icon-button text-white"
                                    onClick={() => repeatRequest(index)}
                                  >
                                    <SyncOutlined />
                                  </IconButton>
                                </Tooltip>
                              )}

                              <Tooltip
                                title="Save & Close"
                                arrow
                                PopperProps={{
                                  className:
                                    "MuiTooltip-popper MuiTooltip-popperArrow secondary",
                                }}
                                placement="top"
                              >
                                <IconButton
                                  className="icon-button text-white"
                                  onClick={() => onSaveAndClose(result)}
                                >
                                  <CheckOutlined />
                                </IconButton>
                              </Tooltip>
                            </div>
                          )}
                        </MyImage>
                      </div>
                    )
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </GDDModal>
  ) : null;
};
export default ImageEditor;
