import React, { useState, useContext, useEffect, useRef } from "react";
import APIContext from "../../context/APIContext";
import "./game-guide.scss";
import { IconPlaceholder } from "./GDD4Menu";
import {
  AddOutlined,
  ArrowBackOutlined,
  AutoAwesome,
  ChevronLeftOutlined,
  ChevronRightOutlined,
  InfoOutlined,
  PrecisionManufacturingOutlined,
  SchoolOutlined,
} from "@mui/icons-material";
import ReactMarkdown from "react-markdown";
import { CircularProgress } from "@material-ui/core";
import Chat from "../../components/common/Chat";
import SocketContext from "../../context/SocketContext";

const DEFAULT_PROMPT =
  "Guide me through the complete development process of game, from initial setup to final optimization";

const SCREENS = {
  LOADING: "LOADING",
  TOPICS: "TOPICS",
  SUBTOPIC: "SUBTOPIC",
  GUIDES: "GUIDES",
  SELECTOR: "SELECTOR",
};

const ENGINES = [
  { id: "unity", name: "Unity" },
  { id: "cocos2d", name: "Cocos2d-x" },
  { id: "buildbox", name: "Buildbox" },
  { id: "solar2d", name: "Solar2D" },
  { id: "godot", name: "Godot" },
  { id: "unreal", name: "Unreal" },
  { id: "roblox", name: "Roblox" },
];

const LEVELS = [
  {
    id: "novice",
    title: "Beginner",
    icon: <SchoolOutlined />,
    description:
      "New to game development. Learn core concepts and basic implementations.",
    features: [
      "Detailed explanations of basic concepts",
      "Step-by-step guidance",
      "More code comments and examples",
      "Focus on fundamental patterns",
    ],
  },
  {
    id: "expert",
    title: "Advanced",
    icon: <PrecisionManufacturingOutlined />,
    description:
      "Experienced developer seeking advanced techniques and optimizations.",
    features: [
      "Advanced optimization strategies",
      "Complex system architectures",
      "Edge cases and performance tuning",
      "Platform-specific considerations",
    ],
  },
];

const GameGuide = ({ projectId }) => {
  const { track } = useContext(SocketContext);
  const { call, loading } = useContext(APIContext);
  const [content, setContent] = useState(null);
  const [values, setValues] = useState(undefined);
  const [selectedStep, setSelectedStep] = useState(null);
  const [allGuides, setAllGuides] = useState([]);

  const [screen, setScreen] = useState(SCREENS.LOADING);

  useEffect(() => {
    trackWrapper("change-screen", { screen });
  }, [screen]);

  function trackWrapper(action, payload) {
    track(`guide.${action}`, payload);
  }

  async function fetchContent(
    action,
    context = {},
    currentValues = values,
    regenerate = false
  ) {
    let data = {
      game_concept_id: projectId,
      action,
      skill_level: currentValues.skillLevel,
      engine: currentValues.engine,
      prompt: currentValues.prompt,
      ...context,
    };
    trackWrapper(action, data);
    const response = await call("getGuideContent", {
      regenerate,
      data,
    });

    if (response?.ok) {
      setContent(response.body);
      return response.body;
    }
  }

  async function onSelectValues(values, regenerate = true) {
    setValues(values);
    setContent(null);
    setSelectedStep(null);
    setScreen(SCREENS.TOPICS);
    let guide = await fetchContent(
      "VIEW_TOPICS",
      undefined,
      values,
      regenerate
    );
    if (guide) {
      let guides = await getGuideList(projectId);
      if (guides?.length > 0) {
        setAllGuides(guides);
      }
    }
  }

  async function getGuideList(projectId) {
    let response = await call("getProjectGuideContent", { projectId });
    if (response?.ok) {
      return response.body;
    }
  }

  useEffect(() => {
    getGuideList(projectId).then((guides) => {
      setAllGuides(guides);
      if (guides?.length > 0) {
        onSelectGuide(guides[0]);
      } else {
        setScreen(SCREENS.SELECTOR);
      }
    });
  }, [projectId]);

  function onSelectGuide(guide) {
    if (guide) {
      const values = {
        skillLevel: guide.skill_level,
        engine: guide.engine,
        prompt: guide.prompt,
      };
      setSelectedStep(null);
      setValues(values);
      setContent(guide);
      setScreen(SCREENS.TOPICS);
      fetchContent("VIEW_TOPICS", undefined, values).catch(console.log);
    } else {
      setScreen(SCREENS.SELECTOR);
    }
  }

  const renderTopicsList = () => {
    const categories = JSON.parse(content.content_json)?.categories;
    if (!Array.isArray(categories)) return null;
    return (
      <>
        {values && (
          <div className="top-bar">
            {allGuides.length > 1 ? (
              <button
                className="reset-level grey"
                onClick={() => setScreen(SCREENS.GUIDES)}
              >
                <ArrowBackOutlined className="font-size-md mr-1" /> All Guides
              </button>
            ) : (
              <div />
            )}
            <div className="skill-level-indicator">
              <div className="current-level">
                <span className="label">Experience Level: </span>
                <span className="text-capitalize text-bold">
                  {values.skillLevel}
                </span>
              </div>
              <div className="current-level">
                <span className="label">Engine: </span>
                <span className="text-capitalize text-bold">
                  {ENGINES.find(({ id }) => id === values.engine)?.name}
                </span>
                <div className="prompt-indicator">
                  <InfoOutlined className="info-icon" />
                  <div className="prompt-tooltip">
                    <div className="tooltip-title">Current Prompt</div>
                    <div className="tooltip-content">
                      {values.prompt || DEFAULT_PROMPT}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="reset-level"
              onClick={() => setScreen(SCREENS.SELECTOR)}
            >
              <AddOutlined className="font-size-xl mr-1" /> New Guide
            </button>
          </div>
        )}
        <div className="topics-view">
          {categories?.map((category, i) => (
            <div key={i} className="category">
              <div className="top">
                <h2>{category.title}</h2>
                <p className="description">{category.description}</p>
              </div>
              <div className="topics">
                {category.topics.map((topic, j) => (
                  <div key={j} className="topic-container">
                    <label
                      className="topic-header"
                      htmlFor={`topic-${topic.id}`}
                    >
                      <div className="topic-info">
                        <div className="topic-meta">
                          <h3>{topic.title}</h3>
                          <p>{topic.description}</p>
                        </div>
                      </div>
                      <div className="meta">
                        <span className="topics-count">
                          {topic.steps.length} steps
                        </span>
                      </div>
                    </label>

                    <div className="topic-steps">
                      <div className="steps">
                        {topic.steps.map((step, i) => (
                          <div
                            key={i}
                            className="step"
                            onClick={() => {
                              setSelectedStep(step.id);
                              fetchContent("VIEW_SUBTOPIC", {
                                current_topic_id: topic.id,
                                current_subtopic_id: step.id,
                              });
                            }}
                          >
                            <div className="step-number">{i + 1}</div>
                            <div className="step-content">
                              <h4>{step.title}</h4>
                              <p>{step.summary}</p>
                            </div>
                            <ChevronRightOutlined />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  if (screen === SCREENS.LOADING) {
    return (
      <div className="text-align-center m-4 opacity-5">
        <CircularProgress size={150} />
      </div>
    );
  }

  if (screen === SCREENS.GUIDES) {
    return <AllGuides guides={allGuides} onSelectGuide={onSelectGuide} />;
  }

  if (screen === SCREENS.SELECTOR) {
    return (
      <SkillLevelSelector
        onSelect={onSelectValues}
        onCancel={() => setScreen(SCREENS.TOPICS)}
        defaultValues={values}
      />
    );
  }

  return (
    <div className="main-wrapper responsive-styles">
      {loading.getGuideContent ? (
        <LoadingState
          type={selectedStep ? "subtopic" : "topics"}
          setScreen={setScreen}
        />
      ) : !content ? null : !!selectedStep ? (
        <SubtopicContent
          content={content}
          setSelectedStep={setSelectedStep}
          backToGuide={() => onSelectValues(values, false)}
          fetchContent={fetchContent}
          onSelectGuide={onSelectGuide}
        />
      ) : (
        renderTopicsList()
      )}
    </div>
  );
};

const SubtopicContent = ({
  content,
  setSelectedStep,
  fetchContent,
  onSelectGuide,
  backToGuide,
}) => {
  const section = JSON.parse(content.content_json);
  const titleRef = useRef(null);

  useEffect(() => {
    if (titleRef.current && content) {
      titleRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [content]);

  return (
    <div className="subtopic-detail">
      <div className="top-bar">
        <button className="reset-level grey" onClick={backToGuide}>
          <ArrowBackOutlined className="font-size-md mr-1" /> To Guide
        </button>
        <div className="title" ref={titleRef}>
          <span className="sub-title">{content.topic_title}</span>
          <h1>
            Step {content.current_step}: {section.title}
          </h1>
        </div>
        <button className="reset-level" onClick={() => onSelectGuide(null)}>
          <AddOutlined className="font-size-xl mr-1" /> New Guide
        </button>
      </div>
      <div className="content">
        <div className="main-content">
          <ReactMarkdown
            components={{ code: CodeBlock }}
            children={section.content}
          />
        </div>
        <hr />
        <ChatPrompt section={section} />
      </div>
      <div className="navigation">
        {content.previous_step?.title ? (
          <div
            className="nav-button previous"
            onClick={() => {
              setSelectedStep(content.previous_step.id);
              fetchContent("VIEW_SUBTOPIC", {
                current_topic_id: content.topic_id,
                current_subtopic_id: content.previous_step.id,
              });
            }}
          >
            <ChevronLeftOutlined className="chevron" />
            <div className="text">
              <span className="label">Previous Step</span>
              <span className="title">{content.previous_step.title}</span>
            </div>
          </div>
        ) : (
          <div />
        )}

        {content.next_step?.title ? (
          <div
            className="nav-button next"
            onClick={() => {
              setSelectedStep(content.next_step.id);
              fetchContent("VIEW_SUBTOPIC", {
                current_topic_id: content.topic_id,
                current_subtopic_id: content.next_step.id,
              });
            }}
          >
            <div className="text">
              <span className="label">Next Step</span>
              <span className="title">{content.next_step.title}</span>
            </div>
            <ChevronRightOutlined className="chevron" />
          </div>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

const ChatPrompt = ({ section }) => {
  const [resetKey, setResetKey] = useState(0);
  return (
    <div className="ask-question">
      <div className="question-prompt">
        <div className="prompt-header">
          <div className="text">
            <h3>
              Have questions about <i>{section.title}</i>?
            </h3>
            <p>
              Chat with Ludo Assistant for help with implementation,
              troubleshooting, or best practices.
            </p>
          </div>
        </div>
        <Chat
          key={resetKey}
          onStartNewChat={() => setResetKey(resetKey + 1)}
          component={{ section: "chat" }}
          section="chat"
          includeFollowUps={false}
          guide_page={section?.content}
          autoFocus={false}
        />
      </div>
    </div>
  );
};

const CodeBlock = ({ node, children }) => {
  let className = node?.properties?.className?.[0] || "";
  className = className.replace("language-", "");
  return <code className={className}>{children}</code>;
};

const AllGuides = ({ guides, onSelectGuide }) => {
  return (
    <div className="main-wrapper">
      <div className="top-bar align-right">
        <button className="reset-level" onClick={() => onSelectGuide(null)}>
          <AddOutlined className="font-size-xl mr-1" /> New Guide
        </button>
      </div>
      <div className="all-guides">
        <div className="guides-header">
          <h2>Your Development Guides</h2>
        </div>

        <div className="guides-list">
          {guides.map((guide) => (
            <div
              key={guide._id}
              className="guide-item"
              onClick={() => onSelectGuide(guide)}
            >
              <div className="guide-main-info">
                <div className="guide-icon">
                  {guide.skill_level === "novice" ? (
                    <SchoolOutlined />
                  ) : (
                    <PrecisionManufacturingOutlined />
                  )}
                </div>
                <div className="guide-details">
                  <div className="guide-meta">
                    <span className="skill-level text-capitalize">
                      {guide.skill_level}
                    </span>
                    <span className="engine">
                      {ENGINES.find((e) => e.id === guide.engine)?.name}
                    </span>
                  </div>
                  <div className="guide-prompt">
                    {guide.prompt || DEFAULT_PROMPT}
                  </div>
                  <div className="guide-dates">
                    <span>
                      Created:{" "}
                      {new Date(guide.date * 1000).toLocaleDateString()}
                    </span>
                    <span>
                      Last viewed:{" "}
                      {new Date(
                        guide.date_last_view * 1000
                      ).toLocaleDateString()}
                    </span>
                  </div>
                </div>
              </div>
              <ChevronRightOutlined className="font-size-xxxl chevron" />
            </div>
          ))}
          <div className="guide-item new" onClick={() => onSelectGuide(null)}>
            <div className="guide-main-info">
              <div className="guide-icon">
                <AddOutlined />
              </div>
              <div className="guide-details">
                <div className="guide-prompt">Create a new guide</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PROMPTS = [
  DEFAULT_PROMPT,
  `Focus on creating dynamic enemy AI that adapts to player behavior, using advanced techniques like behavior trees and state machines`,
  `Walk me through designing immersive, procedurally generated levels`,
  `Help me build a narrative-driven game by integrating intricate quest systems, rich character development, and interactive dialogue mechanics.`,
];

const SkillLevelSelector = ({ onSelect, defaultValues, onCancel }) => {
  const [values, setValues] = useState(
    defaultValues || { skillLevel: "novice", engine: "unity", prompt: "" }
  );

  const handleSubmit = () => {
    onSelect(values);
  };

  return (
    <div className="main-wrapper">
      <div className="top-bar">
        {defaultValues && (
          <button className="reset-level grey" onClick={onCancel}>
            <ArrowBackOutlined className="font-size-md mr-1" /> Back
          </button>
        )}
      </div>
      <div className="skill-level-selector">
        <h2>Customize Your Guide</h2>

        <div className="setup-section">
          <h3>1. Choose Your Experience Level</h3>
          <p className="subtitle">
            Select the difficulty level that best matches your game development
            experience
          </p>
          <div className="levels">
            {LEVELS.map((level) => (
              <div
                key={level.id}
                className={`level-card ${
                  values.skillLevel === level.id ? "selected" : ""
                }`}
                onClick={() =>
                  setValues((prevState) => ({
                    ...prevState,
                    skillLevel: level.id,
                  }))
                }
              >
                <div className="level-header">
                  <div className="icon">{level.icon}</div>
                  <h3>{level.title}</h3>
                </div>
                <div className="level-content">
                  <p className="description">{level.description}</p>
                  <ul className="features">
                    {level.features.map((feature, i) => (
                      <li key={i}>{feature}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="setup-section">
          <h3>2. Select Game Engine</h3>
          <div className="engine-selector">
            {ENGINES.map((engine) => (
              <div
                key={engine.id}
                className={`engine-button ${
                  values.engine === engine.id ? "selected" : ""
                }`}
                onClick={() =>
                  setValues((prevState) => ({
                    ...prevState,
                    engine: engine.id,
                  }))
                }
              >
                <span>{engine.name}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="setup-section">
          <h3>3. Customize Your Guide (Optional)</h3>
          <p className="subtitle">
            Describe what specific aspects of game development you'd like to
            focus on. Leave empty for a complete development guide.
          </p>
          <div className="prompt-input">
            <textarea
              value={values.prompt}
              onChange={(e) =>
                setValues((prevState) => ({
                  ...prevState,
                  prompt: e.target.value,
                }))
              }
              placeholder={DEFAULT_PROMPT}
              rows={4}
            />
          </div>
          <div className="examples">
            <p className="subtitle">Examples</p>
            <ul>
              {PROMPTS.filter((p) => p !== values.prompt).map((prompt, i) => (
                <li
                  key={prompt}
                  onClick={() =>
                    setValues((prevState) => ({
                      ...prevState,
                      prompt,
                    }))
                  }
                >
                  <AutoAwesome className="font-size-lg mr-2" />
                  <span>{prompt}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="button-group">
          <hr />
          <button
            className="submit-button"
            onClick={handleSubmit}
            disabled={!values.skillLevel || !values.engine}
          >
            Generate Guide
          </button>
          <hr />
        </div>
      </div>
    </div>
  );
};

const LoadingState = ({ type = "topics", setScreen }) => {
  if (type === "topics") {
    return (
      <div className="topics-view loading mt-2">
        {[1, 2].map((category) => (
          <div key={category} className="category loading">
            <div className="skeleton title" />
            <div className="skeleton description" />

            <div className="topics">
              {[1, 2, 3].map((topic) => (
                <div key={topic} className="topic-container loading">
                  <div className="topic-header">
                    <div className="topic-info">
                      <div className="skeleton title" />
                      <div className="skeleton description" />
                      <div className="skeleton meta" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (type === "subtopic") {
    return (
      <div className="subtopic-detail loading">
        <div className="top-bar">
          <button
            className="reset-level grey"
            onClick={() => setScreen(SCREENS.TOPICS)}
          >
            <ArrowBackOutlined className="font-size-md mr-1" /> Back to Guide
          </button>
        </div>
        <div className="navigation">
          <div className="skeleton back-button" />
          <div className="nav-buttons">
            <div className="skeleton nav-button" />
            <div className="skeleton nav-button" />
          </div>
        </div>

        <div className="content">
          <div className="content-header">
            <div className="skeleton title" />
            <div className="skeleton topic-context" />
          </div>

          <div className="main-content">
            {[1, 2, 3].map((line) => (
              <div key={line} className="skeleton text-line" />
            ))}
          </div>

          <div className="learning-points">
            <div className="key-points loading">
              <div className="skeleton title" />
              {[1, 2, 3].map((point) => (
                <div key={point} className="skeleton list-item" />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export const GameGuideHeader = ({ gdd }) => {
  const projectName = gdd.sections[0]?.value?.title;
  const gameGenre = gdd.sections[0]?.value?.genre;

  return (
    <div className="game-guide-header main-header">
      <div className="icon">
        <IconPlaceholder icon={gdd.icon} />
      </div>
      <div className="description gradient-text2">
        Interactive Development Guide for{" "}
        <i>
          <b>{projectName}</b>
        </i>
        <div className="subtitle">
          Step-by-step guidance to build your {gameGenre} game, from basic setup
          to advanced features
        </div>
      </div>
    </div>
  );
};

export default GameGuide;
