import React, { useContext, useMemo, useEffect } from "react";
import ShowIf from "components/common/ShowIf";
import { CircularProgress, Grid, IconButton, Tooltip } from "@material-ui/core";
import "./style.scss";
import SocketContext from "context/SocketContext";
import PerformanceUtils from "helpers/PerformanceUtils";
import {
  ArrowForwardIosOutlined,
  AutoAwesomeOutlined,
  CloseOutlined,
} from "@mui/icons-material";
import GameSummaryMenu from "pages/GDD3/GDDSideMenu/GameSummaryMenu";
import GamesMenu from "pages/GDD3/GDDSideMenu/GamesMenu";
import GameElementMenu from "pages/GDD3/GDDSideMenu/GameElementsMenu";
import MoodboardMenu from "pages/GDD3/GDDSideMenu/MoodboardMenu";
import GDDContext from "context/GDDContext";
import HeaderMenu from "pages/GDD3/GDDSideMenu/HeaderMenu";
import Chat from "components/common/Chat";
import CacheContext from "context/CacheContext";
import { Hint } from "scenes/Headquarters";
import AssetsMenu from "./AssetsMenu";

const GDDSideMenu = ({ addNewComponent, gdd }) => {
  const { track } = useContext(SocketContext);
  const { menu } = useContext(GDDContext);
  const { cache } = useContext(CacheContext);
  const { gddComponents } = cache;

  function onClickedComponent(component) {
    track("gdd.menu.clicked", { section: component?.section });
    if (component.multiple_allowed)
      component = { ...component, id: PerformanceUtils.generateId() };
    addNewComponent(component, menu?.section);
  }

  const component = menu?.component;
  const option = menu?.option;
  let className = "gdd-side-menu-3";
  if (option) className += ` option-${option}`;

  const section = useMemo(() => {
    return gdd.sections.find(
      ({ id, name }) => id === menu.section || name === menu.section
    );
  }, [gdd, menu]);

  useEffect(() => {
    window.dispatchEvent(new CustomEvent("resize"));
  }, [menu]);

  return (
    <ShowIf condition={!!component}>
      <div className={className}>
        <ShowIf condition={component?.section === "index"}>
          <IndexSection
            component={component}
            setComponent={onClickedComponent}
            gdd={gdd}
          />
        </ShowIf>
        <ShowIf condition={component?.section === "chat" || component?.noMenu}>
          <div className="menu-section">
            <MenuTopBar />
            <div className="menu-section-content">
              <Chat
                component={component}
                section={menu?.section}
                element={undefined}
                sectionValue={section}
                ludo_score={menu?.ludo_score}
                ludo_score_metric={menu?.ludo_score_metric}
                ludo_score_label={menu?.ludo_score_label}
              />
            </div>
          </div>
        </ShowIf>
        <ShowIf
          condition={component?.section === gddComponents?.summary?.section}
        >
          <GameSummaryMenu component={component} gdd={gdd} />
        </ShowIf>
        <ShowIf
          condition={
            component?.section === gddComponents?.moodboard?.section ||
            component?.section === gddComponents?.images?.section
          }
        >
          <MoodboardMenu component={component} gdd={gdd} />
        </ShowIf>

        <ShowIf
          condition={component?.section === gddComponents?.assets?.section}
        >
          <AssetsMenu component={component} gdd={gdd} />
        </ShowIf>

        <ShowIf
          condition={component?.section === gddComponents?.header?.section}
        >
          <HeaderMenu component={component} gdd={gdd} />
        </ShowIf>

        <ShowIf
          condition={
            component?.section === gddComponents?.games?.section ||
            component?.section === gddComponents?.trending?.section
          }
        >
          <GamesMenu component={component} gdd={gdd} />
        </ShowIf>
        <ShowIf condition={!!component?.gameElements}>
          <GameElementMenu
            key={component?.section + menu?.option}
            component={component}
            gdd={gdd}
          />
        </ShowIf>
      </div>
    </ShowIf>
  );
};

export const MenuTopBar = ({ overrideTitle }) => {
  const { menu, closeMenu } = useContext(GDDContext);
  const title =
    overrideTitle ||
    `${
      menu?.subSection ||
      menu?.component?.label ||
      ((menu?.option || "").toLowerCase().includes("chat")
        ? "Ludo AI Chat Assistant"
        : undefined)
    }`;
  return (
    <div className="menu-top-bar">
      <span className="title text-capitalize gradient-text">{title}</span>
      <div className="handle" />
      <IconButton onClick={closeMenu} className="close-menu-button">
        <CloseOutlined className="font-size-xxxxxl pointer" />
      </IconButton>
    </div>
  );
};

function isDisabled(component, gdd) {
  let disabled = false;
  let { multiple_allowed, gameElements } = component;
  if (!multiple_allowed || !!gameElements) {
    return (gdd?.sections || []).find(({ name }) => name === component.section);
  }
  return disabled;
}

const IndexSection = ({ setComponent, component, gdd }) => {
  const { closeMenu, suggestions } = useContext(GDDContext);
  const { cache } = useContext(CacheContext);
  const { componentGroups, gddComponents } = cache;

  const groups = useMemo(() => {
    return componentGroups
      .map((group) => {
        return {
          ...group,
          components: group.components.filter((component) => !component.hide),
        };
      })
      .filter((group) => group.components.length > 0);
  }, [componentGroups]);

  function onClickComponent(event, component) {
    event.preventDefault();
    event.stopPropagation();
    setComponent(component);
  }

  return (
    <div className="menu-section index">
      <MenuTopBar onClose={closeMenu} title={component.label} />
      <div className="menu-section-content">
        <SectionIcons
          title="Suggestions"
          hint="Ludo's suggestions of the most relevant sections for your game concept"
          components={suggestions}
          onClick={onClickComponent}
          isDisabled={(component) => isDisabled(component, gdd)}
        />
        <SectionIcons
          title="Add Standard Sections"
          components={Object.values(gddComponents).filter(
            (component) => !component.hide && !component.gameElements
          )}
          onClick={onClickComponent}
          isDisabled={(component) => isDisabled(component, gdd)}
        />
        {groups.map((group) => (
          <SectionIcons
            key={group.title}
            title={group.title}
            hint={group.description}
            components={group.components}
            onClick={onClickComponent}
            isDisabled={(component) => isDisabled(component, gdd)}
          />
        ))}
      </div>
    </div>
  );
};

const SectionIcons = ({ title, hint, components, onClick, isDisabled }) => {
  return (
    <React.Fragment>
      <span className="secondary-title">
        {title} {hint && <Hint hint={hint} iconClassName="help" />}
      </span>
      <Grid container>
        {components.map((component) => (
          <Grid item md={4} key={component.section}>
            <SectionIcon
              description={component.description}
              label={component.label}
              onClick={(event) => {
                onClick(event, component);
              }}
              disabled={isDisabled(component)}
            />
          </Grid>
        ))}
      </Grid>
      <hr className="light-hr" />
    </React.Fragment>
  );
};

const SectionIcon = ({ label, description, onClick, disabled }) => (
  <Tooltip
    title={description}
    placement="top"
    PopperProps={{
      disablePortal: true,
      className: "MuiTooltip-popper MuiTooltip-popperArrow secondary max-width",
    }}
  >
    <div>
      <div
        className={"section-icon" + (disabled ? " disabled" : "")}
        onClick={disabled ? undefined : onClick}
      >
        <span>{label}</span>
      </div>
    </div>
  </Tooltip>
);

export const MenuOptions = ({ section, onClick, options, visible }) => {
  const { track } = useContext(SocketContext);

  function onClickWrapper(value) {
    track("gdd.menu.clicked-option", { section, option: value });
    onClick(value);
  }

  return (
    <ShowIf condition={visible}>
      {Object.values(options).map((value) => (
        <MenuOption title={value} onClick={() => onClickWrapper(value)} />
      ))}
    </ShowIf>
  );
};

export const MenuOption = ({ onClick, title }) => (
  <div className="menu-option-title px-5">
    <div className="menu-option-title-content clickable" onClick={onClick}>
      <span>{title}</span>
      <ArrowForwardIosOutlined className="font-size-xxxl" />
    </div>
  </div>
);

export default GDDSideMenu;
