import { useEffect, useState, useCallback } from "react";
import ReactPixel from "react-facebook-pixel";
import RedditPixel from "react-reddit-pixel";
import { hotjar } from "react-hotjar";
import TagManager from "react-gtm-module";
import { usePostHog } from "posthog-js/react";
import { getCookieConsentValue } from "react-cookie-consent";
import config from "config/config.json";
import usePersistedState from "./usePersistedState";
import * as Sentry from "@sentry/react";
import PerformanceUtils from "../helpers/PerformanceUtils";

function extractClickId(cookieString, platform) {
  switch (platform.toLowerCase()) {
    case "google":
      const gclMatch = cookieString.match(/_gcl_aw=GCL\.\d+\.(\d+)/);
      return gclMatch ? gclMatch[1] : null;

    case "fbc":
      const fbcMatch = cookieString.match(/_fbc=([^;]+)/);
      return fbcMatch ? fbcMatch[1] : null;
    case "fbp":
      const fbpMatch = cookieString.match(/_fbp=([^;]+)/);
      return fbpMatch ? fbpMatch[1] : null;
    case "reddit":
      const rclMatch = cookieString.match(/_rdt_cid=(\w+)/);
      return rclMatch ? rclMatch[1] : null;

    default:
      return null;
  }
}

const DEFAULT_OBJECT = {};

const useAnalytics = (user, setAuth, call, apiReady, setTrackingIds) => {
  const posthog = usePostHog();

  //debug
  const cookieConsent = true; //getCookieConsentValue(config.CONSENT_COOKIE);

  const [consent, setConsent] = useState(cookieConsent);
  const [error, setError] = useState(false);

  const [currentTrackingIds, setCurrentTrackingIds] = usePersistedState(
    "trackingIds",
    DEFAULT_OBJECT
  );

  const redditClickId = extractClickId(document.cookie, "reddit");
  const facebookClickId = extractClickId(document.cookie, "fbc");
  const facebookPixelId = extractClickId(document.cookie, "fbp");

  useEffect(() => {
    if (consent) {
      setCurrentTrackingIds((prevState) => {
        return {
          ...prevState,
          ["rdt_cid"]: redditClickId,
          ["fbc"]: facebookClickId,
          ["fbp"]: facebookPixelId,
        };
      });
    }
  }, [redditClickId, facebookClickId, facebookPixelId, consent]);

  useEffect(() => {
    setTrackingIds(currentTrackingIds);
  }, [currentTrackingIds]);

  useEffect(() => {
    const userConsent = user?.cookie_consent;
    setConsent(cookieConsent || userConsent);
  }, [user, cookieConsent]);

  const updateUserConsent = useCallback(async () => {
    if (error || !apiReady) return;
    if (consent && !!user?._id) {
      let data = {};
      if (!user.cookie_consent) {
        data.cookie_consent = true;
      }
      Object.keys(currentTrackingIds).forEach((key) => {
        let value = currentTrackingIds[key];
        if (value && user[key] !== value) {
          data[key] = value;
        }
      });

      if (Object.keys(data).length > 0) {
        const response = await call(
          "updateUserInfo",
          { data },
          { hideErrorMessage: true }
        );
        if (response?.ok) {
          setAuth({ user: response.body }, true, false);
        } else {
          setError(true);
        }
      }
    }
  }, [consent, user, call, setAuth, currentTrackingIds, error, apiReady]);

  useEffect(() => {
    updateUserConsent();
  }, [updateUserConsent]);

  useEffect(() => {
    if (consent) {
      initializeFacebookPixel(user);
      initializeRedditPixel(user);
      initializeHotjar(user);
      initializeGoogleTagManager(user);
      initializePostHog(user);
    }
    initializeSentry(user);
  }, [consent, user]);

  const initializeSentry = (user) => {
    if (user) {
      Sentry.setUser({ id: user._id, email: user.email });
    }
  };

  const initializeFacebookPixel = (user) => {
    if (config.FACEBOOK_PIXEL) {
      ReactPixel.init(
        config.FACEBOOK_PIXEL,
        {
          em: user?.email?.toLowerCase().trim(),
          external_id: user?._id,
        },
        {
          autoConfig: true,
        }
      );
      if (consent) {
        ReactPixel.grantConsent();
      }
    }
  };

  const initializeRedditPixel = (user) => {
    if (config.REDDIT_PIXEL) {
      RedditPixel.init(config.REDDIT_PIXEL, {
        email: user?.email?.toLowerCase().trim(),
        externalId: user?._id,
      });
    }
  };

  const initializeHotjar = (user) => {
    if (config.HOTJAR_ID) {
      hotjar.initialize(config.HOTJAR_ID, "6");
      if (user) {
        hotjar.identify(user._id, {
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
        });
      }
    }
  };

  const initializeGoogleTagManager = (user) => {
    if (config.GOOGLE_TAG_ID) {
      TagManager.initialize({ gtmId: config.GOOGLE_TAG_ID });
      if (user) {
        TagManager.dataLayer({
          dataLayer: {
            userId: user._id,
          },
        });
      }
    }
  };

  const initializePostHog = (user) => {
    if (config.PUBLIC_POSTHOG_KEY && config.PUBLIC_POSTHOG_HOST) {
      posthog.init(config.PUBLIC_POSTHOG_KEY, {
        api_host: config.PUBLIC_POSTHOG_HOST || "https://app.posthog.com",
        autocapture: false,
      });
      if (user?._id) {
        posthog.identify(user._id, {
          email: user.email,
          name: user.name || `${user.first_name} ${user.last_name}`.trim(),
          first_name: user.first_name,
          last_name: user.last_name,
        });
      }
    }
  };

  const trackPageView = useCallback(
    (pathname) => {
      if (consent) {
        if (config.FACEBOOK_PIXEL) {
          ReactPixel.pageView();
        }
        if (config.REDDIT_PIXEL) {
          RedditPixel.pageVisit();
        }
        trackTagManager("pageview", { pagePath: pathname });
        posthog.capture("$pageview", {
          path: pathname,
        });
      }
    },
    [consent]
  );

  const trackTagManager = useCallback(
    (event, data = {}) => {
      if (consent && !!config.GOOGLE_TAG_ID) {
        TagManager.dataLayer({
          dataLayer: {
            event,
            ...data,
          },
        });
      }
    },
    [consent]
  );

  const trackReddit = useCallback(
    (event, data = undefined) => {
      if (consent) {
        RedditPixel.track(event, data);
      }
    },
    [consent]
  );

  const trackFacebook = useCallback(
    (event, data) => {
      if (consent) {
        ReactPixel.track(event, data);
      }
    },
    [consent]
  );

  const trackPosthog = useCallback(
    (event) => {
      if (consent) {
        posthog?.capture(event);
      }
    },
    [consent]
  );

  const trackCreditCard = useCallback(
    async (user) => {
      trackTagManager("add-credit-card", {
        user_data: {
          email_address: user?.email,
          sha256_email_address: await PerformanceUtils.sha256(user?.email),
        },
      });
      trackFacebook("AddPaymentInfo");
      trackReddit("Lead");
    },
    [consent]
  );

  const trackRegistration = useCallback(
    async (user) => {
      trackTagManager("registration", {
        user_data: {
          email_address: user?.email,
          sha256_email_address: await PerformanceUtils.sha256(user?.email),
        },
      });
      trackFacebook("CompleteRegistration");
      trackReddit("SignUp");
    },
    [consent]
  );

  const trackSubscription = useCallback(
    async (user, value) => {
      trackTagManager("subscription", {
        value,
        user_data: {
          email_address: user?.email,
          sha256_email_address: await PerformanceUtils.sha256(user?.email),
        },
      });
      trackFacebook("Subscription", { value });
      trackReddit("Purchase");
    },
    [consent]
  );

  const trackSinglePayment = useCallback(
    async (user, value) => {
      trackTagManager("subscription", {
        value,
        user_data: {
          email_address: user?.email,
          sha256_email_address: await PerformanceUtils.sha256(user?.email),
        },
      });
      trackFacebook("Purchase", { value });
      trackReddit("Purchase");
    },
    [consent]
  );

  return {
    trackTagManager,
    posthog,
    consent,
    trackPageView,
    trackPosthog,
    trackCreditCard,
    trackRegistration,
    trackSubscription,
    trackSinglePayment,
  };
};

export default useAnalytics;
