import React from "react";
import logo from "assets/images/ludo-logo-head.png";
import CircularProgress from "@mui/material/CircularProgress";
import "./style.scss";

const GradientCircularProgress = (props) => {
  return (
    <>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#4E9AF6" />
            <stop offset="100%" stopColor="#7F2BEE" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress
        sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
        {...props}
      />
    </>
  );
};

// Update decoration pairs with optional custom properties for animation start/end
const decorationPairs = [
  // This decoration starts slightly off the left edge
  {
    left: "0%", // starting outside
    top: "20%",
    size: 100,
    // Only moving inside normally
    "--moveX": "100px",
    "--moveY": "50px",
  },
  // This decoration will move so that it ends outside on the right/bottom
  {
    left: "70%",
    top: "0",
    size: 150,
    "--moveX": "-120px",
    "--moveY": "80px",
    "--startX": "0px",  // starting value
    "--startY": "0px",
  },
  // Regular decoration that moves normally
  {
    left: "50%",
    top: "70%",
    size: 80,
    "--moveX": "80px",
    "--moveY": "-100px",
  },
  {
    left: "0",
    top: "50%",
    size: 120,
    "--moveX": "-90px",
    "--moveY": "-70px",
  },
  {
    left: "80%",
    top: "60%",
    size: 90,
    "--moveX": "60px",
    "--moveY": "120px",
  },
];

const LoadingPage = () => {
  return (
    <div className="loading-page">
      <div className="center-container">
        <div className="logo-container">
          <GradientCircularProgress thickness={2} />
          <img src={logo} alt="Ludo Logo" className="logo" />
        </div>
        <span className="title">Preparing Ludo.ai</span>
        <span className="subtitle">
          Loading your data and some other awesome stuff
        </span>
      </div>
      <div className="decorations">
        {decorationPairs.map((pair, index) => (
          <React.Fragment key={index}>
            <Square
              style={{
                left: pair.left,
                top: pair.top,
                width: `${pair.size}px`,
                height: `${pair.size}px`,
                // Pass along our custom animation values as CSS variables
                "--moveX": pair["--moveX"],
                "--moveY": pair["--moveY"],
                "--startX": pair["--startX"],
                "--startY": pair["--startY"],
                "--endX": pair["--endX"],
                "--endY": pair["--endY"],
                animationDelay: pair.delay,
              }}
            />
            <SmallCircle
              style={{
                left: pair.left,
                top: pair.top,
                width: `${pair.size}px`,
                height: `${pair.size}px`,
                "--moveX": pair["--moveX"],
                "--moveY": pair["--moveY"],
                "--startX": pair["--startX"],
                "--startY": pair["--startY"],
                "--endX": pair["--endX"],
                "--endY": pair["--endY"],
                animationDelay: pair.delay,
              }}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

const Square = ({ style = {}, className = "" }) => {
  return (
    <div className={`square ${className}`} style={style}>
      <div className="inner" />
    </div>
  );
};

const SmallCircle = ({ style = {}, className = "" }) => {
  return <div className={`small-circle ${className}`} style={style} />;
};

export default LoadingPage;
