import React, { useState, useEffect, useContext, useMemo } from "react";
import "./style.scss";
import { useHistory } from "react-router";
import AuthContext from "../../context/AuthContext";
import SocketContext from "../../context/SocketContext";
import CacheContext from "../../context/CacheContext";
import logoText from "assets/images/logo-text-white.png";
import featureAssistantImage from "assets/images/misc/feature-assistant.png";
import featureTrendsImage from "assets/images/misc/feature-trends.png";
import featureConceptImage from "assets/images/misc/feature-concept.png";
import featureIdeatorImage from "assets/images/misc/feature-ideator.png";
import featureMarketAnalysisImage from "assets/images/misc/feature-market-analysis.png";
import featureSearchImage from "assets/images/misc/feature-search.png";
import featureImageGeneratorImage from "assets/images/misc/feature-image-generator.png";
import featureStepImage from "assets/images/misc/feature-step.png";
import featureNewConceptImage from "assets/images/misc/feature-new-concept.png";
import feature3dImage from "assets/images/misc/feature-3d.png";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { ImportConcept } from "../Headquarters2";
import { addNewProject } from "../../pages/GDD3/Helpers";
import APIContext from "../../context/APIContext";
import { ThreeDAsset } from "../../pages/ThreeDGenerator";
import _ from "lodash";
import { CircularProgress, IconButton, Tooltip } from "@material-ui/core";
import GeneratedGameCard from "../../components/common/GeneratedGameCard";
import { useContainerQuery } from "react-container-query";
import {
  ArrowUpwardOutlined,
  AutoAwesomeOutlined,
} from "@mui/icons-material";
import { textToUniversalOption } from "../../components/Controls/UniversalInput";

const getShowcaseGallery = "getShowcaseGallery";

const CONTAINER_QUERY = { small: { maxWidth: 767 } };

const Headquarters3 = () => {
  const [activeSection, setActiveSection] = useState(null);
  const history = useHistory();
  const { call } = useContext(APIContext);
  const { track } = useContext(SocketContext);
  const { auth } = useContext(AuthContext);
  const { cache, setCache } = useContext(CacheContext);
  const { credits } = cache;

  const [params, containerRef] = useContainerQuery(CONTAINER_QUERY);
  const isMobile = params.small;

  const defaultPlatform = auth.user.platform;

  const [showImportModal, setShowImportModal] = useState(false);

  const navigateTo = (path) => {
    history.push(path);
  };

  const onOptionSelected = (option) => {
    track("hq.option", { optionId: option.id, sectionId: activeSection?.id });
    option.action && option.action();
  };

  const onActiveSection = (section) => {
    track("hq.section", { sectionId: section?.id });
    setActiveSection(section?.id === activeSection ? null : section?.id);

    // If we're activating a section (not deactivating), scroll to options
    if (isMobile && section?.id !== activeSection) {
      // Use setTimeout to ensure the options container is rendered before scrolling
      setTimeout(() => {
        const optionsContainer = document.querySelector(".options-container");
        if (optionsContainer) {
          optionsContainer.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }, 100);
    }
  };

  const onNewConcept = async () => {
    let project = await addNewProject(
      cache.projects,
      call,
      setCache,
      defaultPlatform
    );
    if (project) {
      let path = {
        pathname: "/game-concept/" + project._id,
      };
      history.push(path);
    }
  };

  const sections = [
    {
      id: "brainstorming",
      title: "Brainstorming",
      description: "Create new game ideas",
      image: featureIdeatorImage,
      options: [
        {
          id: "step-by-step",
          title: "I don't have any ideas yet",
          description: "Guided step-by-step ideation",
          name: "Step-by-Step Ideator",
          image: featureStepImage,
          action: () => navigateTo("/step-by-step"),
        },
        {
          id: "game-ideator",
          title: "I already have some ideas or keywords",
          description: "Generate ideas from your input",
          name: "Game Ideator",
          image: featureIdeatorImage,
          action: () => navigateTo("/game-ideator"),
        },
        {
          id: "chat-assistant",
          title: "I want to chat and brainstorm with AI",
          description: "Have a conversation with AI",
          name: "Chat Assistant",
          image: featureAssistantImage,
          action: () => navigateTo("/assistant"),
        },
      ],
    },
    {
      id: "concept",
      title: "Concept Development",
      description: "Develop and flesh out a game concept",
      image: featureConceptImage,
      options: [
        {
          id: "import-gdd",
          title: "I already have a game concept",
          name: "Import Game Concept",
          description: "Import your existing design document",
          image: featureConceptImage,
          action: () => setShowImportModal(true),
        },
        {
          id: "step-by-step",
          title: "I don't have any ideas yet",
          description: "Guided step-by-step ideation",
          name: "Step-by-Step Ideator",
          image: featureStepImage,
          action: () => navigateTo("/step-by-step"),
        },
        {
          id: "game-ideator",
          title: "I already have some ideas or keywords",
          description: "Generate ideas from your input",
          name: "Game Ideator",
          image: featureIdeatorImage,
          action: () => navigateTo("/game-ideator"),
        },
      ],
    },
    {
      id: "research",
      title: "Market Insights",
      description: "Research market trends",
      image: featureTrendsImage,
      options: [
        {
          id: "market-trends",
          title: "See market overview",
          name: "Market Trends",
          description: "Explore industry trends and insights",
          image: featureTrendsImage,
          action: () => navigateTo("/trends"),
        },
        {
          id: "market-analysis",
          name: "Market Analysis",
          title: "Run detailed analysis of a specific game or topic",
          description: "Deep dive into market data",
          image: featureMarketAnalysisImage,
          action: () => navigateTo("/market-analysis"),
        },
        {
          id: "search",
          name: "Search",
          title: "Find reference materials",
          description: "Search for games and resources",
          image: featureSearchImage,
          action: () => navigateTo("/search"),
        },
      ],
    },
    {
      id: "assets",
      title: "Asset Creation",
      description: "Generate visual assets for your game",
      image: featureImageGeneratorImage,
      options: [
        {
          id: "image-generator",
          name: "Image Generator",
          title: "Generate images",
          description: "Create 2D artwork and visuals",
          image: featureImageGeneratorImage,
          action: () => navigateTo("/image-generator"),
        },
        {
          id: "3d-generator",
          name: "3D Generator",
          title: "Generate 3D models",
          description: "Create 3D assets and environments",
          image: feature3dImage,
          action: () => navigateTo("/3d-generator"),
        },
        {
          id: "asset-assistant",
          name: "Chat Assistant",
          title: "Chat with AI to help me create assets",
          description: "Get guidance for asset creation",
          image: featureAssistantImage,
          action: () => navigateTo("/assistant"),
        },
      ],
    },
  ];

  let headerFormWrapperClassName = "header-form-wrapper d-flex flex-column";
  if (credits && credits.max) headerFormWrapperClassName += " short";

  return (
    <div className="headquarters3" ref={containerRef}>
      <div className="header">
        <div className="top-bg-content">
          <div className={headerFormWrapperClassName}>
            <div className="hq-form-wrapper">
              <img alt="Ludo" className="d-block m-auto logo" src={logoText} />
              {/*<div className="form">
                <div className="d-flex flex-row input-fields">
                  <div className="info-text">
                    <span className="text">
                      Changing these settings affect your overall Ludo.ai
                      experience
                    </span>
                    <Hint
                      hint="These will be your new defaults for all Ludo tools"
                      iconClassName="help text-white-important"
                      placement="bottom"
                    />
                  </div>
                  <GenreSelection />
                  <PlatformSelection chips={true} />
                </div>
              </div>*/}
            </div>
          </div>
        </div>
      </div>

      {showImportModal && (
        <ImportConcept onClose={() => setShowImportModal(false)} />
      )}
      <div className="main-content">
        <div className="welcome-message">
          <h1>Hi {auth?.user?.first_name || "there"}, welcome to Ludo.ai!</h1>
          <h2 className="gradient-text3">
            What would you like to do or explore right now?
          </h2>
        </div>

        <div className="section-buttons">
          {sections.map((section) => (
            <div
              key={section.id}
              className={`section-button ${
                activeSection === section.id ? "active" : ""
              }`}
              onClick={() => onActiveSection(section)}
            >
              <div className="section-text">
                <div className="title">{section.title}</div>
                <div className="subtitle">{section.description}</div>
              </div>
            </div>
          ))}
        </div>

        {activeSection ? (
          <div className="options-section">
            <div className="section-desc">
              <span>Let's unlock your creative potential!</span>
              <h3>
                Here are the specialized AI tools to help with your game
                development:
              </h3>
            </div>

            <div className="options-container">
              {sections
                .find((s) => s.id === activeSection)
                ?.options.map((option) => (
                  <div
                    key={option.id}
                    className="option-card"
                    onClick={() => onOptionSelected(option)}
                  >
                    <div className="option-image">
                      <img
                        src={option.image || feature3dImage}
                        alt={option.title}
                      />
                    </div>
                    <div className="option-content">
                      <h4>{option.title}</h4>
                      <p>{option.description}</p>
                      <button className="option-button">{option.name}</button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <ShowcaseGallery />
        )}
      </div>
    </div>
  );
};

const ShowcaseGallery = () => {
  const { call } = useContext(APIContext);
  const [data, setData] = useState([]);
  const [showBackToTop, setShowBackToTop] = useState(false);

  useEffect(() => {
    call(getShowcaseGallery).then((response) => {
      if (response.ok) {
        setData(response.body);
      }
    });

    // Get the scroll container
    const scrollContainer = document.querySelector(
      ".app-content--inner__wrapper"
    );

    if (scrollContainer) {
      // Add scroll event listener to show/hide the back to top button
      const handleScroll = () => {
        if (scrollContainer.scrollTop > 300) {
          setShowBackToTop(true);
        } else {
          setShowBackToTop(false);
        }
      };

      scrollContainer.addEventListener("scroll", handleScroll);
      return () => scrollContainer.removeEventListener("scroll", handleScroll);
    }
  }, []);

  const scrollToTop = () => {
    const scrollContainer = document.querySelector(
      ".app-content--inner__wrapper"
    );
    if (scrollContainer) {
      scrollContainer.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const items = useMemo(() => {
    let newImages =
      _.shuffle(
        (data.images || []).map((image) => {
          return { image, id: image.id };
        })
      ).slice(0, 15) || [];
    let newAssets = _.shuffle(
      (data.assets || []).map((asset) => {
        return { asset, id: asset.image.id };
      })
    ).slice(0, 15);
    let newIdeas = _.shuffle(
      (data.ideas || []).map((idea) => {
        return { idea, id: idea.id };
      })
    ).slice(0, 15);
    return _.shuffle([...newImages, ...newAssets, ...newIdeas]);
  }, [data]);

  return (
    <div className="showcase-gallery-container">
      <div className="gallery-header">
        <hr />
        <h2 className="gallery-title">Creative Inspiration Gallery</h2>
        <p className="gallery-description">
          Browse through these examples to spark ideas for your game development
        </p>
      </div>
      {data.length === 0 && (
        <center>
          <CircularProgress size={100} color="white" />
        </center>
      )}
      <div className="showcase-gallery">
        <ResponsiveMasonry
          columnsCountBreakPoints={{
            350: 1,
            600: 2,
            1000: 3,
            1500: 4,
            1900: 5,
            2400: 6,
          }}
        >
          <Masonry gutter="15px">
            {items.map((item) => (
              <ShowcaseItem key={item.id} item={item} />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </div>

      {/* Back to Top Button - Only shows on mobile and when scrolled down */}
      {showBackToTop && (
        <div className="back-to-top-button" onClick={scrollToTop}>
          <IconButton color="primary">
            <ArrowUpwardOutlined />
          </IconButton>
        </div>
      )}
    </div>
  );
};

const ShowcaseItem = ({ item: { image, asset, idea } }) => {
  const { track } = useContext(SocketContext);
  const history = useHistory();

  let text = "",
    title = "";
  if (image) {
    title = `Generated ${image.image_type || ""} Image`;
    text = image.original_hints?.join(", ");
  } else if (asset) {
    title = "3D Asset";
    text = asset.image.original_hints?.join(", ");
  } else if (idea) {
    title = "Game Idea";
    text = idea.hints?.join(", ");
  }

  function onGenerateSimilar() {
    if (image) {
      track("hq.showcase.image.generate-similar", { image });
      const data = {
        image_type:
          image.image_type ||
          (image.icon || image.image_type === "icon" ? "icon" : "screenshot"),
        search: image.original_hints?.map(hint => textToUniversalOption(hint)),
        platform: image.platform,
      };
      history.push("/image-generator", { data });
    } else if (asset) {
      track("hq.showcase.asset.generate-similar", { asset });
      const data = {
        formValues: {
          hints: asset.image.original_hints
        },
        tab: 0,
      };
      history.push("/3d-generator", { data });
    } else if (idea) {
      track("hq.showcase.idea.generate-similar", { asset });
      const data = {
        search: idea.hints?.map(hint => textToUniversalOption(hint)),
        platform: idea.platform,
        skipSubmit: true
      };
      history.push("/game-ideator", { data });
    }
  }

  return (
    <div className="showcase-item">
      {image && <img src={image.url} />}
      {asset && (
        <ThreeDAsset
          threeDAsset={asset}
          hideActions={true}
          hideViewToggle={true}
          hideThumbnail={true}
        />
      )}
      {idea && (
        <GeneratedGameCard
          game={idea}
          showDevelopButton={false}
          actions={{}}
          smallVersion={true}
        />
      )}
      {text && (
        <div className="tooltip">
          <div className="bg" />
          <span className="title text-capitalize">{title}</span>
          <span>
            Prompt: <i>"{text}"</i>
          </span>
          <div className="button-wrapper">
            <Tooltip
              title="Generate Similar"
              arrow
              PopperProps={{
                className: "MuiTooltip-popper MuiTooltip-popperArrow secondary",
              }}
              placement="top"
            >
              <IconButton onClick={onGenerateSimilar} className="white">
                <AutoAwesomeOutlined className="pointer font-size-xl text-secondary" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  );
};

export default Headquarters3;
