import React, { useContext, useRef, useState, useMemo, useEffect } from "react";
import {
  Avatar,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  TextField,
  Grid,
  InputAdornment,
  Tooltip,
} from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import { Autocomplete } from "@material-ui/lab";
import _ from "lodash";
import APIContext from "context/APIContext";
import ShowIf from "components/common/ShowIf";
import CacheContext from "context/CacheContext";
import { FAVORITE_TYPES, filterFavorites } from "pages/Favorites";
import { useHistory, useLocation } from "react-router";
import { SOURCES } from "components/common/GameCard";
import { convertProxyUrl } from "components/common/ImageGallery";
import {
  Add,
  AddPhotoAlternateOutlined,
  FavoriteOutlined, InsightsOutlined,
  Lightbulb,
  Search,
} from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import mediaDefaultImage from "assets/images/icons/video-placeholder.png";
import { toBase64 } from "components/Controls/FileUpload";
import { useSnackbar } from "notistack";
import {
  getNumberAllowedProjects,
  isProjectEditable,
} from "pages/GDD3/Helpers";
import AuthContext from "context/AuthContext";

const searchGamesByTitle = "searchGamesByTitle";
const searchTopicsByTitle = "searchTopicsByTitle";
const DEFAULT_ARRAY = [];

let currentValue = "";

const DEFAULT_ALLOWED = ["text", "game", "generated_game", "gdd"];

const TYPES = {
  text: "Keyword/Expression",
  game: "Existing Games",
  generated_game: "Game Ideas",
  gdd: "Game Concept",
  topic: "Trending Topics",
};

const MAX_GAMES_TOPICS = 3;
const MAX_GAMES = 20;

const UniversalInput = ({
  name,
  onSetData,
  style,
  value = DEFAULT_ARRAY,
  formik,
  label,
  allowed = DEFAULT_ALLOWED,
  PopperComponent,
  autoFocus,
  uploadMedia = false,
}) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: { "image/*": [], "video/*": [] },
    maxFiles: 1,
  });

  const inputRef = useRef();

  const { auth } = useContext(AuthContext);
  const { call } = useContext(APIContext);
  const { cache } = useContext(CacheContext);
  const {
    allFavorites = DEFAULT_ARRAY,
    projects = DEFAULT_ARRAY,
    selectedProjectId,
  } = cache;

  const numberAllowedProjects = useMemo(() => {
    return getNumberAllowedProjects(auth);
  }, [auth]);

  const generatedGameOptions = useMemo(() => {
    if (!allowed.includes("generated_game")) return [];
    return filterFavorites(allFavorites, FAVORITE_TYPES.generated_game).map(
      generatedGameToUniversalOption
    );
  }, [allFavorites, allowed]);

  const gddOptions = useMemo(() => {
    if (!allowed.includes("gdd")) return [];
    return projects
      .filter((project) =>
        isProjectEditable(projects, project._id, numberAllowedProjects) && project.gdd2
      )
      .map((project) => GDDToUniversalOption(project.gdd2, project._id));
  }, [projects, allowed, numberAllowedProjects]);

  const activeProject = useMemo(() => {
    if (!allowed.includes("gdd")) return;
    let project = (projects || []).find(
      (project) => project._id === selectedProjectId
    );
    if (project && project.gdd2?.sections?.length > 0)
      return GDDToUniversalOption(project.gdd2, project._id);
  }, [projects, selectedProjectId, allowed]);

  const defaultOptions = activeProject ? [activeProject] : [];

  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(defaultOptions);
  const [input, setInput] = useState("");
  const [autocompleteKey, setAutocompleteKey] = useState(1);
  const [myFiles, setMyFiles] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    //under 30MB is ok
    let goodFiles = acceptedFiles?.filter(({ size }) => size < 30 * 1048576);

    if (goodFiles.length < acceptedFiles.length) {
      let message = "File not accepted: maximum filesize is 30MB";
      let key = "large-file";
      enqueueSnackbar(message, {
        key,
        variant: "error",
        autoHideDuration: 5000,
        onClick: () => closeSnackbar(key),
      });
    }

    setMyFiles([...myFiles, ...goodFiles]);
  }, [acceptedFiles]);

  useEffect(() => {
    Promise.all(myFiles.map(fileToUniversalOption)).then((converted) => {
      let nonExisting = converted.filter((val) => {
        return !value.find(
          (existingValue) =>
            existingValue.id.toLowerCase() === val.id.toLowerCase()
        );
      });
      if (nonExisting.length > 0) {
        addValue(nonExisting);
        setAutocompleteKey((prevState) => prevState + 1);
        setMyFiles([]);
      }
    });
  }, [myFiles, value]);

  useEffect(() => {
    if (!value) {
      setInput("");
      setResults([]);
    }
  }, [value]);

  const valueOption = useMemo(() => prepareOption(input), [input]);

  const doSearch = async (query = "") => {
    if (query.length > 1) {
      let data = { query };
      let queryLowerCase = query.toLowerCase();
      let textOption = textToUniversalOption(query);
      let filteredGeneratedOptions = generatedGameOptions
        ?.filter((option) => option.label.toLowerCase().includes(queryLowerCase))
        .slice(0, 20);
      let filteredGDDOptions = gddOptions
        ?.filter((gddOption) => {
          let title = (gddOption.value?.sections ||
            [])[0]?.value?.title?.toLowerCase();
          return title?.includes(queryLowerCase);
        })
        .slice(0, 20);

      const allowText = allowed.includes("text");
      const allowGenerated = allowed.includes("generated_game");
      const allowGame = allowed.includes("game");
      const allowTopic = allowed.includes("topic");
      const allowGDD = allowed.includes("gdd");

      let existingOptions = value?.filter((val) => val.type !== "text");
      let currentOptions = allowText ? [textOption] : [];
      currentOptions = allowGenerated
        ? [...currentOptions, ...filteredGeneratedOptions]
        : currentOptions;
      currentOptions = allowGDD
        ? [...currentOptions, ...filteredGDDOptions]
        : currentOptions;
      currentOptions =
        allowGame || allowTopic
          ? [...currentOptions, { loading: true }]
          : currentOptions;

      setResults([...currentOptions, ...existingOptions]);

      setLoading((allowGame || allowTopic) && query === currentValue);

      let [gamesResponse, topicsResponse] = await Promise.all([
        allowGame && query === currentValue
          ? call(searchGamesByTitle, data)
          : undefined,
        allowTopic && query === currentValue
          ? call(searchTopicsByTitle, data)
          : undefined,
      ]);

      setLoading(false);

      let options = allowText ? [textOption] : [];
      options = allowGenerated
        ? [...options, ...filteredGeneratedOptions]
        : options;
      options = allowGDD ? [...options, ...filteredGDDOptions] : options;

      if (topicsResponse?.ok) {
        let topics = topicsResponse.body;
        let newResults = topics
          .filter((topic, index) => {
            return (
              topic.title.toLowerCase().trim() === query.toLowerCase().trim() ||
              index < MAX_GAMES_TOPICS
            );
          })
          .map((topic) => topicToUniversalOption(topic));
        options = [...options, ...newResults];
      }

      if (gamesResponse?.ok) {
        let newResults = gamesResponse.body
          .filter((game, index) => {
            return (
              game.title.toLowerCase().trim() === query.toLowerCase().trim() ||
              index < MAX_GAMES
            );
          })
          .map((game) => gameToUniversalOption(game));
        options = [...options, ...newResults];
      }

      setResults(options);
    }
  };

  const debouncedSearch = useRef(_.debounce(doSearch, 250)).current;

  function addValue(newValues = []) {
    if (newValues && newValues.length > 0) {
      let result = [...value];
      newValues.forEach((newValue) => {
        if (newValue) {
          if (newValue.loading) return;
          if (newValue?.type === "text") {
            newValue.label = newValue.label.trim();
            if (!newValue.label) return;
          }
          let exists = !!result.find(
            (val) => val.id.toLowerCase() === newValue.id.toLowerCase()
          );
          if (!exists) result = [...result, newValue];
        }
      });
      onSetData(result);
    }
    setInput("");
    setAutocompleteKey(autocompleteKey + 1);
  }

  function removeValue(existingValue) {
    let newValues = value.filter((val) => val.id !== existingValue.id);
    onSetData(newValues);
  }

  function clickedChip(data) {
    if (data.type === "text") {
      let text = data.value.text;
      setInput(text);
      removeValue(data.value);
      inputRef.current.focus();
    }
  }

  useEffect(() => {
    if (input) {
      debouncedSearch(input);
    }
  }, [input]);

  let className = "w-100 universal-search";
  if (!!input) className += " has-input";

  let placeholder = label;
  if (!placeholder) {
    placeholder = "Type keywords, phrases, game titles...";
  }

  return (
    <FormControl
      className={className}
      key={(valueOption || {}).key}
      error={formik.errors[name]}
      style={style}
    >
      <Grid container className="p-0">
        <ShowIf condition={value?.length > 0}>
          <div className="chips">
            {(value || []).map((data) => {
              return (
                <Chip
                  className="text-white mt-1 mr-1 w-fit"
                  key={data.id}
                  label={data.label}
                  avatar={getAvatar(data)}
                  onDelete={() => removeValue(data)}
                  onClick={() => clickedChip(data)}
                />
              );
            })}
          </div>
        </ShowIf>
        <Grid
          item
          md="auto"
          sm={12}
          className="p-0 input-wrapper"
          style={{ minWidth: "300px" }}
        >
          <Autocomplete
            key={autocompleteKey}
            PopperComponent={PopperComponent}
            name={name}
            autoHighlight={true}
            options={results}
            getOptionLabel={(option) => {
              return option.label || "";
            }}
            clearOnEscape
            onInputChange={(event, newInputValue, reason) => {
              if (reason === "input") {
                currentValue = newInputValue;
                setInput(newInputValue);
              }
            }}
            onChange={(event, value, reason) => {
              if (value) {
                if (value.type === "text" && input)
                  addValue([textToUniversalOption(input)]);
                else addValue([value]);
              }
            }}
            onBlur={() => {
              if (input) addValue([textToUniversalOption(input)]);
            }}
            defaultValue={valueOption}
            value={valueOption}
            className="mt-2"
            renderInput={(params) => (
              <Field
                component={TextField}
                {...params}
                error={formik.errors[name]}
                placeholder={placeholder}
                inputRef={inputRef}
                InputProps={{
                  ...params.InputProps,
                  autoFocus: autoFocus,
                  startAdornment: (
                    <InputAdornment position="start">
                      {(value || []).length === 0 ? <Search /> : <Add />}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <>
                      <ShowIf condition={uploadMedia}>
                        <div className="upload-media-wrapper">
                          <Tooltip
                            title="Upload images or videos"
                            arrow
                            PopperProps={{
                              disablePortal: false,
                              className:
                                "MuiTooltip-popper MuiTooltip-popperArrow",
                            }}
                            placement="top"
                          >
                            <div
                              {...getRootProps({ className: "upload-media" })}
                            >
                              <input {...getInputProps()} />
                              <AddPhotoAlternateOutlined />
                            </div>
                          </Tooltip>
                        </div>
                      </ShowIf>
                      {params.InputProps.endAdornment}
                    </>
                  ),
                  style: { position: "relative", top: "9px" },
                }}
              />
            )}
            freeSolo={true}
            getOptionSelected={(option, value) => {
              if (value) {
                return option.id === value.id || option.label === value.label;
              }
              return false;
            }}
            groupBy={(option) => TYPES[option.type] || option.type}
            renderOption={(option) => (
              <>
                <ShowIf condition={option.image}>
                  <img
                    src={option.image}
                    width="30px"
                    style={{ marginRight: "15px" }}
                  />
                </ShowIf>
                <ShowIf condition={option.imageElement}>
                  {option.imageElement}
                </ShowIf>
                <ShowIf condition={option.label}>
                  <span>{option.label}</span>
                </ShowIf>
                <ShowIf condition={option.image2}>
                  <img
                    src={option.image2}
                    width="15px"
                    style={{ marginLeft: "15px" }}
                  />
                </ShowIf>
                <ShowIf condition={option.loading && loading}>
                  <div className="universal-search-loading-wrapper">
                    <CircularProgress color="inherit" size={20} />
                  </div>
                </ShowIf>
              </>
            )}
          />
        </Grid>
      </Grid>
      {formik.errors[name] && (
        <div className="mt-0">
          <FormHelperText>{formik.errors[name]}</FormHelperText>
        </div>
      )}
    </FormControl>
  );
};

export async function fileToUniversalOption(file) {
  return {
    label: file.name || file.path,
    type: "file",
    image: file.type.startsWith("video")
      ? mediaDefaultImage
      : URL.createObjectURL(file),
    value: {
      id: file.path,
      name: file.name,
      type: file.type,
      data: await toBase64(file),
    },
    id: file.path,
  };
}

export function textToUniversalOption(query) {
  return {
    label: query,
    type: "text",
    image: undefined,
    value: { id: query, text: query },
    id: query,
  };
}

export function topicToUniversalOption(topic) {
  let newTopic = { ...topic };
  newTopic.games = topic.games.map((game) => {
    return game._id || game.game_id || game;
  });
  return {
    label: `${newTopic.title}`,
    type: "topic",
    imageElement: (
      <InsightsOutlined
        width="30px"
        style={{ marginRight: "15px", color: "#7F2BEE" }}
      />
    ),
    image2: undefined,
    value: newTopic,
    id: newTopic.topic_id,
  };
}

export function gameToUniversalOption(game) {
  let developer = (game.developers || [])[0];
  let source = game.source;
  let sourceIcon = SOURCES[source]?.icon
  if (developer) {
    developer = ` (${developer})`;
  }
  return {
    label: `${game.title}${developer || ""}`,
    type: "game",
    image: convertProxyUrl(game.icons?.[0] || game.icon),
    image2: sourceIcon,
    value: game,
    id: game._id,
  };
}

export function imageToUniversalOption(image) {
  let url = convertProxyUrl(image) || "";
  return {
    type: "image",
    image: url,
    value: image,
    id: image.id,
  };
}

export function generatedGameToUniversalOption(game) {
  return {
    label: game.title || "Untitled",
    type: "generated_game",
    value: game,
    id: game.id,
  };
}

export function GDDToUniversalOption(gdd, projectId) {
  if (!gdd) return undefined;
  let summary = (gdd.sections || [])[0];
  let url = convertProxyUrl(gdd.icon) || "";

  let id = projectId || summary?._id;

  gdd.id = id;

  return {
    label: summary?.value?.title || "Untitled",
    type: "gdd",
    value: gdd,
    image: url,
    id,
  };
}

function prepareOption(input = "") {
  return { label: input };
}

function getAvatar(data) {
  if (data.type === "generated_game") {
    return (
      <div className="generated-game-icon ml-3 mr-1">
        <FavoriteOutlined className="font-size-xxxxxxl heart" />
        <Lightbulb className="font-size-md lightbulb" />
      </div>
    );
  } else if (data.type === "topic") {
    return (
      <div className="topic-icon">
        <InsightsOutlined className="font-size-xl trending" />
      </div>
    );
  } else {
    return data.image ? (
      <Avatar
        className="mr-0"
        src={data.image}
        style={{
          width: "32px",
          height: "32px",
          marginLeft: "5px",
        }}
      />
    ) : null;
  }
}

export function convertUniversalInput(data = []) {
  let result = {};
  data.forEach(({ type, value }) => {
    switch (type) {
      case "game":
        result.game_ids = result.game_ids || [];
        result.game_ids.push(value._id);
        break;
      case "generated_game":
        result.generations = result.generations || [];
        result.generations.push(value);
        break;
      case "gdd":
        result.gdds = result.gdds || [];
        result.gdds.push(value);
        break;
      case "text":
        result.hints = result.hints || [];
        result.hints.push(value.text);
        break;
      case "image":
        result.images = result.images || [];
        result.images.push(value);
        break;
      case "topic":
        result.topics = result.topics || [];
        result.topics.push(value);
        break;
      case "file":
        result.files = result.files || [];
        result.files.push({
          name: value.name,
          type: value.type,
          data: value.data,
        });
        break;
    }
  });
  return result;
}

export const ChangeDataOnLocation = ({
  onAction,
  initialValues,
  shouldOverride,
  override,
  fields = DEFAULT_ARRAY,
}) => {
  const formik = useFormikContext();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!!location.state?.data) {
      let data = location.state.data;

      let formikValues = !!initialValues ? initialValues : { ...formik.values };
      let values = { ...formikValues };

      if (!!shouldOverride && shouldOverride(data)) {
        override(location.state.data, formik);
      } else {
        fields.forEach((field) => {
          switch (field) {
            case "search":
              values.search = [];
              if (data.image)
                values.search.push(imageToUniversalOption(data.image));
              if (data.game)
                values.search.push(gameToUniversalOption(data.game));
              if (data.topic)
                values.search.push(topicToUniversalOption(data.topic));
              if (data.generated_game)
                values.search.push(
                  generatedGameToUniversalOption(data.generated_game)
                );
              if (data.project?.gdd2)
                values.search.push(
                  GDDToUniversalOption(data.project.gdd2, data.project._id)
                );
              if (data.gdd) values.search.push(GDDToUniversalOption(data.gdd));
              if (data.text)
                values.search.push(textToUniversalOption(data.text));
              if (data.search) values.search = data.search;
              values.search = _.uniqBy(values.search, "id");
              break;
            default:
              if (data[field]) values[field] = data[field];
          }
        });

        formik.setValues(values);
        if (onAction) onAction(values, data);
      }

      if (data) {
        history.replace({ ...history.location, state: {} });
      }
    }
  }, [location, formik]);

  return null;
};

export default UniversalInput;
