import {
  IconButton,
  Grid,
  Divider, ListItemIcon, CircularProgress, Tooltip
} from "@material-ui/core";
import moment from 'moment';
import React, {useContext, useEffect, useMemo, useState} from "react";
import ShowIf from "components/common/ShowIf";
import './style.scss';
import {ImageFavoriteButton} from "components/common/FavoriteButton";
import ImageGallery, {convertProxyUrl, IconGallery, ImageEnlarge} from "components/common/ImageGallery";
import {Link} from "react-router-dom";
import CacheContext from "context/CacheContext";
import APIContext from "context/APIContext";
import {ShareIcon} from "components/Sharing";
import {GameIcon} from "components/common/GameCard";
import DetailsPanelContext from "context/DetailsPanelContext";
import MyButton from "components/Controls/MyButton";
import SocketContext from "context/SocketContext";
import {chooseTrend} from "pages/Trends/TrendGames";
import {useHistory, useLocation} from "react-router";
import {BurstModeOutlined, ModeEditOutline, ModeEditOutlined, SearchOutlined} from "@mui/icons-material";

const getGameInformation = 'getGameInformation';

const ImageDetailsPanel = ({containerRef}) => {

  const {call} = useContext(APIContext);
  const {cache} = useContext(CacheContext);
  const {showGame, showDeveloper} = useContext(DetailsPanelContext);
  const {detailsPanel} = cache;
  const [game, setGame] = useState(undefined);
  const [gameTrends, setGameTrends] = useState(undefined);

  const {image} = detailsPanel;
  const {game_id} = image;

  useEffect(() => {
    if (game_id) {
      call(getGameTrends, {id: game_id}).then(response => {
        if (response.ok && response.body.length > 0) {
          setGameTrends(response.body)
        }
      });
      call(getGameInformation, {id: game_id}).then(response => {
        if (response.ok) {
          setGame(response.body);
        }
      });
    }
  }, [game_id]);

  function onClickGame() {
    showGame(game);
  }

  function onClickDeveloper() {
    showDeveloper(game.developers[0], game.source);
  }

  return (
    <>
      <ImageContent
        key={image?.url}
        image={image}
        game={game}
        gameTrends={gameTrends}
        onClickGame={onClickGame}
        onClickDeveloper={onClickDeveloper}
        containerRef={containerRef}
      />
    </>
  )
};

const searchImagesByExistingImage = 'searchImagesByExistingImage';
const getGameTrends = 'getGameTrends';

const ImageContent = ({image, game, gameTrends, onClickGame, onClickDeveloper, containerRef}) => {

  const [similar, setSimilar] = useState(undefined);
  const {call} = useContext(APIContext);
  const {icon} = image;
  const type_filter = icon ? 'icon' : 'screenshot';

  useEffect(() => {

    let number = image.icon ? 31 : 12;

    call(searchImagesByExistingImage, {data: {image, filters: {n: number, type_filter: [type_filter]},}})
      .then(response => {
        if (response.ok) {
          let images = response.body;
          images = images.filter(result => result.id !== image.id);
          setSimilar(images)
        } else {
          setSimilar([]);
        }
      })
  }, [image, type_filter]);

  return (
    <div className="image-details content p0">
      <Header
        key={image?.url}
        image={image}
        containerRef={containerRef}
      />
      <div className="body">
        <Divider className="w-100 mt-0"/>
        <ShowIf condition={!!image.game_id}>
          <Actions image={image} game={game}/>
          <Divider className="w-100"/>
        </ShowIf>
        <ShowIf condition={!!game}>

          <span
            className="text-capitalize mt-4 d-block mx-auto w-100 text-align-center font-weight-bold font-size-xl opacity-6">
            Related game
          </span>

          <GameDetails icon={icon} gameTrends={gameTrends} game={game} onClickGame={onClickGame}
                       onClickDeveloper={onClickDeveloper}/>

          <span
            className="text-capitalize mt-4 d-block mx-auto w-100 text-align-center font-weight-bold font-size-xl opacity-6">
            Similar {image.icon ? "Icons" : "Images"}
          </span>

          <div className="gallery text-align-center my-4 px-1">
            <ShowIf condition={!similar}>
              <div className="text-align-center m-4">
                <CircularProgress size={55}/>
              </div>
            </ShowIf>
            <ShowIf condition={!!similar}>
              <ShowIf condition={(similar || []).length === 0}>
                <span className="d-block">No similar images found!</span>
              </ShowIf>
              <ShowIf condition={(similar || []).length > 0}>
                <ShowIf condition={image.icon}>
                  <IconGallery
                    icons={similar}
                    width={100}
                    spacing={1}
                    showDetailsControl={false}
                  />
                </ShowIf>
                <ShowIf condition={!image.icon}>
                  <ImageGallery
                    key={game?._id}
                    images={similar || []}
                    onImageClick={true}
                  />
                </ShowIf>
              </ShowIf>
            </ShowIf>
          </div>
        </ShowIf>
        <ShowIf condition={image.game_id && !game}>
          <div className="m-4 text-align-center">
            <CircularProgress size={55}/>
          </div>
        </ShowIf>
      </div>
    </div>
  )
};

const GameDetails = ({
                       game,
                       gameTrends,
                       onClickGame,
                       onClickDeveloper,
                       icon,
                       gameGenre,
                       location = "United States",
                       chart
                     }) => {


  const trend = useMemo(() => {
    return chooseTrend(gameTrends, gameGenre, location, chart);
  }, [gameTrends, location, gameGenre, chart]);

  let {
    current_rank,
    current_rank_chart,
    peak_rank,
    peak_date,
    genre,
    peak_chart,
    country
  } = trend;

  let secondTitle = "Date of Peak Rank";
  let secondVal1 = moment.unix(peak_date).format('MMM DD');
  let secondVal2 = moment.unix(peak_date).format('YYYY');

  if (!!current_rank) {
    secondTitle = "Current Rank";
    secondVal1 = current_rank;
    secondVal2 = `${country}, ${genre}, ${current_rank_chart}`
  }

  return (
    <div className="game-info text-align-center my-4">
      <ShowIf condition={!icon}>
        <div className="clickable" onClick={onClickGame}>
          <GameIcon game={game}/>
        </div>
      </ShowIf>
      <div className="text-align-center title link m-auto" onClick={onClickGame}>
        {game.title}
      </div>
      <div className="text-align-center link hover-secondary m-auto" onClick={onClickDeveloper}>
        {game.developers[0]}
      </div>
      <div className="more-box">
        <Grid container>
          <ShowIf condition={true}>
            <ShowIf condition={!!gameTrends && gameTrends.length > 0}>
              <Grid item xs={6} lg={6}>
                <div className="info-element">
                  <span className="info-title">Peak Rank</span>
                  <div className="info-content d-flex flex-column">
                    <span>{peak_rank}</span>
                    <span className="info-small">{country}, {genre}, {peak_chart}</span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={6} lg={6}>
                <div className="info-element">
                  <span className="info-title">{secondTitle}</span>
                  <div className="info-content d-flex flex-column">
                    <span>{secondVal1}</span>
                    <span className="info-small">{secondVal2}</span>
                  </div>
                </div>
              </Grid>
            </ShowIf>
          </ShowIf>
          <Grid item xs={12} lg={12}>
            <MyButton id="image-details.open-game-panel" color="secondary" className="w-100" onClick={onClickGame}>
              More About This Game
            </MyButton>
          </Grid>
        </Grid>
      </div>
    </div>);
};

const Header = ({image, containerRef}) => {

  const url = useMemo(() => convertProxyUrl(image), [image]);

  let isHorizontal = image.height && image.height < image.width;
  let imageClassName = isHorizontal ? "horizontal" : "vertical";

  return (
    <div className="header">
      <div className="image-wrapper">
        <ImageEnlarge
          anchor={containerRef.current}
          image={image}
          placement="left"
        >
          <img
            key={image?.url}
            className={imageClassName}
            src={url}
          />
        </ImageEnlarge>
      </div>
    </div>
  )
};

const Actions = ({image, game}) => {

    const history = useHistory();
    const location = useLocation();
    const {track} = useContext(SocketContext);
    const {setCache} = useContext(CacheContext);

    function trackWrapper(event, action) {
      track(action, {game});
    }

    async function generateSimilar(event) {
      trackWrapper(event, 'details-panel.image.generate-similar-images', true)
      let url = '/image-generator';
      history.push(url, {
        data: {
          image: image.originalImage || image,
          image_type: image.image_type | (image.icon || image.image_type === 'icon' ? 'icon' : 'screenshot'),
          currentPath: location.pathname
        }
      });
    }

    return (
      <div className="actions">
        <Grid container justifyContent="space-evenly" spacing={0}>

          <Grid item className="text-align-center" md={3}>
          <span className="actions-content">
              <ImageFavoriteButton image={image}/>
            <span className="small">Favorites</span>
          </span>
          </Grid>
          <Grid item className="text-align-center" md={3}>
            <span className="actions-content">
              <Tooltip
                title="Search similar"
                arrow
                PopperProps={{className: "MuiTooltip-popper MuiTooltip-popperArrow secondary"}}
                placement="top"
              >
              <Link
                to={{
                  pathname: '/search',
                  state: {
                    data: {
                      image: image.originalImage || image,
                    }
                  }
                }}
                onClick={event => trackWrapper(event, 'details-panel.image.search-similar', true)}
              >
                <ListItemIcon>
                  <IconButton component="span">
                    <SearchOutlined/>
                  </IconButton>
                </ListItemIcon>
              </Link>
              </Tooltip>
              <span className="small">
                Search similar
              </span>
            </span>
          </Grid>
          <Grid item className="text-align-center" md={3}>
            <span className="actions-content">
              <Tooltip
                title={`Generate similar ${image.icon ? 'icons' : 'images'}`}
                arrow
                PopperProps={{className: "MuiTooltip-popper MuiTooltip-popperArrow secondary"}}
                placement="top"
              >
              <div
                onClick={generateSimilar}
              >
                <ListItemIcon>
                  <IconButton component="span" style={{padding: "6px"}}>
                    <ModeEditOutlined/>
                  </IconButton>
                </ListItemIcon>
              </div>
              </Tooltip>
              <span className="small">
                Generate similar {image.icon ? 'icons' : 'images'}
              </span>
            </span>
          </Grid>
          <Grid item className="text-align-center" md={3}>
            <ShareIcon data={{image}}/>
          </Grid>
        </Grid>
      </div>
    )
  }
;

export default ImageDetailsPanel;
