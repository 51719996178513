import { useCallback, useEffect, useState } from "react";
import swaggerClient from "./swagger";
import config from "./config/config.json";
import { useSnackbar } from "notistack";
import * as Sentry from "@sentry/react";

import moment from "moment";
import usePersistedState from "./hooks/usePersistedState";

const opts = {
  url: config.API_URL,
  host: config.API_HOST,
  protocol: config.API_PROTOCOL,
};

const RECONNECT_TIMEOUT = 1000 * 10;

function useApi(logout, setCache) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState({});
  const [client, setClient] = useState();
  const [endpoints, setEndpoints] = useState({});
  const [authorizations, setAuthorizations] = useState({});
  const [trackingIds, setTrackingIds] = useState({});

  useEffect(() => {
    log("Connecting to BE...");

    let timeout;

    function connect() {
      setLoading(true);
      swaggerClient(opts)
        .then((client) => {
          if (!client) throw new Error("Error: Can't connect to API!");
          setClient(client);
          log("Connected to BE!");
        })
        .catch((err) => {
          log("Connection error");
          log(err);
          changeLoading("clientOK", false);
          timeout = setTimeout(() => {
            connect();
          }, RECONNECT_TIMEOUT);
        });
    }

    connect();

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (client) {
      client.authorizations = authorizations;
      setLoggedIn(!!authorizations.Bearer);
    }
  }, [authorizations, client]);

  useEffect(() => {
    if (client) {
      let newEndpoints = {};
      Object.keys(client.apis).forEach((tag) => {
        Object.keys(client.apis[tag]).forEach((endpoint) => {
          newEndpoints[endpoint] = client.apis[tag][endpoint];
        });
      });
      setEndpoints(newEndpoints);
      changeLoading("clientOK", true);
    }
  }, [client, authorizations]);

  const call = useCallback(
    async (id, args, options = {}) => {
      log(id, args);
      let response;
      let key = id + "" + moment().unix() + (options.toastrId || "");

      if (!!options.setError) options.setError();

      if (!options.noLoadingChange) changeLoading(id, true);

      if (!endpoints[id]) return;

      try {
        const trackingHeaders = Object.entries(trackingIds).reduce(
          (acc, [key, value]) => {
            if (value) {
              acc[key] = value;
            }
            return acc;
          },
          {}
        );

        client.requestInterceptor = (request) => {
          request.headers = { ...(request.headers || {}), ...trackingHeaders };
        };

        response = await endpoints[id](args);
        //success
        if (!!options.successMessage) {
          successMessage(key, options.successMessage);
        }
      } catch (err) {
        console.log(err);
        log(id, "failed", err, err.response);
        response = {
          ...err.response,
          message: err.message,
          ok: false,
        };
        if (response.status === 401) {
          logout();
        } else {
          let message = response.body?.message || response.message;

          if (!options.bypass402 && response.status === 402) {
            options.hideErrorMessage = true;
            openLockScreen(message, response.body?.metadata);
          }

          if (response.status === 406) {
            options.hideErrorMessage = true;
          }

          Sentry.captureException(new Error(message), {
            extra: { endpoint: id, message, args: JSON.stringify(args || {}) },
          });
          if (!options.hideErrorMessage && !options.setError) {
            errorMessage(key, message);
          } else if (!!options.setError) {
            options.setError(message);
          }
        }
      }
      if (!options.noLoadingChange) changeLoading(id, false);

      return response;
    },
    [endpoints, closeSnackbar, enqueueSnackbar, trackingIds]
  );

  function openLockScreen(message, countdown) {
    setCache((prevState) => {
      return {
        ...prevState,
        lockScreen: message,
      };
    });
  }

  function successMessage(key, message = "Success") {
    enqueueSnackbar(message, {
      key,
      variant: "success",
      autoHideDuration: 2500,
      onClick: () => closeSnackbar(key),
    });
  }

  function errorMessage(key, message = "Error") {
    enqueueSnackbar(message, {
      key,
      variant: "error",
      autoHideDuration: 5000,
      onClick: () => closeSnackbar(key),
    });
  }

  function setToken(token) {
    if (token) {
      setAuthorizations({ Bearer: `Bearer ${token}` });
    } else {
      setAuthorizations({});
    }
  }

  function changeLoading(id, value) {
    setLoading((prevState) => {
      return { ...prevState, [id]: value };
    });
  }

  const ready = Object.keys(endpoints).length > 0;
  return { loading, client, call, ready, setToken, loggedIn, setTrackingIds };
}

function log(...d) {
  if (config.ENVIRONMENT === "development") console.log(...d);
}

export default useApi;
