import React, { useContext, useState, useRef, useEffect } from "react";
import APIContext from "context/APIContext";
import AuthContext from "context/AuthContext";
import { Formik, Form } from "formik";
import { CircularProgress, Grid, InputAdornment } from "@material-ui/core";
import { FormikTextField } from "formik-material-fields";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import MyButton from "components/Controls/MyButton";
import * as Yup from "yup";
import MailOutlineTwoToneIcon from "@mui/icons-material/MailOutlineTwoTone";
import LockTwoToneIcon from "@mui/icons-material/LockOutlined";
import CacheContext, { DEFAULT_CACHE } from "context/CacheContext";
import logoLarge from "assets/images/logo-white.png";
import ErrorBadge from "components/common/ErrorBadge";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import config from "config/config.json";
import useScript from "hooks/useScript";
import ShowIf from "components/common/ShowIf";
import { Alert } from "@material-ui/lab";
import AnalyticsContext from "../../context/AnalyticsContext";

const EP = "login";

const Login = () => {
  const { call } = useContext(APIContext);
  const { auth, setAuth } = useContext(AuthContext);
  const { setCache, cache } = useContext(CacheContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const history = useHistory();

  const { trackRegistration } = useContext(AnalyticsContext);

  const joinTeamId = auth.joinTeamId;

  async function login(credentials) {
    setLoading(true);
    let response = await call(EP, { auth: credentials }, { setError });
    if (response.ok) {
      localStorage.clear();
      sessionStorage.clear();
      onLoginPayload(response.body, joinTeamId);
    }
    setLoading(false);
  }

  function onLoginPayload(payload = {}, joinTeamId) {
    let { registered } = payload;
    delete payload.registered;
    setAuth(payload, false);
    let selectedProjectId =
      payload.user.selected_project || DEFAULT_CACHE.selectedProjectId;
    let path = cache.unauthenticatedPath || "/";
    setCache({
      ...DEFAULT_CACHE,
      joinTeamId,
      selectedProjectId,
      projectsOpen: !selectedProjectId,
      unauthenticatedPath: undefined,
    });

    if (registered) {
      trackRegistration(payload.user);
    }

    if (joinTeamId) {
      history.push(`/join-team/${joinTeamId}/${payload.user.email}`);
    } else {
      history.push(path);
    }
  }

  return (
    <div className="app-wrapper bg-gradient min-vh-100 login">
      <div className="app-main min-vh-100 top-bg-content">
        <div className="app-content p-0">
          <div className="app-content--inner d-flex align-items-center">
            <div className="app-content--inner__wrapper">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content pb-5 px-2 mb-5">
                  <div className="auth-form-wrapper">
                    <div className="mt-3">
                      <span className="text-center">
                        <h1 className="display-4 mb-4 font-weight-bold font-size-xxxxl">
                          <img
                            width="450"
                            alt="Ludo"
                            className="d-block m-auto logo"
                            src={logoLarge}
                          />
                          Login
                        </h1>
                      </span>

                      <ShowIf condition={!successMessage}>
                        <OAuthLoginForm
                          onLoginPayload={onLoginPayload}
                          setSuccessMessage={setSuccessMessage}
                        />
                        <LoginForm
                          onSubmit={login}
                          loading={loading}
                          error={error}
                        />
                        <div className="text-center pt-4">
                          Don't have an account?{" "}
                          <NavLink
                            to="/register"
                            className="text-white font-weight-bold"
                          >
                            Create an Account
                          </NavLink>
                        </div>
                      </ShowIf>
                      <ShowIf condition={!!successMessage}>
                        <Alert severity="success" className="mt-5 mb-2">
                          {successMessage}
                        </Alert>
                      </ShowIf>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const OAuthLoginForm = ({ onLoginPayload, setSuccessMessage }) => {
  const { call } = useContext(APIContext);
  const [loadingOAuth, setLoadingOAuth] = useState(false);
  const history = useHistory();

  const noPaywall = true;

  function onResponse(response) {
    if (response.ok) {
      if (response.body?.user._id) {
        onLoginPayload(response.body);
      } else {
        history.push("/register?email=" + response.body.user.email);
      }
    } else {
      setLoadingOAuth(false);
    }
  }

  const responseGoogle = async (data) => {
    if (data.credential) {
      setLoadingOAuth(true);
      let token = { value: data.credential };
      let response = await call("authenticateGoogle", {
        token,
        register: noPaywall ? "true" : "false",
      });
      onResponse(response);
    }
  };

  const responseFacebook = async (data) => {
    if (data.accessToken) {
      setLoadingOAuth(true);
      let token = { value: data.accessToken };
      let response = await call("authenticateFacebook", { token });
      onResponse(response);
    }
  };

  return (
    <div className="auth-outer-wrapper">
      <div className="auth-wrapper">
        <ShowIf condition={!loadingOAuth}>
          <div className="mt-4 text-white text-center mb-4">
            <span>
              By proceeding, you agree to our{" "}
              <a
                tabIndex="-1"
                href="https://ludo.ai/privacy-policies"
                target="_blank"
                className="text-white underline"
              >
                Terms of Service and Privacy Policy
              </a>
            </span>
          </div>
          <Grid container spacing={1} justifyContent="center">
            <GoogleLogin onGoogleSignIn={responseGoogle} />
            {/*<Grid item xs={12} sm={6}>
              <FacebookLogin
                disableMobileRedirect={true}
                appId={config.FACEBOOK_APP_ID}
                autoLoad={false}
                fields="name,email"
                callback={responseFacebook}
                cssClass="my-facebook-button-class"
                icon={<Facebook/>}
              />
            </Grid>*/}
          </Grid>
        </ShowIf>
        <ShowIf condition={!!loadingOAuth}>
          <div className="text-align-center m-4 d-flex flex-column place-content-center align-items-center">
            <CircularProgress
              size={55}
              className="text-white"
              title="Please wait..."
            />
            <span>Please wait...</span>
          </div>
        </ShowIf>
      </div>
      <div className="text-white text-center mb-4">or</div>
    </div>
  );
};

const LoginForm = ({ onSubmit, loading, error }) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      onSubmit={onSubmit}
      validationSchema={ValidationSchema}
    >
      {({ isValid, dirty }) => (
        <Form>
          <div className="mb-4">
            <label className="font-weight-bold mb-1">Enter your email</label>
            <FormikTextField
              name="email"
              variant="outlined"
              size="small"
              fullWidth
              placeholder="yourname@yourmail.com"
              type="email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlineTwoToneIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="mb-4">
            <div className="d-flex justify-content-between position-relative">
              <label className="font-weight-bold mb-1">Password</label>
              <div
                className="d-flex"
                style={{
                  position: "absolute",
                  right: "5px",
                  top: 0,
                  color: "white",
                  cursor: "pointer",
                }}
                onClick={() => setShowPassword(!showPassword)}
              >
                <div className="mr-3">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </div>
                <span
                  style={{
                    top: "1px",
                    position: "relative",
                    width: "35px",
                    textAlign: "right",
                  }}
                >
                  {showPassword ? "Hide" : "Show"}
                </span>
              </div>
            </div>
            <FormikTextField
              name="password"
              variant="outlined"
              size="small"
              fullWidth
              placeholder="Enter your password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockTwoToneIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <NavLink to="/forgot-password" className="text-white">
            Forgot Password?
          </NavLink>
          <ErrorBadge error={error} />
          <MyButton
            className="blue"
            style={{ width: "100%" }}
            loading={loading}
          >
            Sign in
          </MyButton>
        </Form>
      )}
    </Formik>
  );
};

export const GoogleLogin = ({
  onGoogleSignIn = () => {},
  text = "signin_with",
}) => {
  const googleSignInButton = useRef(null);

  useScript("https://accounts.google.com/gsi/client", () => {
    if (!!window.google) {
      window.google.accounts.id.initialize({
        client_id: config.GOOGLE_CLIENT_ID,
        callback: onGoogleSignIn,
      });
      window.google.accounts.id.renderButton(googleSignInButton.current, {
        theme: "outline",
        size: "large",
        width: "250px",
        text,
      });
    }
  });

  return (
    <Grid item xs={12} sm={12}>
      <center>
        <div className="google-login" ref={googleSignInButton} />
      </center>
    </Grid>
  );
};

export default Login;

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .required("No email provided"),
  password: Yup.string()
    .required("No password provided")
    .min(6, "Password is too short - should be 6 characters minimum"),
});
