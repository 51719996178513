import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Editable } from "pages/GDD3/Helpers";
import _ from "lodash";
import { AddOutlined } from "@mui/icons-material";
import { Menu } from "pages/GDD3/GDDGameSummary";
import {
  MENUS,
  ASSET_MENUS,
  NON_LISTED_MENUS,
} from "pages/GDD3/GDDSideMenu/AssetsMenu";
import { useListener } from "react-bus";
import GDDContext from "context/GDDContext";
import {
  DownloadPopup,
  getAssetPayloadId,
  onDownload3DModel,
  ThreeDAsset,
} from "../ThreeDGenerator";
import APIContext from "../../context/APIContext";

const imageTo3D = "imageTo3D";

const GDDAssets = ({
  component,
  active,
  loading,
  scrollTo,
  changeGdd,
  section,
  value,
}) => {
  const ref = useRef();
  const addRef = useRef();
  const { call } = useContext(APIContext);

  const [showDownloadPopup, setShowDownloadPopup] = useState();
  const [downloading3D, setDownloading3D] = useState();
  const [retryGeneration, setRetryGeneration] = useState();

  const { menu, openMenu, preview } = useContext(GDDContext);
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (active && !value?.assets?.length) {
      setSelected("add");
    }
  }, [active, value]);

  useEffect(() => {
    if (!active) setSelected(false);
  }, [active]);

  useEffect(() => {
    if (active && scrollTo && ref.current) {
      setTimeout(() => {
        ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 150);
    }
  }, [active, scrollTo]);

  useEffect(() => {
    if (value?.title === undefined) {
      changeText("3D Assets", "title");
    }
  }, [value]);

  const onClick = useCallback(
    (asset) => addAsset(asset),
    [section, value, changeGdd]
  );
  useListener(`${section}.click`, onClick);

  const actionsActive = loading ? false : active;

  function addAsset(asset, nextToId) {
    const currentId = nextToId || menu?.selectedAssetId;
    if (asset) {
      let newAssetsValue;

      if (currentId && value?.assets?.length) {
        // Insert the new asset before the asset with currentId
        newAssetsValue = [];
        let inserted = false;

        for (let i = 0; i < value.assets.length; i++) {
          const existingAsset = value.assets[i];
          if (getAssetPayloadId(existingAsset) === currentId && !inserted) {
            newAssetsValue.push(asset);
            inserted = true;
          }
          newAssetsValue.push(existingAsset);
        }

        // If we couldn't find the asset with currentId, just append the new asset
        if (!inserted) {
          newAssetsValue.push(asset);
        }
      } else {
        // If no currentId, just append the new asset
        newAssetsValue = [...(value?.assets || []), asset];
      }

      let newValue = {
        ...(value || {}),
        assets: _.uniqBy(newAssetsValue, (asset) => getAssetPayloadId(asset)),
      };
      changeGdd(section, newValue, true);
    }
  }

  function removeAsset(asset) {
    if (asset) {
      let newValue = {
        ...(value || {}),
        assets: (value?.assets || []).filter(
          (currentAsset) =>
            getAssetPayloadId(asset) !== getAssetPayloadId(currentAsset)
        ),
      };
      changeGdd(section, newValue, true);
    }
  }

  function changeText(text, field = "text") {
    let newValue = {
      ...(value || {}),
      [field]: text,
    };
    changeGdd(section, newValue, true);
  }

  function onClickedOption(option, asset, input) {
    openMenu({ option, component, section, asset, input });
  }
  function mergeAsset(image, asset) {
    if (asset) {
      let newAssetsValue = [...(value?.assets || [])].map((currentAsset) => {
        if (image.id === currentAsset.image.id) {
          return { ...currentAsset, asset };
        }
        return currentAsset;
      });
      changeGdd(section, { ...(value || {}), assets: newAssetsValue }, true);
    }
  }

  async function onRetryGeneration(asset) {
    const id = getAssetPayloadId(asset);
    setRetryGeneration(id);
    const response = await call(imageTo3D, {
      image: asset.image,
      render_mesh: true,
      render_video: true,
    });
    if (response.ok && response.body) {
      addAsset({ image: asset.image, asset: response.body }, id);
      setRetryGeneration();
    }
  }

  let className = "section assets";
  if (actionsActive) className += " active";
  if (!value?.assets?.length) className += " empty";
  else className += " keep-together";

  let menuWrapperClassName =
    "menu-wrapper d-flex flex-column w-fit hide-preview";
  if (menu?.component?.section === section) menuWrapperClassName += " open";
  if (selected === "add") menuWrapperClassName += " selected";

  let titleClassName = "menu-wrapper editable section-subtitle";
  if (selected === "title") titleClassName += " selected";

  let textClassName = "menu-wrapper editable description";
  if (selected === "text") textClassName += " selected";

  function onClickedAssetOption(option, asset) {
    const selectedAssetId = getAssetPayloadId(asset);
    if (option === ASSET_MENUS.download.id) {
      setShowDownloadPopup(asset);
    } else if (option === ASSET_MENUS.delete.id) {
      removeAsset(asset);
    } else if (ASSET_MENUS.edit.options[option]) {
      let realOption = ASSET_MENUS.edit.options[option];
      if (realOption.id === "retry") {
        onRetryGeneration(asset);
      } else {
        const initialData = realOption.getData
          ? realOption.getData(asset)
          : undefined;
        openMenu({
          option: NON_LISTED_MENUS.generate.id,
          component,
          section,
          initialData,
          selectedAssetId
        });
      }
    } else {
      openMenu({ option, component, section, asset, selectedAssetId });
    }
  }

  async function onDownload(format) {
    setDownloading3D(getAssetPayloadId(showDownloadPopup));
    await onDownload3DModel(format, showDownloadPopup, call);
    setDownloading3D();
  }

  const assetWrapper = useCallback(
    (asset, assetComponent) => {
      let id = getAssetPayloadId(asset);
      const menus = asset.asset?.id
        ? { ...ASSET_MENUS }
        : {
            ...ASSET_MENUS,
            edit: undefined,
            download: undefined,
          };
      if (menus.download) {
        menus.download = { ...menus.download, loading: downloading3D === id };
      }
      if (menus.edit) {
        menus.edit = {
          ...menus.edit,
          loading: retryGeneration === id,
          options: {
           ...menus.edit.options,
            retry: {
             ...menus.edit.options.retry,
              loading: retryGeneration === id,
            },
          },
        }
      }
      return (
        <AddAsset
          key={id}
          component={component}
          onClick={() => setSelected(id)}
          selected={id === selected}
          menus={menus}
          onClickedAssetOption={(option) => onClickedAssetOption(option, asset)}
          asset={asset}
          assetComponent={assetComponent}
        />
      );
    },
    [selected, downloading3D, retryGeneration]
  );

  return (
    <div className={className} ref={ref}>
      <div className="left-content">
        {preview ? (
          <span className="section-subtitle">{value?.title}</span>
        ) : (
          <div className={titleClassName}>
            <Editable
              className="w-100"
              value={value?.title}
              setValue={(value = "") => changeText(value, "title")}
              placeholder="3D Assets"
              onFocus={() => setSelected("title")}
              onBlur={() => setSelected()}
            />
          </div>
        )}
        <div className={textClassName}>
          <Editable
            className="w-100"
            value={value?.text}
            setValue={(value = "") => changeText(value)}
            placeholder="Enter text (optional)"
            onFocus={() => setSelected("text")}
            onBlur={() => setSelected()}
          />
        </div>
      </div>
      <DownloadPopup
        open={!!showDownloadPopup}
        onClose={() => setShowDownloadPopup()}
        onDownload={onDownload}
      />
      <div className="right-content">
        <div className="three-d-assets">
          {value?.assets?.map((asset) => {
            const id = getAssetPayloadId(asset);
            return assetWrapper(
              asset,
              <ThreeDAsset
                onActionClick={() => setSelected(id)}
                threeDAsset={asset}
                onNewVideo={(image, video) => mergeAsset(image, video)}
                forceMode={preview ? "image" : undefined}
                dontExpand={true}
                hideActions={true}
              />
            );
          })}
        </div>
        <div
          className={menuWrapperClassName}
          ref={addRef}
          onClick={() => setSelected("add")}
        >
          <Menu id="assets" options={MENUS} onClick={onClickedOption} />
          <div className="add-image">
            <AddOutlined />
          </div>
        </div>
      </div>
    </div>
  );
};

const AddAsset = ({
  onClick,
  onClickedAssetOption,
  selected,
  menus,
  asset,
  component,
  assetComponent,
}) => {
  let imageMenuWrapperClassName = "menu-wrapper image-menu-wrapper";
  if (selected) imageMenuWrapperClassName += " selected";
  if (!asset) imageMenuWrapperClassName += " hide-preview";
  if (!!asset) imageMenuWrapperClassName += " has-image";

  let modifiedMenus = { ...menus };

  return (
    <div className={imageMenuWrapperClassName} onClick={onClick}>
      <Menu
        id={`elements.${component.section}.asset`}
        options={modifiedMenus}
        onClick={(option) => onClickedAssetOption(option, asset)}
      />
      {assetComponent}
    </div>
  );
};

export default GDDAssets;
