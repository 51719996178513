import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  Slider,
  Switch,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  MenuItem,
  Select,
  DialogContent,
  DialogActions,
  Menu,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import APIContext from "context/APIContext";
import PageTitle from "components/layout-components/PageTitle";
import MyButton, { GeneratingButton } from "components/Controls/MyButton";
import CacheContext from "context/CacheContext";
import usePersistedState from "hooks/usePersistedState";
import ShowIf from "components/common/ShowIf";
import PerformanceUtils from "helpers/PerformanceUtils";
import "./style.scss";
import { convertProxyUrl, MyImage } from "components/common/ImageGallery";
import { ThumbnailPreview, toBase64 } from "components/Controls/FileUpload";
import { GDDModal } from "pages/GDD3/Helpers";
import { FAVORITE_TYPES } from "pages/Favorites";
import { useHistory, useLocation } from "react-router";
import { Hint } from "scenes/Headquarters";
import {
  AutorenewOutlined,
  BrushOutlined,
  CropFreeOutlined,
  DeleteOutline,
  DownloadOutlined,
  FavoriteBorderOutlined,
  FavoriteOutlined,
  FileCopyOutlined,
  HelpOutlineOutlined,
  ModeEditOutline,
  ThreeDRotationOutlined,
  ViewInArOutlined,
} from "@mui/icons-material";
import LoadingTip, { LOADING_TIPS_SECTIONS } from "components/utils/LoadingTip";
import SocketContext from "../../context/SocketContext";
import { Examples } from "../GameGenerator";
import ImageEditor from "../ImageGenerator/ImageEditor";
import FavoriteImagesModal from "../../components/common/Modals/FavoriteImagesModal";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DocumentationModal } from "../../components/utils/HelpIcon";
const cancelGeneration = "cancelGeneration";
const getImagePrompts = "getImagePrompts";
const generate3DImages = "generate3DImages";
const imageTo3D = "imageTo3D";
const export3D = "export3D";
const addFavorite3d = "addFavorite3d";
const removeFavorite = "removeFavorite";
const generateRefined3DImages = "generateRefined3DImages";

const DEFAULT_OBJECT = {};
const DEFAULT_ARRAY = [];

let cancelBatches = [];

const ThreeDGenerator = () => {
  return (
    <div className="w-100 three-d-generator">
      <PageTitle
        titleHeading="3D Asset Generator"
        titleDescription="Generate and edit 3D assets based on your own words."
      ></PageTitle>
      <ThreeDGeneratorResults />
    </div>
  );
};

const TABS = {
  Text: { label: "Text to 3D", ep: generate3DImages, loadingSeconds: 10 },
  Image: {
    label: "Image to 3D",
    ep: generate3DImages,
    loadingSeconds: 10,
    validateForm: (values) => !!values?.image,
    extraValues: { force3D: true },
  },
  Texture: {
    label: "Texture Generation",
    ep: generateRefined3DImages,
    values: { texture_strength: 0, mesh_strength: 1 },
    validateForm: (values) => values?.generated_model || values?.user_model,
    loadingSeconds: 20,
  },
  ModelVariation: {
    label: "Model Variation",
    ep: generateRefined3DImages,
    defaultValues: { texture_strength: 0.5, mesh_strength: 0.5 },
    validateForm: (values) => values?.generated_model || values?.user_model,
    loadingSeconds: 20,
  },
};

const SLIDER_HINTS = {
  texture_strength:
    "Adjust this slider to adjust the texture strength. A lower value results in a more different texture.",
  mesh_strength:
    "Adjust this slider to adjust the mesh strength. A lower value results in a more different.",
};

export const ThreeDGeneratorResults = ({
  fullVersion = true,
  onClick,
  initialData,
  hideMenu,
  onActionsClicked,
}) => {
  const { track } = useContext(SocketContext);
  const { call, loading } = useContext(APIContext);

  const location = useLocation();
  const history = useHistory();

  const [currentBatchId, setCurrentBatchId] = useState(undefined);

  let resultsKey = "ThreeDGenerator.results" + (!fullVersion ? ".small" : "");
  let videosKey = "ThreeDGenerator.videos" + (!fullVersion ? ".small" : "");
  let formValuesKey =
    "ThreeDGenerator.formValues" + (!fullVersion ? ".small" : "");
  let tabKey = "ThreeDGenerator.tab" + (!fullVersion ? ".small" : "");

  const [results, setResults, loadingResults] = usePersistedState(
    resultsKey,
    []
  );
  const [videos, setVideos, loadingVideos] = usePersistedState(videosKey, {});
  const [tab, setTab] = usePersistedState(tabKey, 0);

  const [formValues, setFormValues] = usePersistedState(
    formValuesKey,
    DEFAULT_ARRAY
  );

  useEffect(() => {
    if (!!location.state?.data) {
      let data = location.state.data;
      if (data.tab !== undefined) setTab(data.tab);
      if (data.formValues && data.tab !== undefined)
        setFormValuesWrapper(data.tab, data.formValues);
      if (data) {
        history.replace({ ...history.location, state: {} });
      }
    }
  }, [location]);

  useEffect(() => {
    if (initialData) {
      setTab(initialData.tab);
      setFormValuesWrapper(initialData.tab, initialData.formValues);
    }
  }, [initialData]);

  const loadingGenerateImages =
    loading[generate3DImages] || loading[generateRefined3DImages];

  function cancelGenerationWrapper() {
    if (currentBatchId) {
      cancelBatches.push(currentBatchId);
      call(
        cancelGeneration,
        { generationId: currentBatchId },
        { hideErrorMessage: true }
      );
    }
    setCurrentBatchId(undefined);
  }

  async function onGenerate() {
    let {
      ep,
      values = {},
      defaultValues = {},
      extraValues = {},
    } = Object.values(TABS)[tab];
    let data = {
      request_id: PerformanceUtils.generateId(),
      ...defaultValues,
      ...formValues[tab],
      ...values,
    };

    const imageData = data.image;
    if (imageData) {
      imageData.submit_field = imageData.submit_field || "initial_image_file";
      if (imageData.image) data[imageData.submit_field] = imageData.image;
      if (imageData.cropped)
        data[imageData.submit_field] = {
          ...data[imageData.submit_field],
          ...imageData.cropped,
        };
      else data[imageData.submit_field] = imageData.image || imageData;
      delete data.image;
    }

    track("three-d-generator.generate", {
      tab,
      formValues: { ...data, user_model: !!data.user_model, ...extraValues },
    });

    setCurrentBatchId(data.request_id);
    let response = await call(ep, { data, ...extraValues });
    if (response.ok) {
      if (response.body.length > 0) {
        setResults((prevState) => [
          ...response.body.map((asset) => prepareGeneratedImage(asset.image)),
          ...prevState,
        ]);
        setVideos((prevState) => {
          let newState = { ...prevState };
          response.body.forEach((asset) => {
            if (asset) {
              newState[asset.image.id] = asset.asset;
            }
          });
          return newState;
        });
      }
    }
    setCurrentBatchId(undefined);
  }

  function onCreateNew3DModel(image, video) {
    image = { ...image, id: PerformanceUtils.generateId() };
    video = { ...video };
    setResults((prevState) => [image, ...prevState]);
    setVideos((prevState) => ({ ...prevState, [image.id]: video }));
  }

  const assets = useMemo(() => {
    return results.map((result) => ({
      image: result,
      asset: videos[result.id],
    }));
  }, [results, videos]);

  function setFormValuesWrapper(index, values) {
    setFormValues((prevState = []) => {
      let newFormValues = [...prevState];
      let currentValues = newFormValues[index] || {};

      // Create new object for this tab's values
      let newValues = { ...currentValues };

      // Check if we're setting either model field
      if ('user_model' in values) {
        // If setting user_model (even to undefined), ensure consistency
        newValues.user_model = values.user_model;
        if (values.user_model) {
          // If setting to a value, clear the other model
          newValues.generated_model = undefined;
        }
      }

      if ('generated_model' in values) {
        // If setting generated_model (even to undefined), ensure consistency
        newValues.generated_model = values.generated_model;
        if (values.generated_model) {
          // If setting to a value, clear the user model
          newValues.user_model = undefined;
          newValues.user_model_file_type = undefined;
        }
      }

      // Apply all other changes
      newFormValues[index] = { ...newValues, ...values };
      return newFormValues;
    });
  }

  const renderSelectedForm = () => {
    switch (tab) {
      case 0: // Text to 3D
        return (
          <GenericForm
            formValues={formValues[0]}
            setFormValues={(values) => setFormValuesWrapper(0, values)}
            includeHints={true}
            includeExamples={true}
            textPlaceholder="Describe the 3D asset to generate here"
          />
        );
      case 1: // Image to 3D
        return (
          <GenericForm
            formValues={formValues[1]}
            setFormValues={(values) => setFormValuesWrapper(1, values)}
            includeImages={true}
          />
        );
      case 2: // Texture Generation
        return (
          <GenericForm
            formValues={formValues[2]}
            setFormValues={(values) => setFormValuesWrapper(2, values)}
            includeModel={true}
            includePrompt={true}
            textPlaceholder="Describe the model texture here"
          />
        );
      case 3: // Model Variation
        return (
          <GenericForm
            formValues={formValues[3]}
            setFormValues={(values) => setFormValuesWrapper(3, values)}
            includeModel={true}
            includePrompt={true}
            includeSliders={true}
            textPlaceholder="Describe the changes you want here"
          />
        );
      default:
        return null;
    }
  };

  const { ep, loadingSeconds = 10, validateForm } = Object.values(TABS)[tab];

  const canGenerate = validateForm ? validateForm(formValues[tab]) : true;
  const isGenerating = loading[ep];

  return (
    <div className="generator-wrapper">
      <div className="tabbed-form-wrapper">
        {!hideMenu ? (
          <div className="tabs-container">
            <Tabs
              value={tab}
              orientation="vertical"
              onChange={(event, index) => {
                if (tab !== index) {
                  track("three-d-generator.set-tab", { tab: index });
                }
                setTab(index);
              }}
            >
              {Object.values(TABS).map((tab, index) => (
                <Tab key={index} label={tab.label} />
              ))}
            </Tabs>
          </div>
        ) : (
          <span className="secondary-title">
            {Object.values(TABS)[tab].label}
          </span>
        )}
        {renderSelectedForm()}
      </div>
      {!loadingVideos && !loadingResults && (
        <div className="main-generator-content">
          <div className="buttons">
            <GeneratingButton
              label="Generate"
              id="image-generator.editor.generate"
              className="gradient"
              loading={isGenerating}
              loadProgressSecs={loadingSeconds}
              onClick={onGenerate}
              disabled={!canGenerate}
              onCancel={cancelGenerationWrapper}
              style={{ margin: 0 }}
            />
            {results?.length > 0 && (
              <Tooltip
                title="Clear History"
                arrow
                PopperProps={{
                  className:
                    "MuiTooltip-popper MuiTooltip-popperArrow secondary",
                }}
                placement="top"
              >
                <span>
                  <IconButton
                    onClick={() => {
                      setResults([]);
                      track("three-d-generator.clear-results");
                    }}
                  >
                    <DeleteOutline className="pointer text-blue" />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </div>
          <LoadingTip
            style={{
              marginLeft: "60px",
              marginTop: "30px",
              marginBottom: "30px",
            }}
            section={LOADING_TIPS_SECTIONS.threeDGenerator}
            visible={loadingGenerateImages || results?.length === 0}
            key={loadingGenerateImages}
          />
          <ShowIf condition={results?.length > 0}>
            <ThreeDAssets
              assets={assets}
              onActionsClicked={onActionsClicked}
              onCreateNew3DModel={onCreateNew3DModel}
              onNewVideo={(image, video) =>
                setVideos((prevState) => ({ ...prevState, [image.id]: video }))
              }
              onClick={onClick}
            />
          </ShowIf>
        </div>
      )}
    </div>
  );
};

const GenericForm = ({
  formValues = DEFAULT_OBJECT,
  setFormValues,
  includeImages = false,
  includeHints = false,
  includeExamples = false,
  includeModel = false,
  includeSliders = false,
  includePrompt = false,
  textPlaceholder,
}) => {
  const { call } = useContext(APIContext);
  const [favoritesModal, setFavoritesModal] = useState(false);
  const [editor, setEditor] = useState(false);
  const [processedFiles, setProcessedFiles] = useState([]);
  const [examples, setExamples] = useState(DEFAULT_ARRAY);
  const [examplesKey, setExamplesKey] = useState(1);

  useEffect(() => {
    getExamplesWrapper();
  }, [examplesKey]);

  async function getExamplesWrapper() {
    let data = {
      image_type: "3d",
      filters: {
        n: 1,
      },
    };
    let response = await call(getImagePrompts, { data });
    if (response.ok) {
      setExamples(response.body);
    }
  }

  function onRefresh() {
    setExamplesKey(examplesKey + 1);
  }

  const image = formValues?.image;

  useEffect(() => {
    if (image?.url) {
      fileFromImage(image.image || image, undefined, image).then((result) => {
        setProcessedFiles([result]);
      });
    } else {
      setProcessedFiles([]);
    }
  }, [image]);

  function openFavorites(event) {
    event.stopPropagation();
    event.preventDefault();
    setFavoritesModal(true);
  }

  function removeImage() {
    setFormValues({ image: undefined });
  }

  function chooseImage(image) {
    setFormValues({ image, strength: 50 });
  }

  async function onSetFiles(files) {
    if (files?.length > 0) {
      if (includeImages) {
        chooseImage(files[0]);
      } else if (includeModel) {
        let file = files[0];
        if (file.asset) {
          setFormValues({
            generated_model: file,
          });
        } else {
          let binaryString = await toBase64(file, false);
          setFormValues({
            user_model_file_type: file.name.split(".").pop(),
            user_model: binaryString,
          });
        }
      }
      setFavoritesModal(false);
    }
  }

  const chosenGeneratedModel = formValues?.generated_model;
  const uploadedModelFile = formValues?.user_model;
  const hasModel = chosenGeneratedModel || uploadedModelFile;

  useEffect(() => {
    if (uploadedModelFile) {
      setFormValues({ texture_strength: 0.0 });
    } else if (chosenGeneratedModel) {
      setFormValues({ texture_strength: 0.5, mesh_strength: 0.5 });
    }
  }, [chosenGeneratedModel, uploadedModelFile]);

  const [modal, setModal] = useState(false);
  function onClickedHelp() {
    setModal(true);
  }

  const sliders = uploadedModelFile
    ? ["mesh_strength"]
    : ["mesh_strength", "texture_strength"];

  return (
    <div className="form">
      {modal && (
        <DocumentationModal
          modal={modal}
          setModal={setModal}
          key={modal}
          defaultTitle="How to Upload 3D Models"
        />
      )}
      {includeImages && (
        <div className="file-wrapper-grid">
          <div className="file-wrapper">
            {processedFiles.length > 0 && (
              <ThumbnailPreview
                files={processedFiles}
                removeFile={removeImage}
              />
            )}
            {!image && (
              <div className="row">
                <MyButton
                  onClick={openFavorites}
                  className="initial-image-button"
                >
                  <span>Choose reference image</span>
                  <Hint hint="Choose an asset image to generate a 3D model from" />
                </MyButton>
              </div>
            )}
          </div>
          {image && (
            <div className="slider-wrapper mt-2">
              <MyButton
                className="text-gradient"
                style={{ width: "unset", margin: 0 }}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setEditor(true);
                }}
              >
                <CropFreeOutlined className="mr-2" />
                Open In Editor
              </MyButton>
            </div>
          )}
        </div>
      )}
      {includeModel && (
        <div className="file-wrapper-grid">
          <div className="file-wrapper three-d-wrapper">
            {!hasModel && (
              <div className="flex-column">
                <div className="row">
                  <MyButton
                    onClick={openFavorites}
                    className="initial-image-button"
                  >
                    <span>Choose reference model</span>{" "}
                    <Hint hint="Choose an existing 3D model" />
                  </MyButton>
                </div>
                <div className="help-link-container mt-2 text-align-center">
                  <Tooltip title="Learn how to prepare and upload 3D models">
                    <span
                      onClick={onClickedHelp}
                      className="link cursor-pointer underline d-flex align-items-center justify-content-center"
                    >
                      <HelpOutlineOutlined
                        className="mr-1"
                        style={{ fontSize: "20px" }}
                      />
                      How to Upload 3D Models
                    </span>
                  </Tooltip>
                </div>
              </div>
            )}
            <div className="model-wrapper">
              {chosenGeneratedModel && (
                <ThreeDAsset
                  hideActions={true}
                  hideViewToggle={true}
                  onClick={() => {}}
                  threeDAsset={chosenGeneratedModel}
                  onNewVideo={() => {}}
                  forceMode="threeD"
                />
              )}
              {uploadedModelFile && (
                <div className="dummy-image three-d-model-placeholder">
                  <ViewInArOutlined className="secondary" />
                  <span className="model-text">Uploaded 3D Model</span>
                </div>
              )}
              {hasModel && (
                <IconButton
                  className="delete-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    setFormValues({
                      generated_model: undefined,
                      user_model_file_type: undefined,
                      user_model: undefined,
                    });
                  }}
                >
                  <DeleteOutline />
                </IconButton>
              )}
            </div>
            {hasModel && includeSliders && (
              <div className="sliders">
                {sliders.map((sliderField) => (
                  <>
                    <Typography
                      id="discrete-slider-restrict"
                      gutterBottom
                      className="slider-title"
                    >
                      <span className="inner-span">
                        {sliderField.replace("_", " ")}
                      </span>
                      <Hint
                        iconClassName="pt-0"
                        hint={SLIDER_HINTS[sliderField]}
                      />
                    </Typography>
                    <Slider
                      className="slider-primary"
                      value={
                        formValues[sliderField] === undefined
                          ? 50
                          : parseInt(formValues[sliderField] * 100)
                      }
                      step={5}
                      valueLabelDisplay="on"
                      min={0}
                      onChange={(event, value) => {
                        setFormValues({ [sliderField]: value / 100.0 });
                      }}
                      max={100}
                      marks={[
                        {
                          value: 1,
                          label: "0",
                        },
                        {
                          value: 100,
                          label: "100",
                        },
                      ]}
                    />
                  </>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
      {includeHints && (
        <div className="input-fields-wrapper">
          <textarea
            value={formValues?.hints?.[0] || ""}
            onChange={(e) => {
              setFormValues({ hints: [e.target.value] });
            }}
            placeholder={textPlaceholder}
            rows={3}
          />
        </div>
      )}
      {includePrompt && (
        <div className="input-fields-wrapper">
          <textarea
            value={formValues?.prompt || ""}
            onChange={(e) => {
              setFormValues({ prompt: e.target.value });
            }}
            placeholder={textPlaceholder}
            rows={3}
          />
        </div>
      )}
      {includeExamples && (
        <div className="examples-wrapper">
          <Examples
            examples={examples}
            number={1}
            mainIcon={false}
            onRefresh={onRefresh}
            showHeader={true}
            onClickedExample={(value) => {
              if (value?.keyword) setFormValues({ hints: [value.keyword] });
            }}
          />
        </div>
      )}
      {favoritesModal && (
        <FavoriteImagesModal
          open={favoritesModal}
          convertFileToImage={includeImages}
          accept={
            includeModel
              ? {
                  "*/*": [
                    ".glb",
                    ".ply",
                    ".stl",
                    ".off",
                    ".gltf",
                    ".obj",
                    ".fbx",
                    ".3ds",
                    ".dae",
                    ".ctm",
                    ".x3d",
                  ],
                }
              : undefined
          }
          title={includeModel ? "Upload a 3D Model" : undefined}
          favoriteType={includeModel ? FAVORITE_TYPES.three_d_asset : undefined}
          info={
            includeModel ? (
              <span className="mt-3 text-align-center">
                The file should contain only the subject.
                <br />
                It must be a file in one of these formats:{" "}
                <i>
                  obj, ply, stl, off, gltf, glb, fbx, 3ds, dae, ctm, x3d (max 20
                  MB)
                </i>
              </span>
            ) : undefined
          }
          onClose={() => setFavoritesModal(false)}
          setFiles={onSetFiles}
          setProcessedFiles={(files) => {
            if (files?.length > 0) {
              setProcessedFiles(files);
            }
          }}
        />
      )}
      {editor && (
        <ImageEditor
          url={image?.url}
          initialImage={image}
          onClose={() => setEditor(false)}
          formValues={{}}
          onResults={async (results) => {
            if (results.length > 0) {
              let resultImage = results[0];
              if (resultImage.id !== formValues.image?.id) {
                chooseImage(results[0]);
              }
            }
            setEditor(false);
          }}
        />
      )}
    </div>
  );
};

export const ThreeDAssets = ({
  assets,
  onNewVideo,
  onCreateNew3DModel,
  onClick,
  hideActions,
  onActionsClicked,
}) => {
  return (
    <div className="d-flex w-100 justify-content-center p-4 three-d-assets">
      {assets.map((asset) => (
        <ThreeDAsset
          key={asset.image.id}
          threeDAsset={asset}
          onNewVideo={onNewVideo}
          onCreateNew3DModel={onCreateNew3DModel}
          onClick={onClick}
          hideActions={hideActions}
          onActionsClicked={onActionsClicked}
        />
      ))}
    </div>
  );
};

const MODES = {
  image: "image",
  threeD: "threeD",
};

export const ThreeDAsset = ({
  threeDAsset,
  onNewVideo,
  onCreateNew3DModel,
  onClick,
  hideActions,
  hideViewToggle,
  hideThumbnail,
  forceMode,
  onActionsClicked,
  dontExpand,
}) => {
  const { call } = useContext(APIContext);
  const { track } = useContext(SocketContext);
  const { removeFavoriteFromCache, addFavoriteToCache, cache } =
    useContext(CacheContext);

  const history = useHistory();

  const { allFavorites = DEFAULT_ARRAY, selectedProjectId } = cache;

  const [anchorEl, setAnchorEl] = useState(null);
  const [loading3D, setLoading3D] = useState(false);
  const [togglingFavorite, setTogglingFavorite] = useState(false);
  const [showDownloadPopup, setShowDownloadPopup] = useState(false);
  const [downloading3D, setDownloading3D] = useState(false);
  const [mesh, setMesh] = useState(false);
  const [editor, setEditor] = useState(false);
  const [mode, setMode] = useState(asset ? MODES.threeD : MODES.image);

  const [asset, setAsset] = useState(threeDAsset.asset);
  const [image] = useState(prepareGeneratedImage(threeDAsset.image));

  function onEditImage() {
    track("three-d-asset.edit-image", { image });
    const data = { formValues: { image }, tab: 1 };
    history.push("/3d-generator", { data });
  }

  useEffect(() => {
    setAsset(threeDAsset.asset);
    if (threeDAsset.asset?.video_b64) {
      setMode(MODES.threeD);
    }
  }, [threeDAsset.asset]);

  const chosenVideo = mesh ? asset?.mesh_video_b64 : asset?.video_b64;

  const [video, setVideo] = useState();
  useEffect(() => {
    if (chosenVideo) {
      fetch(chosenVideo)
        .then((response) => response.blob())
        .then(async (blob) => {
          if (!blob.type) blob = new Blob([blob], { type: "audio/mpeg" });
          setVideo({ url: URL.createObjectURL(blob) });
        });
    }
  }, [chosenVideo]);

  const payloadId = getAssetPayloadId(threeDAsset);

  const favoriteMatch = useMemo(() => {
    return allFavorites.find(
      (favorite) => favorite.payload_id && favorite.payload_id === payloadId
    );
  }, [allFavorites, payloadId]);

  function onCancelVideo(id) {
    call(cancelGeneration, { generationId: id }, { hideErrorMessage: true });
  }

  const onGenerate3DModel = async (
    image,
    render_mesh = true,
    render_video = true,
    render_snapshots
  ) => {
    track("three-d-asset.generate-3d-model", { image , render_mesh, render_video, render_snapshots  });
    setLoading3D(true);
    let response = await call(imageTo3D, {
      image,
      render_mesh,
      render_video,
      render_snapshots,
    });
    if (response.ok && response.body) {
      if (chosenVideo && onCreateNew3DModel) {
        onCreateNew3DModel(image, response.body);
      } else {
        if (onNewVideo) {
          onNewVideo(image, response.body);
        }
        setMode(MODES.threeD);
        if (!!favoriteMatch) {
          await removeFavoriteWrapper();
          await addFavorite(image, response.body);
        }
      }
    }
    setLoading3D(false);
  };

  const selectedMode = forceMode || mode;

  let className = "three-d-asset " + selectedMode;

  if (mesh) className += " mesh";

  const imageElement = onClick => (
    <MyImage
      key={image.id || image.url}
      image={image}
      hideActions={true}
      dontExpand={dontExpand}
      onImageClickFunc={onClick ? () => {
        onClick(threeDAsset)
      } : undefined}
    />
  );

  async function toggleFavorite() {
    setTogglingFavorite(true);

    if (!!favoriteMatch) {
      track("three-d-asset.remove-favorite", { image, asset });
      await removeFavoriteWrapper();
    } else {
      track("three-d-asset.add-favorite", { image, asset });
      await addFavorite();
    }

    setTogglingFavorite(false);
  }

  async function removeFavoriteWrapper() {
    let toRemove = { ...favoriteMatch };
    let response = await call(removeFavorite, { id: favoriteMatch._id });
    if (response.ok) {
      removeFavoriteFromCache(toRemove);
    }
  }

  async function addFavorite(
    image = threeDAsset.image,
    asset = threeDAsset.asset
  ) {
    let response = await call(addFavorite3d, {
      data: { image, asset },
      projectId: selectedProjectId,
    });
    if (response.ok) {
      addFavoriteToCache(response.body);
    }
  }

  return (
    <div className={className}>
      <div className="controls">
        {!hideViewToggle && asset?.mesh_video_b64 && (
          <Tooltip
            PopperProps={{
              className: "MuiTooltip-popper MuiTooltip-popperArrow secondary",
            }}
            title={mesh ? "Switch to Standard View" : "Switch to Mesh View"}
            arrow
            placement="top"
          >
            <div className="mesh-toggle">
              <Switch
                checked={mesh}
                onChange={() => {
                  track("three-d-asset.change-3d-mode", { mesh: !mesh });
                  setMesh(!mesh);
                }}
                color="primary"
                icon={<ViewInArOutlined />}
                checkedIcon={<ThreeDRotationOutlined />}
              />
            </div>
          </Tooltip>
        )}
        {!hideActions && (
          <>
            {chosenVideo && (
              <Tooltip
                title="Download 3D Object"
                arrow
                PopperProps={{
                  className:
                    "MuiTooltip-popper MuiTooltip-popperArrow secondary",
                }}
                placement="top"
              >
                <IconButton
                  disabled={downloading3D}
                  onClick={() => setShowDownloadPopup(true)}
                  className="download-button button"
                >
                  {downloading3D ? (
                    <CircularProgress size={20} />
                  ) : (
                    <DownloadOutlined />
                  )}
                </IconButton>
              </Tooltip>
            )}
            <Tooltip
              title={
                !!favoriteMatch ? "Remove from Favorites" : "Add to Favorites"
              }
              arrow
              PopperProps={{
                className: "MuiTooltip-popper MuiTooltip-popperArrow secondary",
              }}
              placement="top"
            >
              <IconButton
                disabled={downloading3D}
                onClick={toggleFavorite}
                className="favorite-button button"
              >
                {togglingFavorite ? (
                  <CircularProgress size={20} />
                ) : !!favoriteMatch ? (
                  <FavoriteOutlined />
                ) : (
                  <FavoriteBorderOutlined />
                )}
              </IconButton>
            </Tooltip>
            <ContextMenu
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              hasVideo={chosenVideo}
              loading3D={loading3D}
              onGenerate3DModel={() =>
                onGenerate3DModel(image, true, true, false)
              }
              onEditImage={() => {
                track("three-d-asset.edit-base-image", { image, asset });
                const data = { formValues: { image }, tab: 1 };
                if (onActionsClicked) {
                  onActionsClicked(data);
                  setAnchorEl(null);
                } else {
                  history.push("/3d-generator", {
                    data,
                  });
                }
              }}
              onReTexture={() => {
                track("three-d-asset.re-texture", { image, asset });
                const data = {
                  formValues: { generated_model: threeDAsset },
                  tab: 2,
                };
                if (onActionsClicked) {
                  onActionsClicked(data);
                  setAnchorEl(null);
                } else {
                  history.push("/3d-generator", {
                    data,
                  });
                }
              }}
              onGenerateVariations={() => {
                track("three-d-asset.generate-variations", { image, asset });
                const data = {
                  formValues: { generated_model: threeDAsset },
                  tab: 3,
                };
                if (onActionsClicked) {
                  onActionsClicked(data);
                  setAnchorEl(null);
                } else {
                  history.push("/3d-generator", {
                    data,
                  });
                }
              }}
            />
          </>
        )}
      </div>
      {selectedMode === MODES.image && (
        <>
          {asset && (
            <div
              className="video-thumbnail"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setMode(MODES.threeD);
              }}
            >
              <video
                src={video?.url}
                controls={false}
                autoPlay={true}
                playsInline={true}
                muted={true}
                loop={true}
              />
            </div>
          )}
          {imageElement(onClick)}
          {!chosenVideo && (
            <GeneratingButton
              id="3d-asset.generate"
              className="gradient"
              loading={loading3D}
              onCancel={() => {
                onCancelVideo(image.id);
              }}
              onClick={() => onGenerate3DModel(image, true, true, false)}
              loadProgressSecs={5}
              style={{ margin: 0 }}
              trackOptions={{
                image,
              }}
            >
              Generate 3D Object
            </GeneratingButton>
          )}
        </>
      )}
      {selectedMode === MODES.threeD && (
        <>
        {!hideThumbnail && <div className="image-thumbnail" onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setMode(MODES.image);
          }}>
            <div
              style={{ display: "contents" }}
            >
              {imageElement()}
            </div>
          </div>}
          <div className="image-placeholder">{imageElement()}</div>
          {chosenVideo ? (
            <video
              src={video?.url}
              controls={false}
              autoPlay={true}
              playsInline={true}
              muted={true}
              loop={true}
              onClick={onClick ? () => {
                onClick(threeDAsset)
              } : undefined}
            />
          ) : null}
        </>
      )}
      <DownloadPopup
        open={showDownloadPopup}
        onClose={() => setShowDownloadPopup(false)}
        onDownload={async (format) => {
          track("three-d-asset.download-3d", { format });
          setDownloading3D(true);
          await onDownload3DModel(format, threeDAsset, call);
          setDownloading3D(false);
        }}
      />
      {editor && (
        <ImageEditor
          url={image.url}
          initialImage={image}
          onClose={() => setEditor(false)}
          formValues={{}}
          onResults={async (results) => {
            if (results.length > 0) {
              let resultImage = results[0];
              if (resultImage.id !== image.id) {
                onEditImage(resultImage);
              }
            }
            setEditor(false);
          }}
        />
      )}
    </div>
  );
};

const ContextMenu = ({
  hasVideo,
  loading3D,
  onGenerate3DModel,
  onEditImage,
  onReTexture,
  onGenerateVariations,
  anchorEl,
  setAnchorEl,
}) => {
  return (
    <>
      <Tooltip
        title="Options"
        arrow
        PopperProps={{
          className: "MuiTooltip-popper MuiTooltip-popperArrow secondary",
        }}
        placement="top"
      >
        <IconButton
          aria-controls="3d-options-menu"
          aria-haspopup="true"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          className="options-button button"
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="3d-options-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            onEditImage();
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <ModeEditOutline fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit base image" />
        </MenuItem>
        {hasVideo && (
          <MenuItem onClick={() => onGenerate3DModel()} disabled={loading3D}>
            <ListItemIcon>
              {loading3D ? (
                <CircularProgress size={20} />
              ) : (
                <AutorenewOutlined fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText primary="Retry 3D model generation" />
          </MenuItem>
        )}
        {hasVideo && (
          <MenuItem onClick={() => onReTexture()}>
            <ListItemIcon>
              <BrushOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Re-texture" />
          </MenuItem>
        )}
        {hasVideo && (
          <MenuItem onClick={() => onGenerateVariations()}>
            <ListItemIcon>
              <FileCopyOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Generate variations" />
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export const DownloadPopup = ({ open, onClose, onDownload }) => {
  const [format, setFormat] = useState("glb");

  const handleDownload = () => {
    onDownload(format);
    onClose();
  };

  return (
    <GDDModal open={open} onClose={onClose} title="Download 3D Object">
      <DialogContent>
        <FormControl className="w-100">
          <InputLabel>Format</InputLabel>
          <Select
            value={format}
            onChange={(e) => setFormat(e.target.value)}
            fullWidth
            margin="dense"
          >
            <MenuItem key="glb" value="glb">
              <ListItemText primary="GLB" secondary="Includes textures" />
            </MenuItem>
            {["stl", "ply", "obj", "off"].map((f) => (
              <MenuItem key={f} value={f}>
                <ListItemText primary={f.toUpperCase()} secondary="No textures" />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <MyButton onClick={handleDownload} color="primary">
          Download
        </MyButton>
      </DialogActions>
    </GDDModal>
  );
};

export function prepareGeneratedImage(image) {
  if (!image) return;
  const isEmbedded = !!image.image;
  let url = image.url || `data:image/png;base64, ${image.image}`;
  image = { ...image, url, embedded: isEmbedded };
  return {
    ...image,
    src: url,
    originalImage: image,
  };
}

async function fileFromImage(image, submit_field, rawData) {
  const urlToObject = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], "image.jpg", { type: blob.type });
  };

  let proxyUrl = convertProxyUrl(image, true);
  let file = await urlToObject(proxyUrl);
  file.preview = URL.createObjectURL(file);
  file.image = image;
  file.mask = rawData?.mask;
  file.cropped = rawData?.cropped;
  const isGameImage = image?.game_id;
  file.submit_field =
    submit_field ||
    (isGameImage ? "initial_game_image" : "initial_generated_image");
  return file;
}

export default ThreeDGenerator;

export function getAssetPayloadId(threeDAsset) {
  return threeDAsset.image.id + threeDAsset.asset?.id;
}

export async function onDownload3DModel(format, asset, call) {
  let data = {
    request_id: PerformanceUtils.generateId(),
    format,
    asset_generation: asset.asset,
  };
  let response = await call(export3D, { data });
  if (response?.ok && response.body) {
    let base64 = response.body.data;
    let binaryString = atob(base64);
    let len = binaryString.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    let blob = new Blob([bytes], { type: response.body.type });
    let link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    const hints = Array.isArray(asset.image.hints) ? asset.image.hints : [];
    const hintString = hints.join(" ").replace(/\W+/g, "-").slice(0, 50);
    let name = hintString || "model";
    link.download = `${name}.${format}`;
    link.click();
  }
}
