import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SocketContext from "context/SocketContext";
import PageTitle from "components/layout-components/PageTitle";
import ShowIf from "components/common/ShowIf";
import CacheContext from "context/CacheContext";
import {
  addNewProject,
  FAIcon,
  getNumberAllowedProjects,
  isProjectEditable,
} from "pages/GDD3/Helpers";
import { PDFExport } from "@progress/kendo-react-pdf";
import "./style.scss";
import _ from "lodash";
import GDDSideMenu from "pages/GDD3/GDDSideMenu";
import GDDGameSummary from "pages/GDD3/GDDGameSummary";
import PerformanceUtils from "helpers/PerformanceUtils";
import GDDGameElement, {
  GAME_ELEMENT_LAYOUTS,
} from "pages/GDD3/GDDGameElement";
import GDDText from "pages/GDD3/GDDText";
import GDDMoodboard from "pages/GDD3/GDDMoodboard";
import GDDGames from "pages/GDD3/GDDGames";
import { Provider } from "react-bus";
import GDDContext from "context/GDDContext";
import PDFTemplate from "pages/GDD3/PDFTemplate";
import CommentsSection from "pages/GDD3/GDDSideMenu/CommentsMenu";
import { sendToSlack, showInfoMessage } from "components/Sharing/ShareToSlack";
import AuthContext from "context/AuthContext";
import { useSnackbar } from "notistack";
import { drawDOM } from "@progress/kendo-drawing";
import { useHistory, useLocation, useParams } from "react-router";
import GDDSectionActions from "pages/GDD3/GDDSectionActions";
import DismissableMessage from "components/common/DismissableMessage";
import {
  AddCircleOutlineOutlined,
  AutoAwesomeOutlined,
  CloseOutlined,
  WarningOutlined,
} from "@mui/icons-material";
import APIContext from "context/APIContext";
import useGDDState, { GDD_TAB_NAMES } from "hooks/useGDDState";
import { CircularProgress, IconButton, Tooltip } from "@material-ui/core";
import GDDLudoScore from "pages/GDD3/GDDLudoScore";
import GDD4Menu, { ConceptsSection } from "./GDD4Menu";
import MyButton from "../../components/Controls/MyButton";
import TableOfContents from "./TableOfContents";
import ExportProject from "./ExportProject";
import { STATUS } from "../../useSocket";
import GameGuide from "./GameGuide";
import CompetitiveAnalysis from "../CompetitiveAnalysis";
import GDDAssets from "./GDDAssets";
import featureConceptImage from "../../assets/images/misc/feature-concept.png";
import featureStepImage from "../../assets/images/misc/feature-step.png";
import featureNewConceptImage from "../../assets/images/misc/feature-new-concept.png";
import featureIdeatorImage from "../../assets/images/misc/feature-ideator.png";
import feature3dImage from "../../assets/images/misc/feature-3d.png";
import { ImportConcept } from "../../scenes/Headquarters2";

const DEFAULT_ARRAY = [];
const generateGameConceptHeaderImagesNew = "generateGameConceptHeaderImagesNew";
const cancelGameConceptHeaderImages = "cancelGameConceptHeaderImages";
const suggestSectionsToAdd = "suggestSectionsToAdd";
const updateUserInfo = "updateUserInfo";
const getProject = "getProject";

const DEFAULT_GDD = {
  genres: undefined,
  sections: [
    {
      id: PerformanceUtils.generateId(),
      name: "summary",
      value: { title: "", text: "" },
    },
  ],
};

const GDD4 = () => {
  const history = useHistory();
  const { auth } = useContext(AuthContext);
  const { call } = useContext(APIContext);
  const { cache, setCacheValue, setCache } = useContext(CacheContext);
  const { reset, setLocked } = useContext(GDDContext);
  const { selectedProjectId, projects = DEFAULT_ARRAY } = cache;

  const [loadingProjects, setLoadingProjects] = useState(true);

  const params = useParams();

  const projectId = params.projectId || selectedProjectId;

  useEffect(() => {
    if (projectId) {
      call(getProject, { projectId }).then((response) => {
        if (response.ok) {
          let projectData = response.body;
          setCache((prevState) => {
            return {
              ...prevState,
              projects: PerformanceUtils.editOrAdd(
                projectData,
                prevState.projects,
                "_id"
              ),
            };
          });
        } else {
          setCacheValue("selectedProjectId", null);
          setCache((prevState) => {
            return {
              ...prevState,
              selectedProjectId: null,
              projects: PerformanceUtils.removeFromArray(
                { _id: projectId },
                prevState.projects,
                "_id"
              ),
            };
          });
          history.push("/");
          window.location.reload();
        }
        setLoadingProjects(false);
      });
    }
  }, []);

  useEffect(() => {
    if (selectedProjectId !== projectId) {
      setCacheValue("selectedProjectId", projectId);
      call(updateUserInfo, { data: { selected_project: projectId } });
    }
  }, [call, selectedProjectId, projectId]);

  useEffect(() => {
    if (projectId) {
      setCacheValue("selectedProjectId", projectId);
    }
  }, [projectId, selectedProjectId]);

  useEffect(() => {
    reset();
  }, [projectId]);

  const projectIndex = useMemo(() => {
    return projects.findIndex(({ _id }) => _id === selectedProjectId);
  }, [selectedProjectId, projects]);

  const numberAllowedProjects = useMemo(() => {
    return getNumberAllowedProjects(auth);
  }, [auth]);

  const locked = projectIndex >= numberAllowedProjects;

  useEffect(() => {
    setLocked(locked);
  }, [locked]);

  return projectId ? (
    loadingProjects ? (
      <div className="top-overlay">
        <CircularProgress />
        <span>Loading your concept...</span>
      </div>
    ) : (
      <GDD4Content key={projectId} projectId={projectId} />
    )
  ) : null;
};

const GDD4Content = ({ projectId }) => {
  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { call, loading } = useContext(APIContext);
  const { cache, setCache } = useContext(CacheContext);
  const { track, emit, status } = useContext(SocketContext);
  const { auth } = useContext(AuthContext);
  const {
    menu,
    openMenu,
    closeMenu,
    suggestions,
    setSuggestions,
    tab,
    setTab,
  } = useContext(GDDContext);
  const [generatedIcon, setGeneratedIcon] = useState();
  const [generatedHeader, setGeneratedHeader] = useState();
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);

  const editorRef = useRef();
  const pdfRef = useRef();
  const wrapperRef = useRef(null);

  const sectionSuggestions = loadingSuggestions ? [] : suggestions;

  const {
    projects = DEFAULT_ARRAY,
    detailsPanel,
    generationTypes = DEFAULT_ARRAY,
    gddComponents,
  } = cache;
  const project = useMemo(() => {
    return projects.find((project) => project._id === projectId) || {};
  }, [projectId, projects]);

  const [savingPDF, setSavingPDF] = useState(false);
  const [exportPDF, setExportPDF] = useState(false);
  const [activeComponent, setActiveComponent] = useState();
  const [showConcepts, setShowConcepts] = useState(false);
  const [forceEditEmpty, setForceEditEmpty] = useState(undefined);

  const sidePanelOpen = !!menu || !!detailsPanel.mode;

  const debouncedSendGDDSocket = useRef(_.debounce(sendGDDSocket, 300)).current;
  const debouncedSendGDDSectionSocket = useRef(
    _.debounce(sendGDDSectionSocket, 300)
  ).current;

  useEffect(() => {
    if (!project?._id) {
      history.push("/game-concept");
    }
  }, [history, project]);

  const editorHeight = useMemo(() => {
    return editorRef?.current?.offsetHeight;
  }, [editorRef, savingPDF, exportPDF]);

  const gdd = project.gdd2 || {
    ...DEFAULT_GDD,
    genres: auth.user.genres || DEFAULT_ARRAY,
  };

  useEffect(() => {
    setLoadingSuggestions(true);
    setTimeout(() => {
      call(suggestSectionsToAdd, {
        id: projectId,
      }).then((response) => {
        if (response.ok) {
          let suggestions = response.body.slice(0, 5).map((name) => {
            return gddComponents[name];
          });
          setSuggestions(suggestions);
          setLoadingSuggestions(false);
        }
      });
    }, 300);
  }, [gdd.sections.length, projectId]);

  useEffect(() => {
    return () => {
      closeMenu();
    };
  }, []);

  useEffect(() => {
    if (location.state?.data) {
      let data = location.state.data;

      if (data.generateElements) {
        generateHeaderImages(data.extract);
      }

      if(data.forceEditEmpty !== undefined) {
        setForceEditEmpty(data.forceEditEmpty);
      }

      history.replace({ ...history.location, state: {} });
    }
  }, [location, gdd, project]);

  useEffect(() => {
    if (generatedIcon) {
      setGeneratedIcon();
      changeAllGdd({ ...gdd, icon: generatedIcon });
    }
  }, [generatedIcon, gdd]);

  useEffect(() => {
    if (generatedHeader) {
      setGeneratedHeader();
      changeAllGdd({ ...gdd, header: generatedHeader });
    }
  }, [generatedHeader, gdd]);

  useEffect(() => {
    if (menu && project.platform !== gdd.platform) {
      changeAllGdd({ ...gdd, platform: project.platform });
    }
  }, [project, gdd, menu]);

  async function generateHeaderImages(extract) {
    await call(generateGameConceptHeaderImagesNew, { projectId, extract });
  }

  async function stopGeneration() {
    await call(cancelGameConceptHeaderImages, { projectId });
  }

  function sendGDDSocket(gdd, teamId, id) {
    emit("gdd-update", { gdd, teamId, id });
  }

  function sendGDDSectionSocket(key, section, teamId, id) {
    emit("gdd-update-section", { key, section, teamId, id });
  }

  useEffect(() => {
    if (gdd?.sections?.length === 0) {
      changeGdd("sections", DEFAULT_GDD.sections);
    } else if (!gdd.sections.find(({ name }) => name === "summary")) {
      changeGdd("sections", [{ name: "summary" }, ...gdd.sections]);
    }
  }, [gdd]);

  function changeAllGdd(updatedGdd) {
    project.platform = project.platform || "Mobile";
    if (!updatedGdd.platform && project.platform)
      updatedGdd.platform = project.platform;
    debouncedSendGDDSocket(updatedGdd, project.team_id, project._id);
    updateGDDCache(updatedGdd);
  }

  const changeGdd = useCallback(
    (key, value, isSection = false, otherData) => {
      let updatedGdd;
      if (isSection) {
        updatedGdd = {
          ...gdd,
          ...(otherData || {}),
          sections: (gdd.sections || []).map((section) => {
            if (section.id === key || section.name === key)
              section.value = value;
            return section;
          }),
        };
        if (otherData)
          debouncedSendGDDSocket(updatedGdd, project.team_id, project._id);
        else {
          let section = updatedGdd.sections.find(
            (section) => section.id === key || section.name === key
          );
          debouncedSendGDDSectionSocket(
            key,
            section,
            project.team_id,
            project._id
          );
        }
      } else {
        updatedGdd = {
          ...gdd,
          [key]: value,
        };
        if (!updatedGdd.platform && project.platform)
          updatedGdd.platform = project.platform;
        debouncedSendGDDSocket(updatedGdd, project.team_id, project._id);
      }
      updateGDDCache(updatedGdd);
    },
    [gdd, project, projectId]
  );

  function updateGDDCache(updatedGdd) {
    let updatedProject = { ...project, gdd2: updatedGdd };
    setCache((prevState) => {
      return {
        ...prevState,
        projects: PerformanceUtils.editOrAdd(
          updatedProject,
          prevState.projects,
          "_id"
        ),
      };
    });
  }

  function deleteSection(key) {
    track("gdd.section.delete-section", { section: key });
    let sections = PerformanceUtils.removeFromArray(
      { name: key },
      gdd.sections,
      "name"
    );
    sections = PerformanceUtils.removeFromArray({ id: key }, sections, "id");
    changeGdd("sections", sections);
    setActiveComponent();
  }

  function changeOrder(key, up = true) {
    track("gdd.section.re-order", {
      section: key,
      direction: up ? "up" : "down",
    });
    let index = _.findIndex(gdd.sections, ["name", key]);
    if (index < 0) index = _.findIndex(gdd.sections, ["id", key]);
    if (index >= 0) {
      let targetIndex = index + (up ? -1 : 1);
      targetIndex = Math.min(
        Math.max(targetIndex, 1),
        (gdd.sections || []).length - 1
      );
      if (targetIndex !== index) {
        let sections = PerformanceUtils.arrayMove(
          gdd.sections,
          index,
          targetIndex
        );
        changeGdd("sections", sections);
      }
    }
  }

  const numberAllowedProjects = useMemo(() => {
    return getNumberAllowedProjects(auth);
  }, [auth]);

  const editable = useMemo(() => {
    return isProjectEditable(projects, projectId, numberAllowedProjects);
  }, [numberAllowedProjects, projects, projectId]);

  useEffect(() => {
    if (!editable) setTab(GDD_TAB_NAMES.review);
  }, [editable]);

  useEffect(() => {
    track("gdd.tab-selected", { tab });
  }, [tab]);

  function clickedAdd(section) {
    track("gdd.clicked-add");
    changeActiveComponent();
    openMenu(section);
  }

  function addNewComponent(component, afterSection = "summary") {
    setSuggestions((prevState) =>
      prevState.filter((item) => item.section !== component.section)
    );

    let newSection = {
      name: component.section,
      id: component?.id || PerformanceUtils.generateId(),
      value: {},
    };
    let sections = gdd?.sections || [];

    if (component.gameElements) {
      newSection.value = {};
      newSection.value.layout = generationTypes.includes(component.section)
        ? GAME_ELEMENT_LAYOUTS.images
        : GAME_ELEMENT_LAYOUTS.text;
    }

    if (afterSection) {
      let index = _.findIndex(gdd.sections, ["name", afterSection]);
      if (index < 0) index = _.findIndex(gdd.sections, ["id", afterSection]);
      index = Math.max(0, index);
      let newSections = [];

      for (let i = 0; i <= index; i++) newSections.push(sections[i]);

      newSections.push(newSection);

      for (let i = index + 1; i < sections.length; i++)
        newSections.push(sections[i]);

      sections = newSections;
    } else {
      sections = [...sections, newSection];
    }

    changeGdd("sections", sections);
    changeActiveComponent({ ...component, id: newSection.id });
    closeMenu();
  }

  function changeActiveComponent(component) {
    setActiveComponent(component);
  }

  function downloadPDF() {
    let originalTab = tab;
    setTab(GDD_TAB_NAMES.review);
    track("gdd.download-pdf");
    setSavingPDF(true);
    setTimeout(() => {
      setExportPDF(true);
      pdfRef?.current.save(() => {
        setSavingPDF(false);
        setExportPDF(false);
        setTab(originalTab);
      });
    }, 100);
  }

  const title = (gdd?.sections || [])[0]?.value?.title;
  let inchHeight = Math.max(editorHeight * 0.0104166667, 11.7);

  const PDF_OPTIONS = {
    scale: 0.6,
    paperSize: ["27cm", `${inchHeight}in`],
    fileName: `${title || project.name} GDD.pdf`,
    margin: "1cm",
  };

  async function exportPDFBase64() {
    setSavingPDF(true);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        let gridElement = document.querySelector(".gdd .editor");
        drawDOM(gridElement, PDF_OPTIONS)
          .then((group) => exportPDF(group))
          .then((dataUri) => {
            return resolve(dataUri.split(";base64,")[1]);
          })
          .catch((err) => reject(err))
          .finally(() => {
            setSavingPDF(false);
          });
      }, 100);
    });
  }

  async function shareToSlack() {
    let gdd_pdf = await exportPDFBase64();
    let data = { gdd, gdd_pdf, project: project._id };
    let team = (auth.user.teams || [])[0];
    if (!team)
      return showInfoMessage(
        "Sharing can only be used if you are a member of a team",
        enqueueSnackbar,
        closeSnackbar
      );
    await sendToSlack(call, loading, { data, project: project._id, team });
  }

  const hasHeader = !!gdd.header?.url;

  let editorClassName = "editor";
  if (savingPDF) editorClassName += " k-pdf-export";

  let gddPageClassName = "w-100 gdd-page";
  if (sidePanelOpen) gddPageClassName += " panel-open";
  if (hasHeader) gddPageClassName += " has-header-image";

  let gddContentClassName = "gdd-content";
  if (tab === GDD_TAB_NAMES.review || savingPDF)
    gddContentClassName += " gdd-preview";
  if (tab === GDD_TAB_NAMES.unityCode) gddContentClassName += " export-project";
  if (tab === GDD_TAB_NAMES.developmentGuide)
    gddContentClassName += " game-guide";
  if (tab === GDD_TAB_NAMES.analysis) gddContentClassName += " analysis";

  if (!project?._id) return null;

  const summarySection = gdd.sections[0];

  let addHere =
    menu?.section === summarySection.id &&
    menu?.component === gddComponents.index;
  let onlyOneSection = gdd.sections.length === 1;

  let iconsWrapperClassName = "icons-wrapper";
  if (addHere) iconsWrapperClassName += " active";
  if (onlyOneSection) iconsWrapperClassName += " hover";

  const socketOK = status === STATUS.connected;

  const isEmpty =
    gdd.sections.length === 1 &&
    !gdd.sections[0].value.text &&
    gdd.sections[0].value.title?.includes("Untitled");

  const showEmptyState =
    forceEditEmpty !== false && (isEmpty || forceEditEmpty === true);

  return (
    <Provider>
      <div className="gdd-page-wrapper">
        <div className={gddPageClassName}>
          <PageTitle
            titleHeading="Game Concept"
            titleDescription="Let Ludo help turn your game idea into a complete Concept Document"
          />
          {!socketOK && (
            <div className="top-overlay">
              <WarningOutlined />
              <span>
                You are not currently connected to Ludo. Please check your
                internet connection.
              </span>
            </div>
          )}
          <ShowIf condition={!!projectId}>
            <PDFExport
              paperSize={PDF_OPTIONS.paperSize}
              fileName={PDF_OPTIONS.fileName}
              scale={PDF_OPTIONS.scale}
              margin={PDF_OPTIONS.margin}
              title=""
              subject=""
              keywords=""
              creator="Ludo AI"
              pageTemplate={PDFTemplate}
              ref={pdfRef}
            >
              <div className="gdd">
                <div className={gddContentClassName} ref={editorRef}>
                  <TableOfContents
                    gdd={gdd}
                    changeActiveComponent={changeActiveComponent}
                  />
                  {showConcepts && (
                    <ConceptsSection onClose={() => setShowConcepts(false)} />
                  )}
                  <div className="gdd-top-bar-wrapper">
                    <GDD4Menu
                      changeGdd={changeGdd}
                      changeAllGdd={changeAllGdd}
                      numberAllowedProjects={numberAllowedProjects}
                      editable={editable}
                      gdd={gdd}
                      shareToSlack={shareToSlack}
                      downloadPDF={downloadPDF}
                      project={project}
                      activeComponent={activeComponent}
                      setActiveComponent={() =>
                        changeActiveComponent({
                          ...gddComponents.header,
                          id: gddComponents.header.section,
                        })
                      }
                      showConcepts={showConcepts}
                      setShowConcepts={setShowConcepts}
                      showEmptyState={showEmptyState}
                      onAddProject={() => setForceEditEmpty(true)}
                    >
                      {!showEmptyState && (
                        <Components
                          projectId={projectId}
                          gdd={gdd}
                          changeGdd={changeGdd}
                          activeComponent={activeComponent}
                          changeOrder={changeOrder}
                          deleteSection={deleteSection}
                          setActiveComponent={changeActiveComponent}
                          addNewComponent={addNewComponent}
                          stopGeneration={stopGeneration}
                          sections={gdd.sections?.slice(0, 1)}
                        />
                      )}
                    </GDD4Menu>
                  </div>
                  {showEmptyState ? (
                    <EmptyContent
                      isEmpty={isEmpty}
                      onEditNew={() => setForceEditEmpty(false)}
                    />
                  ) : (
                    <>
                      <DismissableMessage
                        id="gdd-mobile-warning"
                        message="Your Game Concept can only be edited in the desktop version. Visit Ludo on your desktop or laptop to get the full experience!"
                        className="only-mobile mt-3 mx-2"
                      />
                      {tab === GDD_TAB_NAMES.unityCode ? (
                        <ExportProject projectId={projectId} gdd={gdd} />
                      ) : tab === GDD_TAB_NAMES.developmentGuide ? (
                        <GameGuide projectId={projectId} />
                      ) : tab === GDD_TAB_NAMES.analysis ? (
                        <CompetitiveAnalysis
                          key="competitive"
                          defaultInput={{
                            sources:
                              project.platform === "Desktop"
                                ? "steam"
                                : "appstore+playstore",
                            topics: [
                              {
                                id: projectId,
                                gdds: [gdd],
                              },
                            ],
                          }}
                          formId={projectId + project.platform}
                          onChat={(
                            ludo_score,
                            ludo_score_metric,
                            ludo_score_label
                          ) => {
                            openMenu({
                              component: { section: "chat" },
                              option: "chat",
                              section: undefined,
                              ludo_score,
                              ludo_score_metric,
                              ludo_score_label,
                            });
                          }}
                        />
                      ) : (
                        <div className="content-wrapper" ref={wrapperRef}>
                          <div className={editorClassName} key={projectId}>
                            <div className="section-wrapper">
                              <AddSection
                                className={iconsWrapperClassName}
                                clickedAdd={clickedAdd}
                                section={summarySection}
                                menu={menu}
                                suggestions={sectionSuggestions}
                                addNewComponent={addNewComponent}
                              />
                            </div>

                            <Components
                              projectId={projectId}
                              gdd={gdd}
                              changeGdd={changeGdd}
                              activeComponent={activeComponent}
                              changeOrder={changeOrder}
                              deleteSection={deleteSection}
                              setActiveComponent={changeActiveComponent}
                              addNewComponent={addNewComponent}
                              clickedAdd={clickedAdd}
                              stopGeneration={stopGeneration}
                              sections={gdd.sections?.slice(1)}
                              sectionSuggestions={sectionSuggestions}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </PDFExport>
          </ShowIf>
        </div>
        {projectId && (
          <GDDSideMenu
            key={projectId}
            changeGdd={changeGdd}
            addNewComponent={addNewComponent}
            gdd={gdd}
          />
        )}
      </div>
    </Provider>
  );
};

const Components = ({
  gdd,
  changeGdd,
  activeComponent,
  setActiveComponent,
  clickedAdd,
  changeOrder,
  deleteSection,
  stopGeneration,
  projectId,
  sections = gdd.sections,
  sectionSuggestions,
  addNewComponent,
}) => {
  const { cache } = useContext(CacheContext);
  const { gddComponents } = cache;

  const { summary, text, games, moodboard, images, trending, score, assets } =
    gddComponents;

  const { menu, tab, preview } = useContext(GDDContext);

  return (sections || []).map((section) => {
    const matchingComponent = gddComponents[section.name] || {};

    let sectionName = section.id || section.name;
    let active = section.id === activeComponent?.id;
    let scrollTo = activeComponent?.scrollTo;
    if (!!activeComponent?.targetSection)
      active = section.id === activeComponent.targetSection;

    function sectionSetActiveComponent() {
      if (!active) {
        //closeMenu();
        return setActiveComponent(
          { ...matchingComponent, id: section.id },
          undefined
        );
      }
    }

    let addHere =
      menu?.section === section.id && menu?.component === gddComponents.index;
    let onlyOneSection = gdd.sections.length === 1;
    const isLoading = section.loading;

    let iconsWrapperClassName = "icons-wrapper";
    if (addHere) iconsWrapperClassName += " active";
    if (onlyOneSection) iconsWrapperClassName += " hover";

    let wrapperClassName = "section-wrapper";
    if (active) wrapperClassName += " active";
    if (isLoading) wrapperClassName += " loading";

    let sectionContentWrapperClassName = "section-content-wrapper";
    if (active) sectionContentWrapperClassName += " active";

    if (preview && !!activeComponent)
      sectionContentWrapperClassName += " comments-active";

    if (section.name === score.section) return null;

    return (
      <div className={wrapperClassName} key={section.id || section.name}>
        <div
          className={sectionContentWrapperClassName}
          onClick={sectionSetActiveComponent}
        >
          <GDDSectionActions
            changeGdd={changeGdd}
            component={matchingComponent}
            gdd={gdd}
            value={section.value}
            section={sectionName}
            changeOrder={(up) => changeOrder(sectionName, up)}
            deleteSection={
              section.name !== "summary"
                ? () => deleteSection(sectionName)
                : undefined
            }
            projectId={projectId}
          />
          <ShowIf condition={active && preview}>
            <CommentsSection
              value={section.value}
              component={activeComponent}
              gdd={gdd}
              changeGdd={changeGdd}
              section={sectionName}
            />
          </ShowIf>
          {isLoading ? (
            <div className="loading-wrapper">
              <CircularProgress size={55} />
              <IconButton onClick={stopGeneration}>
                <CloseOutlined className="font-size-xxl pointer text-secondary" />
              </IconButton>
            </div>
          ) : null}
          {section.name === summary.section ? (
            <GDDGameSummary
              value={section.value}
              section={sectionName}
              active={active}
              loading={section.loading}
              scrollTo={scrollTo}
              changeGdd={changeGdd}
              component={matchingComponent}
            />
          ) : null}
          {section.name === text.section ? (
            <GDDText
              active={active}
              scrollTo={scrollTo}
              changeGdd={changeGdd}
              section={sectionName}
              value={section.value}
            />
          ) : null}
          {section.name === score.section ? (
            <GDDLudoScore
              value={section.value}
              section={sectionName}
              active={active}
              loading={section.loading}
              scrollTo={scrollTo}
              changeGdd={changeGdd}
              component={matchingComponent}
              projectId={projectId}
            />
          ) : null}
          {section.name === moodboard.section ||
          section.name === images.section ? (
            <GDDMoodboard
              value={section.value}
              section={sectionName}
              active={active}
              loading={section.loading}
              scrollTo={scrollTo}
              changeGdd={changeGdd}
              gdd={gdd}
              component={matchingComponent}
            />
          ) : null}
          {section.name === games.section ||
          section.name === trending.section ? (
            <GDDGames
              key={sectionName}
              value={section.value}
              section={sectionName}
              active={active}
              loading={section.loading}
              scrollTo={scrollTo}
              changeGdd={changeGdd}
              component={matchingComponent}
              includeStats={true}
              gdd={gdd}
              title="Games"
            />
          ) : null}
          {matchingComponent.gameElements ? (
            <GDDGameElement
              component={matchingComponent}
              title={matchingComponent.label}
              value={section.value}
              section={sectionName}
              active={active}
              loading={section.loading}
              scrollTo={scrollTo}
              changeGdd={changeGdd}
              gdd={gdd}
              sectionSetActiveComponent={sectionSetActiveComponent}
            />
          ) : null}
          {section.name === assets.section ? (
            <GDDAssets
              value={section.value}
              section={sectionName}
              active={active}
              loading={section.loading}
              scrollTo={scrollTo}
              changeGdd={changeGdd}
              gdd={gdd}
              component={matchingComponent}
            />
          ) : null}
        </div>
        {clickedAdd && (
          <AddSection
            className={iconsWrapperClassName}
            clickedAdd={clickedAdd}
            section={section}
            menu={menu}
            suggestions={sectionSuggestions}
            addNewComponent={addNewComponent}
          />
        )}
      </div>
    );
  });
};

const AddSection = ({
  className,
  clickedAdd,
  section,
  menu,
  suggestions,
  addNewComponent,
}) => {
  const ref = useRef();
  const { cache } = useContext(CacheContext);
  const { gddComponents } = cache;

  function scrollTo(delayMs) {
    setTimeout(() => {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }, delayMs);
  }

  function openAddMenu() {
    if (!menu?.component) {
      scrollTo(200);
    }
    clickedAdd({ component: gddComponents.index, section: section.id });
  }

  return (
    <div ref={ref} className={className} onClick={() => openAddMenu()}>
      <FAIcon
        tooltip="Add Other Sections"
        icon={<AddCircleOutlineOutlined className="font-size-xxxxxxl" />}
        animation="pulse"
        className="animate__infinite animate__slower mx-3"
      />
      <hr />
      {suggestions?.length ? (
        <div className="suggestions-wrapper">
          <span className="description mr-2">
            <Tooltip
              arrow
              placement="top"
              title="Ludo Suggestions"
              PopperProps={{
                disablePortal: true,
                className: "MuiTooltip-popper MuiTooltip-popperArrow secondary",
              }}
            >
              <AutoAwesomeOutlined className="font-size-lg text-secondary" />
            </Tooltip>
          </span>
          {suggestions?.map((component) => (
            <Tooltip
              key={component.description}
              arrow
              placement="top"
              title={component.description}
              PopperProps={{
                disablePortal: true,
                className: "MuiTooltip-popper MuiTooltip-popperArrow secondary",
              }}
            >
              <span
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  addNewComponent(component, section.id);
                }}
              >
                <MyButton
                  className="text-gradient-static w-fit no-shadow"
                  style={{ width: "fit-content" }}
                >
                  {component.label}
                </MyButton>
              </span>
            </Tooltip>
          ))}
          {suggestions?.length > 0 && (
            <Tooltip
              arrow
              placement="top"
              title="See all different sections you can add to your concept"
              PopperProps={{
                disablePortal: true,
                className: "MuiTooltip-popper MuiTooltip-popperArrow secondary",
              }}
            >
              <span
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  openAddMenu();
                }}
              >
                <MyButton
                  className="text-gradient-static w-fit no-shadow"
                  style={{ width: "fit-content" }}
                >
                  Other
                </MyButton>
              </span>
            </Tooltip>
          )}
        </div>
      ) : null}
    </div>
  );
};

const EmptyContent = ({ onEditNew, isEmpty }) => {
  const { track } = useContext(SocketContext);
  const history = useHistory();
  const { auth } = useContext(AuthContext);
  const { call } = useContext(APIContext);
  const { cache, setCache } = useContext(CacheContext);
  const [showImportModal, setShowImportModal] = useState(false);

  const defaultPlatform = auth.user.platform;

  const navigateTo = (path) => {
    history.push(path);
  };

  const onOptionSelected = (option) => {
    track("gdd.empty-option", { optionId: option.id });
    option.action && option.action();
  };

  async function addProject() {
    track("gdd.new-project");
    let project = await addNewProject(
      cache.projects,
      call,
      setCache,
      defaultPlatform
    );
    if (project) {
      history.push("/game-concept/" + project._id, {
        data: { forceEditEmpty: false },
      });
    }
  }

  const OPTIONS = [
    {
      id: "import-gdd",
      title: "I already have a game concept",
      name: "Import Game Concept",
      description: "Import your existing design document",
      image: featureConceptImage,
      action: () => setShowImportModal(true),
    },
    {
      id: "step-by-step",
      title: "No idea yet, help me start",
      description: "Build a concept step-by-step",
      name: "Step-by-Step Ideator",
      image: featureStepImage,
      action: () => navigateTo("/step-by-step"),
    },
    {
      id: "game-ideator",
      title: "I already have some ideas or keywords",
      description: "Generate ideas from your input",
      name: "Game Ideator",
      image: featureIdeatorImage,
      action: () => navigateTo("/game-ideator"),
    },
    {
      id: "new-gdd",
      title: "I want a clean slate",
      description: "Start with a blank document",
      name: "New Game Concept",
      image: featureNewConceptImage,
      action: () => (isEmpty ? onEditNew() : addProject()),
    },
  ];

  return (
    <div className="options-container">
      {showImportModal && (
        <ImportConcept onClose={() => setShowImportModal(false)} />
      )}
      <div className="empty-state-heading">
        <h1>Your Game Concept Document</h1>
        <p>Choose how you'd like to start creating your game concept below</p>
      </div>
      {OPTIONS.map((option) => (
        <div
          key={option.id}
          className="option-card"
          onClick={() => onOptionSelected(option)}
        >
          <div className="option-image">
            <img src={option.image || feature3dImage} alt={option.title} />
          </div>
          <div className="option-content">
            <h4>{option.title}</h4>
            <p>{option.description}</p>
            <button className="option-button">{option.name}</button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default GDD4;
