import { useBus } from "react-bus";
import React, { useContext, useEffect, useMemo, useState } from "react";
import ShowIf from "components/common/ShowIf";
import { GeneratingButton } from "components/Controls/MyButton";
import APIContext from "context/APIContext";
import { MenuTopBar } from "pages/GDD3/GDDSideMenu";
import CacheContext from "context/CacheContext";
import { FAVORITE_TYPES, filterFavorites } from "pages/Favorites";
import {
  AutoAwesomeOutlined,
  TryOutlined,
  FavoriteOutlined,
  Sync,
  ModeEditOutlined,
  ViewInArOutlined,
  DownloadOutlined,
  DeleteOutlined, BrushOutlined, FileCopyOutlined, AutorenewOutlined,
} from "@mui/icons-material";
import GDDContext from "context/GDDContext";
import { useHistory } from "react-router";
import Chat from "components/common/Chat";
import {
  getAssetPayloadId,
  ThreeDAssets,
  ThreeDGeneratorResults,
} from "../../ThreeDGenerator";
import { TextField } from "@material-ui/core";

const DEFAULT_ARRAY = [];

export const MENUS = {
  suggestions: {
    id: "suggestions",
    showInput: true,
    buttonLabel: (
      <span className="px-2">
        <AutoAwesomeOutlined className="font-size-lg mr-2" />
        Suggestions
      </span>
    ),
  },
  favorites: {
    id: "favorites",
    label: "Favorites",
    buttonLabel: <FavoriteOutlined />,
  },
};

export const NON_LISTED_MENUS = {
  chat: {
    id: "chat",
    label: "Ask Ludo",
    buttonLabel: <TryOutlined />,
  },
  generate: {
    id: "generate",
    label: "Generate",
    buttonLabel: <Sync />,
  },
};

export const ASSET_MENUS = {
  download: {
    id: "download",
    label: "Download",
    buttonLabel: (
      <span className="px-2">
        <DownloadOutlined />
      </span>
    ),
  },
  edit: {
    id: "edit",
    label: "Edit",
    buttonLabel: (
      <span className="px-2">
        <ModeEditOutlined />
      </span>
    ),
    options: {
      retry: {
        id: "retry",
        label: "Retry Generation",
        icon: <AutorenewOutlined />
      },
      editBaseImage: {
        id: "editBaseImage",
        getData: asset => ({ formValues: { image: asset.image }, tab: 1 }),
        icon: <ModeEditOutlined />,
        label: "Edit Base Image",
      },
      reTexture: {
        id: "reTexture",
        label: "Re-Texture",
        getData: asset => ({ formValues: { generated_model: asset }, tab: 2 }),
        icon: <BrushOutlined />
      },
      variations: {
        id: "variations",
        label: "Generate Variations",
        getData: asset => ({ formValues: { generated_model: asset }, tab: 3 }),
        icon: <FileCopyOutlined />
      },
    }
  },
  delete: {
    id: "delete",
    label: "Delete",
    buttonLabel: (
      <span className="px-2">
        <DeleteOutlined />
      </span>
    ),
  },
};

const AssetsMenu = ({ component, gdd }) => {
  const { menu } = useContext(GDDContext);

  const section = useMemo(() => {
    return gdd.sections.find(
      ({ id, name }) => id === menu.section || name === menu.section
    );
  }, [gdd, menu]);

  return (
    <div className="menu-section assets">
      <MenuTopBar />
      <div className="menu-section-content">
        <ShowIf condition={menu?.option === MENUS.suggestions.id}>
          <AssetSuggestions
            section={menu?.section}
            value={section?.value}
            component={component}
            generated={true}
            input={menu?.input}
          />
        </ShowIf>
        <ShowIf condition={menu?.option === MENUS.favorites.id}>
          <AssetFavorites section={menu?.section} value={section?.value} />
        </ShowIf>
        <ShowIf condition={menu?.option === NON_LISTED_MENUS.chat.id}>
          <Chat
            component={component}
            section={section?.id || component.section}
            element={undefined}
            sectionValue={section}
          />
        </ShowIf>
        <ShowIf condition={menu?.option === NON_LISTED_MENUS.generate.id}>
          <AssetGenerator
            key="edit"
            section={menu?.section}
            initialData={menu?.initialData}
            hideMenu={true}
          />
        </ShowIf>
      </div>
    </div>
  );
};

export const AssetGenerator = ({ hideMenu, section, initialData }) => {
  const bus = useBus();
  const { menu, openMenu } = useContext(GDDContext);

  function onClick(asset) {
    bus.emit(`${section}.click`, asset);
  }

  function onHover(value) {
    bus.emit(`${section}.hover`, { asset: value });
  }

  function onActionsClicked(data) {
    openMenu({
      option: NON_LISTED_MENUS.generate.id,
      component: menu.component,
      section: menu.section,
      initialData: data,
    });
  }

  return (
    <div className="asset-generator pb-4">
      <ThreeDGeneratorResults
        fullVersion={false}
        onClick={(asset) => onClick(asset)}
        hideMenu={hideMenu}
        initialData={initialData}
        onActionsClicked={onActionsClicked}
      />
    </div>
  );
};

export const AssetFavorites = ({ section, value }) => {
  const bus = useBus();

  const name = value?.title || "3D Assets";

  const { cache } = useContext(CacheContext);
  const { allFavorites = DEFAULT_ARRAY } = cache;

  const excludeAssets = value?.assets || DEFAULT_ARRAY;

  const assets = useMemo(() => {
    let excludeIds = excludeAssets.map((asset) => getAssetPayloadId(asset));
    return filterFavorites(allFavorites, FAVORITE_TYPES.three_d_asset).filter(
      ({ image, asset }) =>
        !excludeIds.includes(getAssetPayloadId({ image, asset }))
    );
  }, [allFavorites, excludeAssets]);

  function onClick(asset) {
    bus.emit(`${section}.click`, asset);
  }

  function onHover(value) {
    bus.emit(`${section}.hover`, { asset: value });
  }

  return (
    <div className="favorites-list p-3">
      <ShowIf condition={assets.length === 0}>
        <span className="explanation">No image 3D assets found</span>
      </ShowIf>
      <ShowIf condition={assets.length > 0}>
        <span className="explanation m-3">
          Add favorite assets to the {name}
        </span>
      </ShowIf>
      <ThreeDAssets assets={assets} onClick={onClick} />
    </div>
  );
};

const suggestProjectAssets = "suggestProjectAssets";
export const AssetSuggestions = ({
  section,
  generated = false,
  value,
  input,
}) => {
  const bus = useBus();
  const { call } = useContext(APIContext);
  const { cache } = useContext(CacheContext);
  const { selectedProjectId } = cache;

  const name = value?.title || "3D Assets";

  const [isLoading, setIsLoading] = useState(true);
  const [suggestions, setSuggestions] = useState();
  const [currentInput, setCurrentInput] = useState(input);

  useEffect(() => {
    loadSuggestions(input);
  }, [input]);

  function onClick(value) {
    bus.emit(`${section}.click`, value);
    let newSuggestions = (suggestions || []).filter(
      ({ url }) => url !== value.url
    );
    setSuggestions(newSuggestions);
  }

  function onHover(value) {
    bus.emit(`${section}.hover`, { image: value });
  }
  async function loadSuggestions(input = currentInput) {
    setSuggestions([]);
    setIsLoading(true);

    let payload = {
      id: selectedProjectId,
      data: {
        n: generated ? 3 : undefined,
        hints: input ? [input] : undefined,
      },
    };

    setTimeout(async () => {
      let response = await call(suggestProjectAssets, payload);
      if (response.ok) {
        setSuggestions(response.body);
      }
      setIsLoading(false);
    }, 300);
  }

  function onInputKeyDown(event) {
    if (event.key === "Enter") {
      loadSuggestions(currentInput);
    }
  }

  return (
    <div className="asset-suggestions p-3 d-flex flex-column">
      <span className="explanation m-3">
        Add suggested assets to the {name} section
      </span>

      <div style={{ padding: "0 15px" }}>
        <TextField
          className="text-field ludo-color"
          fullWidth
          placeholder="Enter a description (optional)"
          variant="standard"
          value={currentInput}
          margin="normal"
          onChange={(e) => setCurrentInput(e.target.value)}
          onKeyDown={(e) => onInputKeyDown(e)}
        />
      </div>

      <div className="suggestions px-0 py-2">
        {suggestions && <ThreeDAssets assets={suggestions} onClick={onClick} />}
      </div>

      <GeneratingButton
        id="assets.load-suggestions"
        className="mx-auto mt-3"
        color="secondary"
        onClick={() => loadSuggestions(currentInput)}
        loadProgressSecs={5}
        loading={isLoading}
      >
        <Sync className="font-size-lg mr-2 mt-1" />
        Refresh Suggestions
      </GeneratingButton>
    </div>
  );
};

export default AssetsMenu;
