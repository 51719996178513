import React, { useContext, useMemo } from "react";
import FileUpload, { toBase64 } from "../../Controls/FileUpload";
import ImageGallery from "../ImageGallery";
import { GDDModal } from "../../../pages/GDD3/Helpers";
import { FAVORITE_TYPES, filterFavorites } from "../../../pages/Favorites";
import CacheContext from "../../../context/CacheContext";
import APIContext from "../../../context/APIContext";
import { ThreeDAssets } from "../../../pages/ThreeDGenerator";

const DEFAULT_ARRAY = [];

const uploadImage = "uploadImage";

const FavoriteImagesModal = ({
  open,
  onClose,
  setFiles,
  convertFileToImage = true,
  accept = {"image/*" : []},
  title = "Upload an Image",
  info,
  favoriteType = FAVORITE_TYPES.image,
}) => {
  const { call } = useContext(APIContext);
  const { cache } = useContext(CacheContext);
  const { allFavorites = DEFAULT_ARRAY } = cache;
  const favorites = useMemo(
    () => filterFavorites(allFavorites, favoriteType),
    [allFavorites, favoriteType]
  );

  async function onClickedFavoriteImage(image) {
    image.submit_field = "initial_image_file";
    if (image.prompt) image.submit_field = "initial_generated_image";
    if (image.game_id) image.submit_field = "initial_game_image";
    setFiles([image]);
  }

  async function onFilesUpdated(files) {
    if (convertFileToImage) {
      if (files.length > 0) {
        let binaryStrings = await Promise.all(
          files.map((file) => toBase64(file, false))
        );
        let data = { files: binaryStrings };
        let response = await call(uploadImage, { data });
        if (response.ok) {
          let images = response.body;
          images = images.map((image) => {
            return { ...image, submit_field: "initial_image_file" };
          });
          setFiles(images);
        }
      }
    } else {
      setFiles(files);
    }
  }

  return (
    <GDDModal open={open} onClose={onClose} className="image-generator-modal">
      <div className="px-4 m-auto modal-content">
        <div className="d-flex flex-column align-items-center">
          <span className="font-weight-bold text-secondary">{title}</span>

          {info}

          <FileUpload
            accept={accept}
            title={null}
            hideFiles={true}
            onFilesUpdated={(files) => onFilesUpdated(files)}
            maxFiles={1}
            maxSize={20 * 1024 * 1024}
          />

          <span className="font-weight-bold text-secondary py-3 d-block">
            Or Select From Your Favorites
          </span>
        </div>

        {favoriteType === FAVORITE_TYPES.image && (
          <ImageGallery
            images={favorites}
            minImages={2}
            enforceSize={false}
            onImageClick={true}
            onImageClickFunc={(image) => onClickedFavoriteImage(image)}
          />
        )}
        {favoriteType === FAVORITE_TYPES.three_d_asset && (
          <ThreeDAssets
            assets={favorites.filter(asset => !!asset.asset)}
            onClick={(asset) => setFiles([asset])}
          />
        )}
      </div>
    </GDDModal>
  );
};

export default FavoriteImagesModal;
